import React, { Component } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import axios from "axios";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ScrolltoTop from "../ScrollTop/ScrollTop";
import Sidebar from "../Sidebar/Sidebar";
import { connect } from "react-redux";
import moment from "moment";
import "./IncomeStatement.css";
import { InputTextarea } from "primereact/inputtextarea";
import { setUserSessionAuthKey, previewPdf } from "../../Component/commonUtils";
import TranslationFile from "../15-Translation/locale/Translation_apps.json";
import NavBarHeader from "../NavBarHeader/NavBarHeader";
import Footer_contact_tab from "../Footerpage/Footer_contact_tab";
import { Toast } from "primereact/toast";
import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";
import Autocomplete from "react-autocomplete";

let userSelectedLanguage = localStorage.getItem("selectedLanguage");
let tourGuidePages = localStorage.getItem("tourGuidePages");
let currentPageTourGuideName = "IncomeStatement";

let incomeStatementTemplateReqArray = [],
  incomeStatementDefaultTemplateResponse = {},
  incomeStatementSuggestionTemplateResponse = {},
  linkSharedUser,
  tabTitleArray = [],
  dateTemplate = [],
  pdfLink = "",
  sideBarGreenTickPages = [],
  currentReportGUIDFromUrl = "";

let userSessionAuthKeyReturnValue = setUserSessionAuthKey();
const mapStateToProps = (state) => {
  return {
    annualReportType: state.annualReportType.annualReportType.values,
    companyInformation: state.companyInformation.companyInformation.values,
    financialYear: state.financialYear.financialYear,
    sessionDetails: state.sessionDetails,
    login: state.login.login.values,
    sideBarReducer: state.sideBarReducer.sideBarReducer,
    activeSideBar: state.activeSideBar.activeSideBar,
    incomeStatement: state.incomeStatement.incomeStatement,
    currentPageName: state.currentPageName.currentPageName,
    readOnly: state.readOnly.readOnly,
    showWIPStatus: state.showWIPStatus.showWIPStatus,
  };
};

let steps = [
  {
    content: TranslationFile.TourGuide_PDF_Btn[userSelectedLanguage],
    placement: "top",
    styles: {
      options: {
        width: 300,
      },
    },
    target: ".pdf_view_incomestatement",
    title: TranslationFile.PDFfile[userSelectedLanguage],
    disableBeacon: true,
  },
  {
    content: TranslationFile.TourGuide_Toggle_Btn[userSelectedLanguage],
    placement: "top",
    styles: {
      options: {
        width: 300,
      },
    },
    target: ".toggle-open",
    title: TranslationFile.AccordionTab[userSelectedLanguage],
    disableBeacon: true,
  },
  {
    content: TranslationFile.TourGuide_Plus_Btn[userSelectedLanguage],
    placement: "bottom",
    styles: {
      options: {
        width: 300,
      },
    },
    target: "#ISAddNewLineIcon",
    title: TranslationFile.AddNewLine[userSelectedLanguage],
    disableBeacon: true,
  },
];

class IncomeStatement extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      activeIndex: [0],
      checked: true,
      sideBarStatus: true,
      latestCustomLineNo: 0,
      reportGUID: "",
      finalSumLbl: "Årets resultat",
      toggleOpen: false,
      headerCount: 0,
      isLoading: true,
      incomeStatementValue: "",
      companyName: "",
      currentFinancialYear: "",
      headerDetails: "",
      run: false,
      items: [],
      showNoMatchFound: false,
      isToastVisible: false,
    };
    this.textareaRef = React.createRef();
    // this.autoCompleteOnClick = this.autoCompleteOnClick.bind();
  }

  handleChange(toggleOpen) {
    window.scroll(0, 0);
    if (toggleOpen == true) {
      let headerIdxList = [];
      Object.keys(tabTitleArray).map((heading, idx) => {
        headerIdxList.push(idx);
      });
      this.setState({ activeIndex: headerIdxList });
      this.setState({ toggleOpen: false });
    } else {
      let collapse = [];
      this.setState({ activeIndex: collapse });
      this.setState({ toggleOpen: true });
    }
  }

  openAll(resp) {
    let headerIdxList = [];
    Object.keys(resp).map((heading, idx) => {
      headerIdxList.push(idx);
    });
    let open = headerIdxList;
    this.setState({ activeIndex: open });
  }

  convert = (str) =>
    str.split(",").flatMap((s) => {
      if (!s.includes("-")) return +s;
      const [min, max] = s.split("-");
      return Array.from({ length: max - min + 1 }, (_, n) => n + +min);
    });

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.state.run) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    let userTourGuide = JSON.parse(localStorage.getItem("TourGuide")) || false;
    if (userTourGuide) {
      if (
        tourGuidePages != null &&
        tourGuidePages.length > 0 &&
        tourGuidePages.includes(currentPageTourGuideName)
      ) {
        setTimeout(() => {
          this.setState({ run: false });
        }, 200);
      } else {
        setTimeout(() => {
          this.setState({ run: true });
        }, 200);
      }
    }

    const { companyInformation } = this.props;
    let companyValues =
      companyInformation != undefined && companyInformation.organizationname;

    this.setState({
      companyInformation: companyValues,
    });
  }

  checkForPreviousYear() {
    const { incomeStatementValue, headerDetails } = this.state;
    if (
      headerDetails.previousYear1 == null ||
      headerDetails.previousYear1 == ""
    ) {
      //Get the state and filter the financial year value 0 and store it in a variable and call the update json api and update it and rerender
      let filteredISValue = [];

      {
        Object.keys(incomeStatementValue).map((heading, parentIdx) => {
          let responseArray = incomeStatementValue[heading];
          responseArray = [...responseArray].sort(
            (a, b) => a.lineNo - b.lineNo
          );
          responseArray = responseArray.filter(
            (val) => val.financialYearValue == 0
          );
          filteredISValue.push(responseArray);
        });
        this.setState({ incomeStatementValue: filteredISValue });

        if (userSessionAuthKeyReturnValue) {
          let currentPageName = localStorage.getItem("currentPageName");
          let updateJsonTableObj = {
            pageName: currentPageName,
            guid: currentReportGUIDFromUrl,
            json: JSON.stringify(filteredISValue),
          };
          axios
            .post(
              "/annualReportLine/updateLinesJsonTable",
              updateJsonTableObj,
              userSessionAuthKeyReturnValue
            )
            .then((response) => {});
        }
      }
    } else {
      //If the header details have previous year then show 2 columns
    }
  }
  componentWillMount() {
    window.scrollTo(0, 0);
    userSelectedLanguage = localStorage.getItem("selectedLanguage");
    userSessionAuthKeyReturnValue = setUserSessionAuthKey();
    let {
      companyInformation,
      incomeStatement,
      sessionDetails,
      sideBarReducer,
      currentPageName,
      readOnly,
    } = this.props;
    currentPageName.values = "Income Statement";
    localStorage.setItem("currentPageName", "Income Statement");

    let splittedUrl = window.location.href.split("/");
    currentReportGUIDFromUrl = splittedUrl[splittedUrl.length - 2];
    if (userSessionAuthKeyReturnValue) {
      axios
        .get(
          "/annualReport/getHeader?reportId=" + currentReportGUIDFromUrl,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          let responseDate = response.data;

          if (responseDate != "" && null != responseDate.reportID) {
            ////////////////////////////////////////////////////////////////
            dateTemplate = [];
            let dateTemplateObj = {
              validFromDate: moment(
                responseDate.currentYear.slice(0, 10)
              ).format("YYYY-MM-DD"),
              validToDate: moment(
                responseDate.currentYear.slice(13, 23)
              ).format("YYYY-MM-DD"),
            };
            dateTemplate.push(dateTemplateObj);
            if (
              responseDate.previousYear1 != "" &&
              responseDate.previousYear1 != null
            ) {
              let dateTemplateObj = {
                validFromDate: moment(
                  responseDate.previousYear1.slice(0, 10)
                ).format("YYYY-MM-DD"),
                validToDate: moment(
                  responseDate.previousYear1.slice(13, 23)
                ).format("YYYY-MM-DD"),
              };
              dateTemplate.push(dateTemplateObj);
            }
            ////////////////////////////////////////////////////////////////
            this.setState({ headerDetails: responseDate });
            let reportAccessReq = {
              reportId: responseDate.reportID,
            };
            axios
              .post(
                "/annualReport/getAccessStatus",
                reportAccessReq,
                userSessionAuthKeyReturnValue
              )
              .then((response) => {
                switch (response.data) {
                  case 2:
                    linkSharedUser = 2; //Write
                    break;
                  case 3:
                    linkSharedUser = 3; //Read Only
                    break;
                  case 4:
                    linkSharedUser = 4; //No Access
                    break;
                }
                if (responseDate.companyName != "") {
                  this.setState({
                    companyName: responseDate.companyName,
                  });
                }

                if (responseDate.currentYear != "")
                  this.setState({
                    currentFinancialYear: responseDate.currentYear,
                  });
              })
              .catch((error) => {
                let noAccessURL =
                  "/annualreport/" + currentReportGUIDFromUrl + "/no-access";
                this.props.history.push(noAccessURL);
              });

            axios
              .get(
                "/annualReport/getCompletedPages?reportId=" +
                  responseDate.reportID,
                userSessionAuthKeyReturnValue
              )
              .then((completedStatementTypesResponse) => {
                sideBarReducer.values = completedStatementTypesResponse.data;
                //"My Dashboard"
                sideBarReducer.values.push("My Dashboard");
                sideBarReducer.values.push("Company Information");
                sideBarReducer.values.push("Income Statement");
                let updatePossibleNavigationPagesListString = JSON.stringify(
                  sideBarReducer.values
                );
                localStorage.setItem(
                  "possibleAccessablePages",
                  updatePossibleNavigationPagesListString
                );
              });

            let getJsonLines = {
              guid: currentReportGUIDFromUrl,
              pageName: "Income Statement",
            };

            axios
              .post(
                "/annualReportLine/getLinesJson",
                getJsonLines,
                userSessionAuthKeyReturnValue
              )
              .then((response) => {
                let responseData = response.data.incomeStatement;
                if (responseData == undefined) {
                  responseData = "";
                }

                console.log(dateTemplate, "???", companyInformation);

                // incomeStatementTemplateReqArray = [];
                // companyInformation != undefined &&
                //   companyInformation.financialYear.length &&
                //   companyInformation.financialYear.map((financialYear, idx) => {
                //     let incomeStatementTemplateReq = {
                //       validFromDate: moment(financialYear.from).format(
                //         "YYYY-MM-DD"
                //       ),
                //       validToDate: moment(financialYear.to).format(
                //         "YYYY-MM-DD"
                //       ),
                //       statementType: 1,
                //     };
                //     incomeStatementTemplateReqArray.push(
                //       incomeStatementTemplateReq
                //     );
                //   });

                dateTemplate != undefined &&
                  dateTemplate &&
                  dateTemplate.length &&
                  dateTemplate.map((financialYear, idx) => {
                    let incomeStatementTemplateReq = {
                      validFromDate: moment(financialYear.validFromDate).format(
                        "YYYY-MM-DD"
                      ),
                      validToDate: moment(financialYear.validToDate).format(
                        "YYYY-MM-DD"
                      ),
                      statementType: 1,
                    };

                    incomeStatementTemplateReqArray.push(
                      incomeStatementTemplateReq
                    );

                    console.log(
                      incomeStatementTemplateReqArray,
                      "!!! DATE REQ"
                    );
                  });

                axios
                  .post(
                    "/annualReport/getIncomeBalanceTemplateYearWise",
                    incomeStatementTemplateReqArray,
                    userSessionAuthKeyReturnValue
                  )
                  .then((response) => {
                    incomeStatementSuggestionTemplateResponse =
                      Object.fromEntries(
                        Object.entries(response.data).map(([key, value]) => [
                          key,
                          value.filter((item) => item.isDefault === false),
                        ])
                      );

                    incomeStatementDefaultTemplateResponse = Object.fromEntries(
                      Object.entries(response.data).map(([key, value]) => [
                        key,
                        value.filter((item) => item.isDefault === true),
                      ])
                    );

                    if (responseData == "") {
                      this.dataManipulation(
                        incomeStatementDefaultTemplateResponse,
                        true,
                        false
                      );
                      tabTitleArray = incomeStatementDefaultTemplateResponse;
                      this.openAll(incomeStatementDefaultTemplateResponse);
                    }
                  });

                if (responseData != "") {
                  let jsonConvertedResponseData = JSON.parse(responseData);
                  let financialYearFromJsonResponse = [];
                  Object.keys(jsonConvertedResponseData).map((i, idx) => {
                    if (i == "financialYear") {
                      financialYearFromJsonResponse = [
                        ...jsonConvertedResponseData[i],
                      ];
                    }
                  });

                  incomeStatementTemplateReqArray =
                    financialYearFromJsonResponse;

                  delete jsonConvertedResponseData.financialYear;

                  tabTitleArray = jsonConvertedResponseData;
                  this.openAll(jsonConvertedResponseData);

                  axios
                    .get(
                      "/annualReport/getCompletedPages?reportId=" +
                        response.data.reportID,
                      userSessionAuthKeyReturnValue
                    )
                    .then((completedStatementTypesResponse) => {
                      sideBarReducer.values =
                        completedStatementTypesResponse.data;
                      sideBarGreenTickPages =
                        completedStatementTypesResponse.data;
                      this.setState({
                        reRender: true,
                      });
                    });
                  this.setState({
                    incomeStatementValue: jsonConvertedResponseData,
                    isLoading: false,
                  });
                  // this.checkForPreviousYear();
                }
              });
          } else {
            sideBarGreenTickPages = [];
            sideBarGreenTickPages.push(
              "My Dashboard",
              "Company Information",
              "Income Statement"
            );
            let updatePossibleNavigationPagesListString = JSON.stringify(
              sideBarGreenTickPages
            );
            localStorage.setItem(
              "possibleAccessablePages",
              updatePossibleNavigationPagesListString
            );
          }
          // }
        });
    }

    tourGuidePages = localStorage.getItem("tourGuidePages");
    if (tourGuidePages != null) {
      tourGuidePages = JSON.parse(tourGuidePages);
      if (!tourGuidePages.includes(currentPageTourGuideName)) {
        tourGuidePages.push(currentPageTourGuideName);
      }
      localStorage.setItem("tourGuidePages", JSON.stringify(tourGuidePages));
    } else {
      let currentPageTourGuideArray = [];
      currentPageTourGuideArray.push(currentPageTourGuideName);
      localStorage.setItem(
        "tourGuidePages",
        JSON.stringify(currentPageTourGuideArray)
      );
    }
  }
  navigateToPDF() {
    axios
      .get("/annualReport/pdf?reportGUID=" + currentReportGUIDFromUrl, {
        responseType: "blob",
      })
      .then((response) => {
        response["Access-Control-Expose-Headers"] = "Content-Disposition";
        const filename =
          response.headers["content-disposition"].split("filename=")[1];
        const blob = new Blob([response.data], { type: "application/pdf" });
        let url = window.URL.createObjectURL(blob);
        window.open(url, "_blank");
      });
  }

  dataManipulation(resp, isInitialRender, isOnChangeCall) {
    let { companyInformation, sessionDetails, login, incomeStatement } =
      this.props;
    let fieldExistsInMultiYear = [],
      lineNoArray = [],
      lineDetailsArray = [];

    delete resp.financialYear;
    if (userSessionAuthKeyReturnValue) {
      if (incomeStatement.values != undefined && !isOnChangeCall) {
        resp = incomeStatement.values;
      } else {
        if (isInitialRender) {
          // calculating lineAmount
          Object.keys(resp).map((i, idx) => {
            let sortedRespArray = [...resp[i]].sort(
              (a, b) => a.lineNo - b.lineNo
            );
            sortedRespArray.map((sortedResp, sortedRespIdx) => {
              let range = this.convert(sortedResp.range, "-");
              if (range[0] == 0) {
                range = this.convert(sortedResp.lineSumRange, "-");
              }
            });
          });
        }

        // calculating yearwise lineAmount
        Object.keys(resp).map((i, idx) => {
          let sortedRespArray = [...resp[i]].sort(
            (a, b) => a.lineNo - b.lineNo
          );

          sortedRespArray = sortedRespArray.filter(
            (obj) => obj.lineSumRange != ""
          );
          sortedRespArray.length &&
            sortedRespArray.map((sortedResp, sortedRespIdx) => {
              let range = this.convert(sortedResp.lineSumRange, "-");
              let fyYearValue = sortedResp.year;
              let lineSumValue = 0;

              Object.keys(resp).map((innerRespObj, innerRespIdx) => {
                let innerSortedRespArray = [...resp[innerRespObj]].sort(
                  (a, b) => a.lineNo - b.lineNo
                );

                innerSortedRespArray = innerSortedRespArray.filter(
                  (obj) =>
                    obj.year == fyYearValue &&
                    obj.lineType == "Line" &&
                    obj.lineAmount != undefined
                );

                innerSortedRespArray.map(
                  (innerSortedRespValue, innerSortedRespIdx) => {
                    if (range.includes(innerSortedRespValue.lineNo)) {
                      lineSumValue += innerSortedRespValue.lineAmount;
                    }
                  }
                );
              });
              sortedResp["lineAmount"] = lineSumValue;
            });
        });
      }

      //setting currentYearValue and previousYear value
      Object.keys(resp).map((i, idx) => {
        let sortedRespArray = [...resp[i]].sort((a, b) => a.lineNo - b.lineNo);
        sortedRespArray.map((sortedResp, sortedRespIdx) => {
          if (sortedResp.financialYearValue == 0) {
            sortedResp["currentYearValue"] = sortedResp.lineAmount;
          } else {
            let yearValue = sortedResp.financialYearValue * -1;
            sortedResp["previousYear" + yearValue + "Value"] =
              sortedResp.lineAmount;
          }
          sortedResp["reportGUID"] = currentReportGUIDFromUrl;
          sortedResp["createdByUserID"] = login == undefined ? 0 : login.userID;
        });
      });

      //merging muti year object
      Object.keys(resp).map((i, idx) => {
        let sortedRespArray = [...resp[i]].sort((a, b) => a.lineNo - b.lineNo);
        sortedRespArray.map((sortedResp, sortedRespIdx) => {
          const filtered = sortedRespArray.filter(
            (obj) => obj.name == sortedResp.name
          );
          fieldExistsInMultiYear.push(Object.assign({}, ...filtered));
        });
      });

      fieldExistsInMultiYear.length &&
        fieldExistsInMultiYear.map((i, idx) => {
          if (!lineNoArray.includes(i.lineNo)) {
            lineNoArray.push(i.lineNo);
            lineDetailsArray.push(i);
          }
        });

      if (lineDetailsArray.length > 0) {
        axios
          .post(
            "/annualReportLine/insertLines",
            lineDetailsArray,
            userSessionAuthKeyReturnValue
          )
          .then((response) => {});
      }

      this.setState({
        incomeStatementValue: resp,
        isLoading: false,
      });

      incomeStatement.values = resp;

      let responseJson = resp;
      responseJson["financialYear"] = incomeStatementTemplateReqArray;
      // if (userSessionAuthKeyReturnValue) {
      let currentPageName = localStorage.getItem("currentPageName");
      let updateJsonTableObj = {
        pageName: currentPageName,
        guid: currentReportGUIDFromUrl,
        json: JSON.stringify(responseJson),
      };
      axios
        .post(
          "/annualReportLine/updateLinesJsonTable",
          updateJsonTableObj,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {});
      // }
    }
  }

  pageNavigation(navigateToPageName) {
    const { sessionDetails } = this.props;
    let { sideBarReducer } = this.props;
    const annualReport = "/annualreport/";

    if (userSessionAuthKeyReturnValue) {
      let navigateToReportGUID = localStorage.getItem("navigateToGUID");
      if (navigateToReportGUID != "") {
        axios
          .get(
            "/annualReport/getHeader?reportId=" + navigateToReportGUID,
            userSessionAuthKeyReturnValue
          )
          .then((response) => {
            let responseDate = response.data;
            let sideBarReducerValues = [
              "Company Information",
              "Income Statement",
            ];
            if (sideBarGreenTickPages.length == 0)
              sideBarReducer.values = sideBarReducerValues;
            axios
              .get(
                "/annualReport/getCompletedPages?reportId=" +
                  responseDate.reportID,
                userSessionAuthKeyReturnValue
              )
              .then((completedStatementTypesResponse) => {
                sideBarReducer.values = completedStatementTypesResponse.data;
                sideBarReducer.values.push("My Dashboard");
                let updatePossibleNavigationPagesListString = JSON.stringify(
                  sideBarReducer.values
                );
                localStorage.setItem(
                  "possibleAccessablePages",
                  updatePossibleNavigationPagesListString
                );
              });
          });
      } else {
        let sideBarReducerValues = [];
        sideBarReducerValues = sideBarReducer.values;
        if (sideBarReducerValues == null) {
          sideBarReducerValues = [];
        }
        sideBarReducerValues.push("Income Statement");
        sideBarReducer.values = sideBarReducerValues;
      }

      let possibleAccessablePagesListLSString = localStorage.getItem(
        "possibleAccessablePages"
      );

      let possibleAccessablePagesListLSArray = JSON.parse(
        possibleAccessablePagesListLSString
      );

      if (!possibleAccessablePagesListLSArray.includes("Balance Sheet")) {
        possibleAccessablePagesListLSArray.push(
          "Company Information",
          "Income Statement",
          "Balance Sheet"
        );

        let updatePossibleNavigationPagesListString = JSON.stringify(
          possibleAccessablePagesListLSArray
        );
        localStorage.setItem(
          "possibleAccessablePages",
          updatePossibleNavigationPagesListString
        );
      }

      this.props.history.push(
        annualReport + currentReportGUIDFromUrl + "/" + navigateToPageName
      );
    }
  }

  accordionHeader(header) {
    return (
      <Row>
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          style={{ width: "41%", marginTop: "7px" }}
        >
          <label className="ISFieldsStyle" style={{ marginLeft: "-21px" }}>
            {header}
          </label>
        </Col>
      </Row>
    );
  }
  AmountOnBlur(e, mutilYear, heading) {
    const { incomeStatementValue } = this.state;
    let custEnteredValue = e.target.value;
    incomeStatementValue[heading].map((i, idx) => {
      if (
        i.lineNo == mutilYear.lineNo &&
        i.financialYearValue == mutilYear.financialYearValue
      ) {
        let spacesRemoved = custEnteredValue.replaceAll(/\s/g, "");

        const firstChar = spacesRemoved.charAt(0);
        let minusArray = ["-", "−", "‑", "−"];
        if (minusArray.includes(firstChar)) {
          const newStr = spacesRemoved.slice(1);
          return (i["lineAmount"] = -1 * newStr);
        } else return (i["lineAmount"] = Number(spacesRemoved));
      }
    });
    this.setState({
      incomeStatementValue: "",
    });

    this.dataManipulation(incomeStatementValue, false, true);
  }

  AmountOnChange(e, mutilYear, heading) {
    const { incomeStatementValue } = this.state;
    if (e.value != null) {
      incomeStatementValue[heading].map((i, idx) => {
        if (i.lineNo == mutilYear.lineNo && i.year == mutilYear.year) {
          return (i["lineAmount"] = e.value);
        }
      });
      this.setState({
        incomeStatementValue: "",
      });

      this.dataManipulation(incomeStatementValue, false, true);
    }
  }

  sideBarStatus = (e) => {
    this.setState({
      sideBarStatus: e,
    });
  };

  highlightMatchedText = (label, value) => {
    // Split the label by the matched value and wrap the match with a span for styling
    const parts = label.split(new RegExp(`(${value})`, "i"));
    return parts.map((part, index) =>
      part.toLowerCase() === value.toLowerCase() ? (
        <span
          key={index}
          style={{ backgroundColor: "#2a55a5", color: "white" }}
        >
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  // autoCompleteOnClick() {
  //   alert("CLICK");
  // }

  onClickAddNewRow(name, ISTemplateFieldOutsideMap, mutilYear) {
    const { incomeStatementValue } = this.state;
    if (userSessionAuthKeyReturnValue) {
      this.setState({
        items: [],
      });

      // Object.entries(incomeStatementSuggestionTemplateResponse).map(
      //   ([key, value]) => {
      //     if (key == name) {
      //       let suggestionsList = [];
      //       incomeStatementSuggestionTemplateResponse[key] &&
      //         incomeStatementSuggestionTemplateResponse[key].length &&
      //         incomeStatementSuggestionTemplateResponse[key].map((i, idx) => {
      //           let suggestionObj = {
      //             id: idx + 1,
      //             label: i.name,
      //           };
      //           suggestionsList.push(suggestionObj);
      //           return (i.isDefault = true);
      //         });

      //       this.setState({
      //         items: suggestionsList,
      //       });
      //     }
      //   }
      // );

      axios
        .get(
          "/siteOwner/getLinesSuggestionListByParentLineNo?parentLineNo=" +
            mutilYear.parentLineNo,
          userSessionAuthKeyReturnValue
        )
        .then((suggestionResponse) => {
          if (suggestionResponse.data.length > 0) {
            console.log(suggestionResponse, "@@ SUGG");
            let suggestions = suggestionResponse.data;

            let suggestionsList = [];
            suggestions &&
              suggestions.length &&
              suggestions.map((i, idx) => {
                let suggestionObj = {
                  id: idx + 1,
                  label: i.accountDescription,
                };
                suggestionsList.push(suggestionObj);
              });

            console.log(suggestionsList, "@@ ARR");
            this.setState({
              items: suggestionsList,
            });
          } else
            this.setState({
              items: [],
            });
        });

      Object.keys(incomeStatementValue).map((heading, idx) => {
        let sortedRespArray = [...incomeStatementValue[heading]].sort(
          (a, b) => a.lineNo - b.lineNo
        );

        const emptyCustRowArray = sortedRespArray.filter(
          (obj) => obj.name == ""
        );

        if (emptyCustRowArray.length) {
          axios
            .post(
              "/annualReportLine/deleteCustomLine",
              emptyCustRowArray[0],
              userSessionAuthKeyReturnValue
            )
            .then((response) => {});
        }

        const emptyCustRowRemovedArray = sortedRespArray.filter(
          (obj) => obj.name != ""
        );
        incomeStatementValue[heading] = emptyCustRowRemovedArray;
      });

      let isCustLineInsertNeeded = false;
      let newRowObj = {};
      Object.keys(incomeStatementValue).map((heading, idx) => {
        if (heading == name) {
          let linesList = [];
          linesList = incomeStatementValue[heading].filter(
            (obj) => !obj.isLineSum
          );

          let emptyNameRowList = [];
          emptyNameRowList = linesList.filter((obj) => obj.name == "");

          if (emptyNameRowList.length == 0) {
            linesList = incomeStatementValue[heading].filter(
              (obj) => !obj.isLineSum && !obj.isFinalSum
            );

            let lineNo = ISTemplateFieldOutsideMap.lineNo + 1;
            let calcNextLineNo = false;

            for (
              let i = ISTemplateFieldOutsideMap.lineNo;
              i <= ISTemplateFieldOutsideMap.lineNo + 1000;
              i++
            ) {
              let lineNoObj = [];
              lineNoObj = [...incomeStatementValue[heading]].filter(
                (obj) => obj.lineNo == i
              );
              if (lineNoObj.length == 0 && !calcNextLineNo) {
                lineNo = i;
                calcNextLineNo = true;
              }
            }

            this.setState({
              latestCustomLineNo: lineNo,
            });

            incomeStatementTemplateReqArray.map((selectedYear, fyIdx) => {
              newRowObj = {
                name: "",
                range: "",
                year: selectedYear.validFromDate,
                statementType: "Income Statement",
                isLineSum: false,
                isHeader: false,
                lineSumRange: "",
                isFinalSum: false,
                no: ISTemplateFieldOutsideMap.no,
                parentLineNo: ISTemplateFieldOutsideMap.parentLineNo,
                lineNo: lineNo,
                lineType: "Line",
                lineLevel: ISTemplateFieldOutsideMap.lineLevel,
                financialYearValue: fyIdx == 0 ? 0 : fyIdx * -1,
                reportGUID: currentReportGUIDFromUrl,
                createdByUserID: ISTemplateFieldOutsideMap.createdByUserID,
                isCustomRow: true,
              };

              this.setState({
                reportGUID: currentReportGUIDFromUrl,
              });

              linesList.push(newRowObj);

              let responseArray = incomeStatementValue[heading];
              responseArray.push(newRowObj);

              responseArray = [...responseArray].sort(
                (a, b) => a.lineNo - b.lineNo
              );

              incomeStatementValue[heading] = responseArray;
            });
            isCustLineInsertNeeded = true;
          }
        }
      });

      this.setState({
        incomeStatementValue: incomeStatementValue,
      });

      if (isCustLineInsertNeeded) {
        axios
          .post(
            "/annualReportLine/insertCustomLine",
            newRowObj,
            userSessionAuthKeyReturnValue
          )
          .then((response) => {});
      }

      this.setState({
        reRender: true,
      });
    }
  }

  handleSelect(selectedValue, template, heading) {
    const { incomeStatementValue } = this.state;

    this.setState({ selectedValue });

    Object.keys(incomeStatementValue).map((i, idx) => {
      let filteredObjectsToRemove = incomeStatementValue[i].filter(
        (item) => item.lineNo == template.lineNo
      );

      filteredObjectsToRemove.map((j, jdx) => {
        j.name = selectedValue;
      });
    });

    Object.entries(incomeStatementSuggestionTemplateResponse).map(
      ([key, value]) => {
        if (key == heading) {
          console.log(value, "$$ VALUE", selectedValue);
          // value.filter((obj)=> obj.name != )
        }
      }
    );

    setTimeout(() => {
      // const textarea = document.querySelector(".auto-resize-textareaa");
      // textarea.style.height = "auto";
      // textarea.style.height = `${textarea.scrollHeight}px`;
      // textarea.style.height = `${textarea.textLength}px`;
      // textarea.style.height = `${(textarea.textLength / 55) * 40}px`;
    }, 200);

    this.setState({
      reRender: true,
    });
  }

  customOnChange(value, template, heading) {
    const { incomeStatementValue, items } = this.state;

    Object.keys(incomeStatementValue).map((i, idx) => {
      let filteredObjectsToRemove = incomeStatementValue[i].filter(
        (item) => item.lineNo == template.lineNo
      );

      if (
        items.length > 0 &&
        items.some((item) =>
          item.label.toLowerCase().includes(value.toLowerCase())
        )
      ) {
        filteredObjectsToRemove.map((j, jdx) => {
          j.name = value;
        });

        this.setState({
          reRender: true,
          showNoMatchFound: false,
          // isToastVisible: false,
        });
      } else {
        // Check if toast is already visible
        if (!this.state.isToastVisible) {
          this.toast.show({
            severity: "warn",
            summary: "Warn Message",
            detail: '"' + value + '"' + " is not exists in the list",
            life: 2000,
          });
          this.setState({ isToastVisible: true });

          // Set a timeout to reset the state after the toast disappears
          setTimeout(() => {
            this.setState({ isToastVisible: false });
          }, 2000); // Same duration as the toast (3000 ms)
        }

        this.setState({
          showNoMatchFound: true,
        });
      }
    });
  }

  validateForm(e, parentHeading, idx, ISTemplateFieldOutsideMap) {
    const { latestCustomLineNo, reportGUID, incomeStatementValue, items } =
      this.state;
    if (userSessionAuthKeyReturnValue) {
      let value = e.target.value;

      console.log("@@ ON BLUR)");
      if (
        items.length > 0 &&
        !items.some((item) => item.label.toLowerCase() === value.toLowerCase())
      ) {
        //setValue(""); // Clear input if the value doesn't match any valid item

        Object.keys(incomeStatementValue).map((i, idx) => {
          let filteredObjectsToRemove = incomeStatementValue[i].filter(
            (item) => item.lineNo == ISTemplateFieldOutsideMap.lineNo
          );

          filteredObjectsToRemove.map((j, jdx) => {
            j.name = "";
          });
        });

        this.setState({
          reRender: true,
        });
      } else {
        let responseArray = incomeStatementValue[parentHeading];
        responseArray.map((respValue, respIdx) => {
          if (respValue.name == "") {
            respValue.name = e.target.value;
          }
        });
      }

      let sessionDetailsLS = localStorage.getItem("sessionDetails");
      let sessionDetailsParse = JSON.parse(sessionDetailsLS);

      let newRowObj = {
        name: e.target.value,
        reportGUID: currentReportGUIDFromUrl,
        lineNo:
          latestCustomLineNo != 0
            ? latestCustomLineNo
            : ISTemplateFieldOutsideMap.lineNo,
        statementType: "Income Statement",
      };

      axios
        .post(
          "/annualReportLine/updateCustomLineName",
          newRowObj,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          if (response.data) {
            let responseJson = incomeStatementValue;
            responseJson["financialYear"] = incomeStatementTemplateReqArray;

            let currentPageName = localStorage.getItem("currentPageName");
            let updateJsonTableObj = {
              pageName: currentPageName,
              guid: currentReportGUIDFromUrl,
              json: JSON.stringify(responseJson),
            };
            axios
              .post(
                "/annualReportLine/updateLinesJsonTable",
                updateJsonTableObj,
                userSessionAuthKeyReturnValue
              )
              .then((response) => {});
          }
        });
    }
  }

  deleteCustomRow(customRowToDelete, heading) {
    const { incomeStatementValue } = this.state;
    if (userSessionAuthKeyReturnValue) {
      let objectToDelete = Object.assign({}, ...customRowToDelete);

      Object.keys(incomeStatementValue).map((i, idx) => {
        if (i == heading) {
          let filteredObjectsToRemove = incomeStatementValue[i].filter(
            (item) => item.lineNo == objectToDelete.lineNo
          );

          filteredObjectsToRemove.length &&
            filteredObjectsToRemove.map((ObjToRemove, ObjToRemoveIdx) => {
              incomeStatementValue[i].splice(
                incomeStatementValue[i].indexOf(ObjToRemove),
                1
              );
            });

          axios
            .post(
              "/annualReportLine/deleteCustomLine",
              objectToDelete,
              userSessionAuthKeyReturnValue
            )
            .then((response) => {
              if (response.data) {
                this.dataManipulation(incomeStatementValue, false, true);
                this.setState({
                  reRender: true,
                });
              }
            });
        }
      });
    }
  }

  languageValue = (e) => {};

  callback = (annualReport, session, link, status) => {
    const { sessionDetails } = this.props;
    if (status) {
      this.props.history.push(annualReport + session + link);
    }
    this.setState({
      reRender: true,
    });
  };

  removeDuplicateLineNo(fieldExistsInMultiYear) {
    let lineNoArray = [],
      lineDetailsArray = [];

    fieldExistsInMultiYear.length &&
      fieldExistsInMultiYear.map((i, idx) => {
        let lineNoWiseObjArray = fieldExistsInMultiYear.filter(
          (obj) => obj.lineNo == i.lineNo
        );

        let arr = [];
        lineNoWiseObjArray &&
          lineNoWiseObjArray.length &&
          lineNoWiseObjArray.map((j, jdx) => {
            j = JSON.parse(JSON.stringify(j));

            Object.keys(j).forEach((key) => {
              if (j[key] == null) {
                delete j[key];
              }
            });
            arr.push(j);
          });
        const mergedLines = Object.assign({}, ...arr);

        if (!lineNoArray.includes(mergedLines.lineNo)) {
          lineNoArray.push(mergedLines.lineNo);
          lineDetailsArray.push(mergedLines);
        }
      });

    return lineDetailsArray;
  }

  sideBarCallback = (value) => {
    if (value) {
      this.toast.show({
        severity: "info",
        summary: "Info",
        detail: TranslationFile.CompleteCurrentPageText[userSelectedLanguage],
        life: 5000,
      });
      this.setState({
        reRender: true,
      });
    }
  };

  tourStatus = (e) => {
    this.setState({ run: e });
  };

  render() {
    const { readOnly, showWIPStatus, companyInformation } = this.props;
    const {
      checked,
      activeIndex,
      incomeStatementValue,
      sideBarStatus,
      finalSumLbl,
      toggleOpen,
      isLoading,
      companyName,
      currentFinancialYear,
      items,
      showNoMatchFound,
    } = this.state;

    let ISFieldsArrayWithoutDuplicate = [];
    let isFieldExistsAlready = false;

    delete incomeStatementValue.financialYear;

    let pageUnderWIP = false;
    if (showWIPStatus.values != undefined) {
      if (showWIPStatus.values) {
        pageUnderWIP = true;
      } else {
        pageUnderWIP = false;
      }
    }

    return (
      <div>
        <Joyride
          continuous
          run={this.state.run}
          disableBeacon={true}
          showProgress={false}
          showSkipButton
          steps={steps}
          scrollToFirstStep
          scrollToSteps={true}
          disableScrolling={false}
          scrollOffset={100}
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
        />

        <Toast
          ref={(el) => {
            this.toast = el;
          }}
          style={{ marginTop: "34%" }}
        ></Toast>

        <NavBarHeader
          callBack={this.callback}
          isAppPortal={true}
          isHomePage={false}
        />
        <div
          className={
            pageUnderWIP
              ? "companyInfoSideBarWithWIP"
              : "incomeStatementSideBar"
          }
        >
          <Sidebar
            children="Income Statement"
            sideBarStatus={this.sideBarStatus.bind(this)}
            sideBarCallback={this.sideBarCallback}
            tourStatus={this.tourStatus.bind(this)}
          />
        </div>
        <div>
          <Row
            className={sideBarStatus ? "ISFYStyle" : "ISFYStyle-sidebar-close"}
          >
            <div className="ISparent">
              <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                <div>
                  <div className="Page_Title_incomeStatement">
                    <div
                      className="Page_Title_T1_incomestatement"
                      id="sample-id"
                    >
                      {this.state.companyName}
                    </div>
                  </div>
                </div>
              </Col>
              <Col
                xs={7}
                sm={7}
                md={7}
                lg={7}
                xl={7}
                className="ISAmountBoxCol"
              >
                {dateTemplate &&
                  dateTemplate.length > 0 &&
                  dateTemplate.map((selectedYear, fyIdx) => {
                    return (
                      <div className="ISFYMainDiv">
                        <label className="ISFromFY">
                          {selectedYear.validFromDate}
                        </label>
                        <label className="ISToFY">
                          - {selectedYear.validToDate}
                        </label>
                      </div>
                    );
                  })}
              </Col>
              <Col>
                <Button
                  type="button"
                  icon="pi pi-file-pdf"
                  outlined
                  style={{ borderRadius: "70px" }}
                  className="pdf_view_incomestatement"
                  tooltip={TranslationFile.pdfPreview[userSelectedLanguage]}
                  tooltipOptions={{ position: "bottom" }}
                  // onClick={() => this.navigateToPDF()}
                  onClick={() => previewPdf(currentReportGUIDFromUrl)}
                />
              </Col>

              <div className="ISExapndCollapsToggleLbl">
                {toggleOpen == true ? (
                  <Button
                    value={toggleOpen}
                    icon="pi pi-chevron-down"
                    title="Expand"
                    onClick={(e) => this.handleChange(toggleOpen)}
                    className="toggle-open"
                  />
                ) : (
                  <Button
                    value={toggleOpen}
                    icon="pi pi-chevron-up"
                    title="Collapse"
                    onClick={(e) => this.handleChange(toggleOpen)}
                    className="toggle-open"
                  />
                )}
              </div>
            </div>
          </Row>
        </div>

        <div className={sideBarStatus ? "ISSideBarOpen" : "ISSideBarClose"}>
          {isLoading ? (
            <div className="ISLoading">
              <i
                className="pi pi-spin pi-spinner"
                style={{ fontSize: "2rem" }}
              ></i>
            </div>
          ) : (
            <div>
              <Row className="ISParentRow">
                <Col
                  xs={8}
                  sm={8}
                  md={8}
                  lg={8}
                  xl={8}
                  style={{ width: "100%" }}
                >
                  {incomeStatementValue != undefined && (
                    <Accordion
                      multiple
                      activeIndex={activeIndex}
                      onTabChange={(e) =>
                        this.setState({ activeIndex: e.index })
                      }
                      ref={this.myRef}
                    >
                      {Object.keys(incomeStatementValue).map(
                        (heading, parentIdx) => {
                          ISFieldsArrayWithoutDuplicate = [];
                          let lineSumCount = 0;
                          let responseArray = incomeStatementValue[heading];
                          responseArray = [...responseArray].sort(
                            (a, b) => a.lineNo - b.lineNo
                          );
                          let header = responseArray[0].name;
                          let addNewRowBtn = false;
                          let addNewRowBtnIdx = 0;
                          let isPreviousLineSum = false;

                          return (
                            <AccordionTab
                              header={this.accordionHeader(header)}
                              ref={this.myRef}
                              className="IS-Accordion-Tab"
                            >
                              {responseArray.map(
                                (ISTemplateFieldOutsideMap, idx) => {
                                  if (
                                    idx != 0 &&
                                    header != ISTemplateFieldOutsideMap.name
                                  ) {
                                    if (
                                      ISFieldsArrayWithoutDuplicate.includes(
                                        ISTemplateFieldOutsideMap.name +
                                          ISTemplateFieldOutsideMap.lineNo
                                      )
                                    ) {
                                      isFieldExistsAlready = true;
                                    } else {
                                      isFieldExistsAlready = false;
                                      ISFieldsArrayWithoutDuplicate.push(
                                        ISTemplateFieldOutsideMap.name +
                                          ISTemplateFieldOutsideMap.lineNo
                                      );
                                    }

                                    let fieldExistsInMultiYear = [];

                                    responseArray.map(
                                      (
                                        ISTemplateFieldInsideMap,
                                        ISTemplateFieldInsideMapIdx
                                      ) => {
                                        if (
                                          ISTemplateFieldInsideMap.name ==
                                            ISTemplateFieldOutsideMap.name &&
                                          ISTemplateFieldInsideMap.lineNo ==
                                            ISTemplateFieldOutsideMap.lineNo &&
                                          !isFieldExistsAlready &&
                                          (ISTemplateFieldInsideMap.financialYearValue ==
                                            0 ||
                                            ISTemplateFieldInsideMap.financialYearValue ==
                                              -1)

                                          // ((incomeStatementTemplateReqArray.length ==
                                          //   1 &&
                                          //   ISTemplateFieldInsideMap.financialYearValue ==
                                          //     0) ||
                                          //   (incomeStatementTemplateReqArray.length ==
                                          //     2 &&
                                          //     ISTemplateFieldInsideMap.financialYearValue ==
                                          //       -1))
                                        ) {
                                          fieldExistsInMultiYear.push(
                                            ISTemplateFieldInsideMap
                                          );
                                        }
                                      }
                                    );

                                    let prevousObject = responseArray[idx - 1];
                                    if (prevousObject.isLineSum) {
                                      isPreviousLineSum = true;
                                    } else {
                                      isPreviousLineSum = false;
                                    }

                                    if (
                                      ISTemplateFieldOutsideMap.isLineSum &&
                                      !isFieldExistsAlready &&
                                      !isPreviousLineSum
                                    ) {
                                      addNewRowBtnIdx = 0;
                                    } else {
                                      addNewRowBtnIdx = addNewRowBtnIdx + 1;
                                    }

                                    return (
                                      <div>
                                        <Row
                                          className="ISFields"
                                          style={{
                                            marginTop:
                                              isPreviousLineSum && "8px",
                                          }}
                                        >
                                          {!isFieldExistsAlready && (
                                            <Col
                                              xs={5}
                                              sm={5}
                                              md={5}
                                              lg={5}
                                              xl={5}
                                              style={{
                                                marginTop:
                                                  isPreviousLineSum && "-23px",
                                              }}
                                            >
                                              <div className="ISLblRowMainsideDiv">
                                                {ISTemplateFieldOutsideMap.isCustomRow ? (
                                                  // <InputTextarea
                                                  //   disabled={
                                                  //     readOnly.values && true
                                                  //   }
                                                  //   className="IScustomFieldText"
                                                  //   value={
                                                  //     ISTemplateFieldOutsideMap.name
                                                  //   }
                                                  //   onBlur={(e) =>
                                                  //     this.validateForm(
                                                  //       e,
                                                  //       heading,
                                                  //       idx,
                                                  //       ISTemplateFieldOutsideMap
                                                  //     )
                                                  //   }
                                                  //   onChange={(e) => {
                                                  //     this.customOnChange(
                                                  //       e.target.value,
                                                  //       ISTemplateFieldOutsideMap,
                                                  //       heading
                                                  //     );
                                                  //   }}
                                                  //   placeholder={
                                                  //     TranslationFile
                                                  //       .EnterYourDescriptionText[
                                                  //       userSelectedLanguage
                                                  //     ]
                                                  //   }
                                                  // />

                                                  <Autocomplete
                                                    getItemValue={(item) =>
                                                      item.label
                                                    }
                                                    items={this.state.items.filter(
                                                      (item) =>
                                                        item.label
                                                          .toLowerCase()
                                                          .includes(
                                                            ISTemplateFieldOutsideMap.name.toLowerCase()
                                                          )
                                                    )}
                                                    renderItem={(
                                                      item,
                                                      isHighlighted
                                                    ) => (
                                                      <div
                                                        key={item.id}
                                                        style={{
                                                          background:
                                                            isHighlighted
                                                              ? "lightgray"
                                                              : "white",
                                                          padding: "5px",
                                                          cursor: "pointer",
                                                          borderBottom:
                                                            "1px solid #ddd",
                                                        }}
                                                        className="suggestionDiv"
                                                      >
                                                        {this.highlightMatchedText(
                                                          item.label,
                                                          ISTemplateFieldOutsideMap.name
                                                        )}
                                                      </div>
                                                    )}
                                                    value={
                                                      ISTemplateFieldOutsideMap.name
                                                    }
                                                    onSelect={(e) => {
                                                      this.handleSelect(
                                                        e,
                                                        ISTemplateFieldOutsideMap,
                                                        heading
                                                      );
                                                    }}
                                                    onChange={(e) => {
                                                      this.customOnChange(
                                                        e.target.value,
                                                        ISTemplateFieldOutsideMap,
                                                        heading
                                                      );
                                                    }}
                                                    renderInput={(props) => (
                                                      <textarea
                                                        {...props}
                                                        className="auto-resize-textareaa"
                                                        // ref={this.inputRef}
                                                        // onClick={this.autoCompleteOnClick()}
                                                      />
                                                    )}
                                                    // onClick={this.autoCompleteOnClick()}
                                                    inputProps={{
                                                      ref: this.inputRef,
                                                      onBlur: (e) =>
                                                        this.validateForm(
                                                          e,
                                                          heading,
                                                          idx,
                                                          ISTemplateFieldOutsideMap
                                                        ),

                                                      disabled:
                                                        readOnly.values && true,

                                                      placeholder:
                                                        TranslationFile
                                                          .EnterYourDescriptionText[
                                                          userSelectedLanguage
                                                        ],

                                                      style: {
                                                        // width: "400px",
                                                        outline: "none",
                                                        width: "400px",
                                                        // width: "50%",
                                                        //width: "100%", // Ensure it takes full width of its container
                                                        whiteSpace: "pre-wrap", // Enable wrapping for long words
                                                        wordWrap: "break-word", // Allow word breaking
                                                        overflowWrap:
                                                          "break-word", // For newer browsers
                                                        resize: "none", // Prevent resizing of textarea
                                                        minHeight: "40px", // Minimum height to start
                                                        // height: "auto", // Adjust height automatically
                                                        lineHeight: "1.5", // Increase line height for better readability
                                                        padding: "5px",
                                                        borderRadius: "10px",
                                                        borderColor: "#cdcdcd",
                                                        // height: "40px",
                                                        overflowY: "hidden",
                                                        height:
                                                          ISTemplateFieldOutsideMap
                                                            .name.length /
                                                            55 >
                                                          1.0
                                                            ? Math.round(
                                                                ISTemplateFieldOutsideMap
                                                                  .name.length /
                                                                  55
                                                              ) +
                                                              1 * 50 +
                                                              8
                                                            : Math.round(
                                                                ISTemplateFieldOutsideMap
                                                                  .name.length /
                                                                  55
                                                              ) *
                                                                30 +
                                                              8,
                                                      },
                                                    }}
                                                    renderMenu={(
                                                      items,
                                                      value,
                                                      style
                                                    ) => (
                                                      <div
                                                        style={{
                                                          ...style,
                                                          marginTop: "5px",
                                                          maxHeight: "200px",
                                                          overflowY: "auto",
                                                          // width: "100%",
                                                          width: "360px",
                                                          lineHeight: 2,
                                                          boxShadow:
                                                            "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                                        }}
                                                      >
                                                        {items.length > 0 ? (
                                                          items
                                                        ) : (
                                                          <div
                                                            style={{
                                                              padding: "5px",
                                                              color: "gray",
                                                            }}
                                                          >
                                                            No matches found
                                                          </div>
                                                        )}
                                                      </div>
                                                    )}
                                                  />
                                                ) : ISTemplateFieldOutsideMap.isLineSum ? (
                                                  <div
                                                    className="ISLblRowInsideDiv"
                                                    id={
                                                      !addNewRowBtn &&
                                                      addNewRowBtnIdx == 1 &&
                                                      "ISLblRowInsideDivId"
                                                    }
                                                  >
                                                    <label className="ISTotalFieldsStyle">
                                                      {
                                                        ISTemplateFieldOutsideMap.name
                                                      }
                                                    </label>
                                                  </div>
                                                ) : (
                                                  !ISTemplateFieldOutsideMap.isFinalSum && (
                                                    <label className="ISFieldsStyle">
                                                      {
                                                        //sw
                                                        ISTemplateFieldOutsideMap.name
                                                        // Net Sales => Translation() => true --- corresponding sw ? ---visible
                                                        // Translation(ISTemplateFieldOutsideMap.name, 'sw')
                                                      }
                                                    </label>
                                                  )
                                                )}
                                              </div>
                                            </Col>
                                          )}

                                          {!isFieldExistsAlready && (
                                            <Col
                                              xs={7}
                                              sm={7}
                                              md={7}
                                              lg={7}
                                              xl={7}
                                              className="ISAmountBoxCol"
                                              style={{
                                                marginTop:
                                                  ISTemplateFieldOutsideMap.isLineSum &&
                                                  "-22px",
                                              }}
                                            >
                                              {fieldExistsInMultiYear.map(
                                                (mutilYear, multiYearIdx) => {
                                                  if (
                                                    parentIdx == 0 &&
                                                    multiYearIdx == 0 &&
                                                    idx == 1
                                                  ) {
                                                  }
                                                  if (!mutilYear.isFinalSum)
                                                    return (
                                                      <div className="ISAmountBoxRowDiv">
                                                        {mutilYear.isLineSum ? (
                                                          <div>
                                                            <InputNumber
                                                              mode="decimal"
                                                              inputId="integeronly"
                                                              className="ISAmountBoxDisabled"
                                                              disabled={true}
                                                              value={Math.round(
                                                                mutilYear.lineAmount
                                                              )}
                                                              id={
                                                                addNewRowBtnIdx ==
                                                                  1 &&
                                                                "isFirstLineSum"
                                                              }
                                                              useGrouping={true}
                                                              locale="sv-SE"
                                                            />
                                                          </div>
                                                        ) : mutilYear.lineAmount !=
                                                            undefined &&
                                                          mutilYear.lineAmount !=
                                                            0 ? (
                                                          <div>
                                                            <InputNumber
                                                              disabled={
                                                                (readOnly.values ||
                                                                  linkSharedUser ==
                                                                    3) &&
                                                                true
                                                              }
                                                              className="ISInputBox"
                                                              value={Math.round(
                                                                mutilYear.lineAmount
                                                              )}
                                                              onBlur={(e) =>
                                                                this.AmountOnBlur(
                                                                  e,
                                                                  mutilYear,
                                                                  heading
                                                                )
                                                              }
                                                              useGrouping={true}
                                                              locale="sv-SE"
                                                            />

                                                            {fieldExistsInMultiYear.length ==
                                                              multiYearIdx +
                                                                1 &&
                                                              !ISTemplateFieldOutsideMap.isCustomRow && (
                                                                <i
                                                                  id={
                                                                    parentIdx ==
                                                                      0 &&
                                                                    idx == 1 &&
                                                                    multiYearIdx ==
                                                                      0
                                                                      ? "ISAddNewLineIcon"
                                                                      : "ISAddNewLineIcon123"
                                                                  }
                                                                  // id="ISAddNewLineIcon"
                                                                  class="fa fa-plus"
                                                                  aria-hidden="true"
                                                                  onClick={(
                                                                    e
                                                                  ) =>
                                                                    this.onClickAddNewRow(
                                                                      heading,
                                                                      ISTemplateFieldOutsideMap,
                                                                      mutilYear
                                                                    )
                                                                  }
                                                                  title="Add New Line"
                                                                  style={{
                                                                    display:
                                                                      linkSharedUser ==
                                                                        3 &&
                                                                      "none",
                                                                  }}
                                                                ></i>
                                                              )}
                                                          </div>
                                                        ) : (
                                                          !mutilYear.isHeader && (
                                                            <div>
                                                              <InputNumber
                                                                // style={{ border: "1px solid green " }}
                                                                className="ISInputBox"
                                                                disabled={
                                                                  (readOnly.values ||
                                                                    linkSharedUser ==
                                                                      3 ||
                                                                    mutilYear.name ==
                                                                      "") &&
                                                                  true
                                                                }
                                                                onBlur={(e) =>
                                                                  this.AmountOnBlur(
                                                                    e,
                                                                    mutilYear,
                                                                    heading
                                                                  )
                                                                }
                                                                useGrouping={
                                                                  true
                                                                }
                                                                locale="sv-SE"
                                                              />

                                                              {fieldExistsInMultiYear.length ==
                                                                multiYearIdx +
                                                                  1 &&
                                                                !ISTemplateFieldOutsideMap.isCustomRow && (
                                                                  <i
                                                                    id={
                                                                      parentIdx ==
                                                                        0 &&
                                                                      idx ==
                                                                        1 &&
                                                                      multiYearIdx ==
                                                                        0
                                                                        ? "ISAddNewLineIcon"
                                                                        : "ISAddNewLineIcon123"
                                                                    }
                                                                    class="fa fa-plus"
                                                                    aria-hidden="true"
                                                                    onClick={(
                                                                      e
                                                                    ) =>
                                                                      this.onClickAddNewRow(
                                                                        heading,
                                                                        ISTemplateFieldOutsideMap,
                                                                        mutilYear
                                                                      )
                                                                    }
                                                                    title="Add New Line"
                                                                    style={{
                                                                      display:
                                                                        linkSharedUser ==
                                                                          3 &&
                                                                        "none",
                                                                    }}
                                                                  ></i>
                                                                )}
                                                            </div>
                                                          )
                                                        )}
                                                      </div>
                                                    );
                                                }
                                              )}
                                              {fieldExistsInMultiYear &&
                                              fieldExistsInMultiYear.length &&
                                              fieldExistsInMultiYear[0]
                                                .isCustomRow ? (
                                                <i
                                                  // id="ISCustomRowDelete"
                                                  style={{
                                                    display:
                                                      linkSharedUser == 3 &&
                                                      "none",
                                                  }}
                                                  id={
                                                    sideBarStatus
                                                      ? "ISCustomRowDeleteOpenSideBar"
                                                      : "ISCustomRowDeleteCloseSideBar"
                                                  }
                                                  className="pi pi-trash"
                                                  onClick={this.deleteCustomRow.bind(
                                                    this,
                                                    fieldExistsInMultiYear,
                                                    heading
                                                  )}
                                                />
                                              ) : (
                                                ""
                                              )}
                                            </Col>
                                          )}
                                        </Row>
                                      </div>
                                    );
                                  }
                                }
                              )}
                            </AccordionTab>
                          );
                        }
                      )}
                    </Accordion>
                  )}
                </Col>
              </Row>
            </div>
          )}
          {!isLoading && (
            <Row className="ISParentRow" style={{ marginTop: "1%" }}>
              <Col
                xs={8}
                sm={8}
                md={8}
                lg={8}
                xl={8}
                style={{ marginLeft: "11px", width: "100%" }}
              >
                <Row className="ISFYStyle-final">
                  <div
                    className="ISparent-final"
                    style={{ background: "rgb(255 255 255)" }}
                  >
                    <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                      <div>
                        <label className="ISFinalTotalLbl">{finalSumLbl}</label>
                      </div>
                    </Col>
                    <Col
                      xs={7}
                      sm={7}
                      md={7}
                      lg={7}
                      xl={7}
                      className="ISAmountBoxCol"
                    >
                      {incomeStatementValue != undefined && (
                        <div className="ISFinalSumParentDiv">
                          {Object.keys(incomeStatementValue).map(
                            (heading, idx) => {
                              return incomeStatementValue[heading].map(
                                (i, idx) => {
                                  if (
                                    i.isFinalSum &&
                                    (i.financialYearValue == 0 ||
                                      i.financialYearValue == -1)
                                  ) {
                                    return (
                                      <div className="ISFinalSumDiv">
                                        <InputNumber
                                          mode="decimal"
                                          inputId="integeronly"
                                          className="ISFinalLineSum"
                                          disabled={true}
                                          value={Math.round(i.lineAmount)}
                                          useGrouping={true}
                                          locale="sv-SE"
                                        />
                                      </div>
                                    );
                                  }
                                }
                              );
                            }
                          )}
                        </div>
                      )}
                    </Col>
                  </div>
                </Row>
              </Col>

              <center className="ISSaveBtnCenter">
                <Button
                  label={TranslationFile.previousBtn[userSelectedLanguage]}
                  aria-label={
                    TranslationFile.FooterAnnualReport[userSelectedLanguage]
                  }
                  onClick={() => this.pageNavigation("companyInfo")}
                  id="annualReportBtn"
                  className="ISSaveBtn"
                />
                <Button
                  label={TranslationFile.nextBtn[userSelectedLanguage]}
                  aria-label={
                    TranslationFile.FooterAnnualReport[userSelectedLanguage]
                  }
                  onClick={() => this.pageNavigation("balanceSheet")}
                  id="annualReportBtn"
                  className="ISSaveBtn"
                />
              </center>
            </Row>
          )}
        </div>
        <ScrolltoTop />
        <Footer_contact_tab
          sideBar={this.state.sideBarStatus}
          isCorpPortal={false}
          isHomePage={true}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(IncomeStatement);
