import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Dropdown } from "primereact/dropdown";
import { RiArrowDownSFill } from "react-icons/ri";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from "axios";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SelectButton } from "primereact/selectbutton";
import { validateEmail } from "../Login/utils";
import { InputTextarea } from "primereact/inputtextarea";
import { setUserSessionAuthKey, previewPdf } from "../../Component/commonUtils";
import NavBarHeader from "../NavBarHeader/NavBarHeader";
import TranslationFile from "../15-Translation/locale/Translation_apps.json";
import ScrolltoTop from "../ScrollTop/ScrollTop";
import Sidebar from "../Sidebar/Sidebar";
import "./MyDashboard.css";
import Footer_contact_tab from "../Footerpage/Footer_contact_tab";
import { TabView, TabPanel } from "primereact/tabview";
import { Card } from "primereact/card";
import config from "../../Configuration/Config";
import { SplitButton } from 'primereact/splitbutton';
import { OverlayPanel } from 'primereact/overlaypanel';
import { RadioButton } from 'primereact/radiobutton';
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';
import { Password } from "primereact/password";



let accRegNo = "",
  transformedArray = [];
let userSelectedLanguage = localStorage.getItem("selectedLanguage");
let unPaidReportsArray = [];
let userTourGuide = JSON.parse(localStorage.getItem("TourGuide")) || false;


let steps = [
  {
    content: TranslationFile.TourGuide_Report_Sharing_Btn[userSelectedLanguage],
    placement: 'top',
    styles: {
      options: {
        width: 300,
      },
    },
    target: '.dashboardTab',
    title: 'Accordion',
    disableBeacon: true,
  },
  {
    content: TranslationFile.TourGuide_Redirect_CI_Btn[userSelectedLanguage],
    placement: 'top',
    styles: {
      options: {
        width: 300,
      },
    },
    target: '.dashbaord-companyName',
    title: 'Company Name',
    disableBeacon: true,
  },
  {
    content: TranslationFile.TourGuide_Share_link_Btn[userSelectedLanguage],
    placement: 'top',
    styles: {
      options: {
        width: 300,
      },
    },
    target: '.linkshareTab',
    title: 'Link hare',
    disableBeacon: true,
  },
  {
    content: TranslationFile.TourGuide_PDF_Btn[userSelectedLanguage],
    placement: 'top',
    styles: {
      options: {
        width: 300,
      },
    },
    target: '.dashboard-pdf-Id',
    title: 'Pdf',
    disableBeacon: true,
  },
];


let userDetailsByEmailIdRespData;
export default function MyDashboard() {
  userSelectedLanguage = localStorage.getItem("selectedLanguage");
  let userTimeZone = localStorage.getItem("userTimeZone");
  const history = useHistory();
  let isValidEmailIDCheck = false;
  let emptyProduct = {
    id: null,
    companyName: "",
    organizationNumber: "",
    financialYear: "",
    linkSharing: "",
    accountingCompanySharing: "",
    createdOn: "",
    createdBy: "",
    modifiedOn: "",
    modifiedBy: "",
    reportStatus: "",
  };
  let userSessionAuthKeyReturnValue = setUserSessionAuthKey();

  const getSeverityActive = (Active) => {
    switch (Active) {
      case "Read Only":
        return "warning";

      case "Write":
        return "success";

      case "No Access":
        return "danger";
    }
  };

  const shareAccess = [
    { label: TranslationFile.dashboardLinkShareRead[userSelectedLanguage], code: "Read Only", name: "Read Only" },
    { label: TranslationFile.dashboardLinkShareWrite[userSelectedLanguage], code: "Write", name: "Write" },
    { label: TranslationFile.dashboardLinkShareNoAccess[userSelectedLanguage], code: "No Access", name: "No Access" },
  ];

  const peopleWithAccess = [
    { name: "Read Only", code: "Read Only" },
    { name: "Write", code: "Write" },
    { name: "Remove Access", code: "Write" },
  ];

  const [shareEmail, setShareEmail] = useState(null);
  const [peopleselectedAccess, setPeopleSelectedAccess] = useState(
    peopleWithAccess[0]
  );

  const [statuses] = useState(["Draft", "Completed"]);
  const [statuses1] = useState(["Paid"]);
  const [productDialog, setProductDialog] = useState(false);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [product, setProduct] = useState(emptyProduct);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);
  const [userDetails, setUserDetails] = useState(null);
  const [shareProductDialog, setShareProductDialog] = useState(false);
  const [sideBarOpenState, setSideBarOpenState] = useState(true);
  const [dashboardAPIResponse, setDashboardAPIResponse] = useState("");
  const [addEmailId, setAddEmailId] = useState("");
  const [addedEmailIdCount, setAddedEmailIdCount] = useState(0);
  const [selectedRowData, setSelectedRowData] = useState("");
  const [shareEmailFocus, setShareEmailFocus] = useState(false);
  const [shareEmailDialogOverlap, setShareEmailDialogOverlap] = useState(true);
  const [isEmailIdAlreadyExists, setIsEmailIdAlreadyExists] = useState(false);
  const [linkSharedMessage, setLinkSharedMessage] = useState("");
  const [checked, setChecked] = useState(true);
  const [userList, setUserList] = useState('');
  const [companyMembers, setCompanyMembers] = useState([]);
  const [reRender, setReRender] = useState(false);
  const [run, setRun] = useState(false);
  const [showUserNamePasswordDialogBox, setShowUserNamePasswordDialogBox] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userEmailId, setUserEmailId] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [userConfirmPassword, setUserConfirmPassword] = useState('');
  const [userId, setUserId] = useState('');
  const [sharingDisabled, setSharingDisabled] = useState(false);
  const currentPageTourGuideName = "Dashboard";
  const [isMasked, setIsMasked] = useState(true);
  const [filters, setFilters] = useState({

    companyName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    organizationNumber: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    financialYear: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    createdOn: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    createdBy: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    modifiedOn: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    modifiedBy: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    status: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.IN }],
    },
    date: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
  });
  const [inputFields, setInputFields] = useState([
    {
      fullName: "",
      fullNameDp: shareAccess && shareAccess[2].name,
    },
  ]);

  const [inputdata, setInputDate] = useState({
    emailId: "",
    accessLevel: shareAccess && shareAccess[2].name,
  });
  const [inputarr, setInputarr] = useState([]);
  const [selectedAccess, setSelectedAccess] = useState([
    {
      fullNameDp: shareAccess && shareAccess[2].name,
    },
  ]);

  //FOCUS
  const [emailIdFocus, setEmailIdFocus] = useState(false);
  const [isValidEmailId, setIsValidEmailId] = useState(false);
  const loginReducer = useSelector((state) => state.login);
  const sidebarReducer = useSelector((state) => state.sidebarReducer);
  let isAccCompany =
    (loginReducer.login.values != null && loginReducer.login.values.accountingCompany != null && loginReducer.login.values.accountingCompany != undefined)
      ? loginReducer.login.values.accountingCompany
      : false;
  const [activeIndex, setActiveIndex] = useState(isAccCompany ? 0 : 1);




  useEffect(() => {

    localStorage.setItem("currentPageName", "Dashboard");
    window.scrollTo(0, 0);
    let localData = localStorage.getItem("rebelSkoolUser");
    let localObject = localData && JSON.parse(localData);
    userSessionAuthKeyReturnValue = setUserSessionAuthKey();
    if (
      loginReducer.login.values != undefined &&
      userSessionAuthKeyReturnValue &&
      localObject.userID != undefined
    ) {
      axios
        .get(
          "/userDashboard/getLinkSharedDetails",
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          let respData = response.data;
          if (respData.length == 0) {
            checkForSharedReports();
          } else {
            respData.map((i, idx) => {
              if (i.createdAtDateTime > i.modifiedAtDateTime) {
                return (i.modifiedAtDateTime = "");
              }
            });
            respData = respData.reverse();
            setDashboardAPIResponse(respData);
          }
        });
    }

    const timer = setTimeout(() => {
      let userTourGuide = JSON.parse(localStorage.getItem("TourGuide")) || false;
      setRun(userTourGuide);
    }, 200);
    return () => clearTimeout(timer);

  }, []);
  //If my reports has no data then we have to check if shared reports have any data.
  //If it has we habe to show shared reports tab
  //If shared reports also have no data. then we have to show my reports tab

  const isValidEmailID = (isValidEmailId) => {
    if (isValidEmailId) {
      setIsValidEmailId(true);
    } else {
      setIsValidEmailId(false);
    }
  };

  const getCustomers = (data) => {
    return [...(data || [])].map((d) => {
      d.createdOn = new Date(d.createdOn);
      return d;
    });
  };
  const getSeverityOrg = (product, status) => {
    switch (status) {
      case "Completed":
        return "success";

      case "Draft":
        return "warning";

      case "Paid":
        return "success";
      default:
        return null;
    }
  };

  const toggleMask = () => {
    setIsMasked((prev) => !prev);
  };


  const getSeverity = (status) => {
    // let statusValue = "Draft";
    // if (status) {
    //   statusValue = "Completed";
    // }

    switch (status) {
      case "Completed":
        return "success";

      case "Draft":
        return "warning";
    }
  };

  const hideDialog = () => {
    setSubmitted(false);
    setProductDialog(false);
  };

  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };

  const hideDeleteProductsDialog = () => {
    setDeleteProductsDialog(false);
  };

  const confirmDeleteProduct = (product) => {
    setProduct(product);
    setDeleteProductDialog(true);
  };

  const confirmDeleteSelected = () => {
    let paidReportArray = [];
    selectedProducts && selectedProducts.forEach((val) => {
      if (val.paymentEntryNo > 0) {
        paidReportArray.push(val);
      } else {
        unPaidReportsArray.push(val);
      }
      unPaidReportsArray = [...new Set(unPaidReportsArray)];
      // setSelectedProducts(unPaidReportsArray);
      if (paidReportArray.length > 0) {
        toast.current.show({
          severity: "error",
          summary: "No Delete Applicable",
          detail: "The Paid Report cannot be deleted",
          life: 3000,
        });
      }
      else {

        setDeleteProductsDialog(true);
      }
    });

  };

  const deleteProduct = () => {
    setDeleteProductDialog(false);
    setProduct(emptyProduct);
    toast.current.show({
      severity: "success",
      summary: TranslationFile.SuccessfulText[userSelectedLanguage],
      detail: TranslationFile.ProductDeleted[userSelectedLanguage],
      life: 3000,
    });
  };

  const deleteSelectedProducts = () => {
    if (userSessionAuthKeyReturnValue) {
      let reportIds = "";
      selectedProducts.length &&
        selectedProducts.map((i, idx) => {
          if (reportIds == "") reportIds = i.reportId;
          else reportIds = reportIds + "," + i.reportId;
        });

      axios
        .get(
          "/annualReport/hideReportHeaders?reportIds=" + reportIds,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          axios
            .get(
              "/userDashboard/getLinkSharedDetails",
              userSessionAuthKeyReturnValue
            )
            .then((response) => {
              let respData = response.data;
              respData.map((i, idx) => {
                if (i.createdAtDateTime > i.modifiedAtDateTime) {
                  return (i.modifiedAtDateTime = "");
                }
              });
              respData = respData.reverse();
              setDashboardAPIResponse(respData);
            });
        });

      setDeleteProductsDialog(false);
      setSelectedProducts(null);
      toast.current.show({
        severity: "success",
        summary: TranslationFile.SuccessfulText[userSelectedLanguage],
        detail: TranslationFile.ReportDeletedSuces[userSelectedLanguage],
        life: 3000,
      });
    }
  };

  const leftToolbarTemplate = () => {
    return (
      <div>
        {value == options[0] && (
          <Button
            className="whole-delete"
            label={TranslationFile.dashboardDeleteBtn[userSelectedLanguage]}
            icon="pi pi-trash"
            severity="danger"
            onClick={confirmDeleteSelected}
            disabled={!selectedProducts || !selectedProducts.length}
          />
        )}
      </div>
    );
  };
  const [accessValueRadio, setAccessValueRadio] = useState('')
  const op = useRef(null);

  const shareAccessOnClick = () => {
    let reqArray = [];

    companyMembers && companyMembers.forEach((value) => {
      let req = {
        reportId: value.reportId,
        createdByUserId: loginReducer && loginReducer.login.values.userID,
        userAccessLevel: accessValueRadio,
        emailId: value.emailId,
        isInsert: true,
      };
      reqArray.push(req)
    })
    if (userSessionAuthKeyReturnValue) {
      axios
        .post(
          "/annualReport/saveReportAccessList",
          reqArray,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          if (response.data) {
            let temp = [];
            toast.current.show({
              severity: "success",
              summary: TranslationFile.SuccessfulText[userSelectedLanguage],
              detail: TranslationFile.ReportSharedSuces[userSelectedLanguage],
              life: 3000,
            });
            temp = companyMembers
            temp.map((val) => {
              return val.accessLevel = accessValueRadio
            })
            temp && temp.map((val) => {
              setCompanyMembers((companyMembers) => [...companyMembers])
            })
          }
          setAccessValueRadio('')
          op.current.hide()
        });
    }


  }

  const leftToolbarTemplatebox2 = () => {
    return (
      <div>
        <Button
          type="button"
          label={TranslationFile.sharetoAllUserText[userSelectedLanguage]}
          onClick={(e) => op.current.toggle(e)}
        />
        <OverlayPanel ref={op}>
          <div style={{ width: "200px" }}>
            <div style={{ textAlign: 'end' }}>
              <i className="pi pi-times" onClick={() => op.current.hide()}></i>
            </div>
            <div className="common-share-dash">
              <RadioButton
                inputId="readOnly"
                value="Read Only"
                onChange={(e) => setAccessValueRadio(e.value)}
                checked={accessValueRadio === "Read Only"}
              />
              <label style={{ paddingLeft: "10px" }} htmlFor="readOnly">
                Read Only
              </label>
            </div>
            <div className="common-share-dash">
              <RadioButton
                inputId="write"
                value="Write"
                onChange={(e) => setAccessValueRadio(e.value)}
                checked={accessValueRadio === "Write"}
              />
              <label style={{ paddingLeft: "10px" }} htmlFor="write">
                Write
              </label>
            </div>
            <div className="common-share-dash">
              <RadioButton
                inputId="noAccess"
                value="No Access"
                onChange={(e) => setAccessValueRadio(e.value)}
                checked={accessValueRadio === "No Access"}
              />
              <label style={{ paddingLeft: "10px" }} htmlFor="noAccess">
                No Access
              </label>
            </div>
            <div style={{ textAlign: 'center', marginTop: "10%" }}>
              <Button
                label={TranslationFile.dashboardLinkShareBtn[userSelectedLanguage]}
                severity="success"
                icon="pi pi-check"
                autoFocus
                disabled={accessValueRadio == ''}
                onClick={shareAccessOnClick}
              />
            </div>
          </div>
        </OverlayPanel>
      </div>
    );
  }

  const confirmShareProduct = (product) => {
    setProduct(product);
    setShareProductDialog(true);
  };

  const hideShareProductDialog = () => {
    setInputFields([
      {
        fullName: "",
        fullNameDp: shareAccess && shareAccess[2].name,
      },
    ]);
    setShareProductDialog(false);
    axios
      .get(
        "/userDashboard/getLinkSharedDetails",
        userSessionAuthKeyReturnValue
      )
      .then((response) => {
        let respData = response.data;
        respData.map((i, idx) => {
          if (i.createdAtDateTime > i.modifiedAtDateTime) {
            return (i.modifiedAtDateTime = "");
          }
        });
        respData = respData.reverse();
        setDashboardAPIResponse(respData);
      });
    if (isAccCompany) {
      setActiveIndex(0)
    } else {
      setActiveIndex(1)
    }

  };

  const header = () => {
    return (
      <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder={
              TranslationFile.dashboardSearchBtn[userSelectedLanguage]
            }
            className="dashboard-search-bar"
          />
        </span>
      </div>
    );
  };

  // const options = ["My Reports", "Shared with me"];
  const options = [TranslationFile.dashboardMyReportsBtn[userSelectedLanguage],
  TranslationFile.dashboardSharedBtn[userSelectedLanguage]];
  const [value, setValue] = useState(options[0]);

  const dashboardTabOnChange = (e) => {
    if (userSessionAuthKeyReturnValue) {
      if (null == e) {
        setValue(value);
      } else {
        setValue(e);
      }
      if (e == options[0]) {
        if (localObject.emailID != undefined) {
          axios
            .get(
              "/userDashboard/getLinkSharedDetails",
              userSessionAuthKeyReturnValue
            )
            .then((response) => {
              let respData = response.data;
              respData.map((i, idx) => {
                if (i.createdAtDateTime > i.modifiedAtDateTime) {
                  return (i.modifiedAtDateTime = "");
                }
              });
              respData = respData.reverse();
              setDashboardAPIResponse(respData);
            });
        }
      }

      if (e == options[1]) {
        axios
          .get(
            "/userDashboard/getSharedReportsForDashboard?userId=",
            userSessionAuthKeyReturnValue
          )
          .then((response) => {
            let respData = response.data;
            respData.map((i, idx) => {
              if (i.createdAtDateTime > i.modifiedAtDateTime) {
                return (i.modifiedAtDateTime = "");
              }
            });
            respData = respData.reverse();
            setDashboardAPIResponse(respData);
          });
      }
    }
  };

  const checkForSharedReports = () => {
    axios
      .get(
        "/userDashboard/getSharedReportsForDashboard?userId=",
        userSessionAuthKeyReturnValue
      )
      .then((response) => {
        let respData = response.data;
        if (respData.length > 0) {
          dashboardTabOnChange(TranslationFile.dashboardSharedBtn[userSelectedLanguage]);
        } else {
          dashboardTabOnChange(TranslationFile.dashboardMyReportsBtn[userSelectedLanguage]);
        }
      });
  };

  const dashboardMyReports = () => {
    return (
      <div className="dashboard-togglebtn">
        <SelectButton
          value={value}
          onChange={(e) => dashboardTabOnChange(e.value)}
          options={options}
          // className="my-reports-select-button"
          className="dashboardTab"
        />
      </div>
    );
  };

  const linkShareOnClick = (rowData) => {
    accRegNo = loginReducer && loginReducer.login.values.accountingCompanyRegNo;
    let localData = localStorage.getItem("rebelSkoolUser");
    let localObject = JSON.parse(localData);
    // ---------------------------------------------------------

    setSelectedRowData(rowData);
    setInputarr([]);
    let responseObj = {
      emailId: "",
      accessLevel: "",
    };
    let arr = [];
    let lastIdx = 0;

    if (
      !loginReducer.login.values.accountingCompany ||
      !localObject.accountingCompany
    ) {
      //Private User
      if (userSessionAuthKeyReturnValue) {
        axios
          .get(
            "/userDashboard/getMyDashboard?reportId=" + rowData.reportId,
            userSessionAuthKeyReturnValue
          )
          .then((response) => {
            let responseData = response.data;

            responseData &&
              responseData.length &&
              responseData.map((i, idx) => {
                lastIdx = idx;
                // let accessLevelObj = {
                //   name: i.accessLevel,
                //   code: i.accessLevel,
                // };
                let responseObj = {
                  emailId: i.emailId,
                  accessLevel: i.accessLevel,
                  reportId: rowData.reportId
                };

                let { emailId, accessLevel } = i;

                responseObj.emailId = i.emailId;
                responseObj.accessLevel = i.accessLevel;
                arr.push(responseObj);

                setInputarr((inputarr) => [...inputarr.filter((val) => val.emailId != ''), responseObj]);
              });

            setShareProductDialog(true);
          })
      }
    } else {
      //Accounting User
      if (userSessionAuthKeyReturnValue) {
        //The below API will return the accounting company details and the users/Admin under the accounting company
        axios
          .get(
            "/accountingCompany/getCompanyDetailsAndUsers?accountingCompanyId=" +
            accRegNo,
            userSessionAuthKeyReturnValue
          )
          .then((res) => {
            let resData = res.data.usersResponse;
            resData = resData.filter((val) => val.emailID != localObject.emailID && val.status != 'In Active')

            //This API will get you the access people of the report
            axios
              .get(
                "/userDashboard/getMyDashboard?reportId=" + rowData.reportId,
                userSessionAuthKeyReturnValue
              )
              .then((response) => {
                let responseData = response.data;
                //Step 1: Seperate the external & Internal users
                //In the internal Users, find the person who has accountingCompanyRegNo and accoutingCompanyID
                let internalUsers = responseData.filter((val) => val.accountingCompanyRegNo != null && val.accoutingCompanyID > 0 && val.accountingCompanyRegNo == accRegNo)
                //Merge the resData and internal Users
                //Need firstName, lastName, emailID from resData
                let userData = resData.map((val) => {
                  return {
                    firstName: val.firstName,
                    lastName: val.lastName,
                    emailId: val.emailID,
                  }
                })
                let mergedSet = userData.map(itemA => {
                  let matchingItemB = internalUsers.find(itemB => itemB.emailId === itemA.emailId);
                  return { ...itemA, ...matchingItemB };
                });
                mergedSet &&
                  mergedSet.length &&
                  mergedSet.map((i, idx) => {
                    lastIdx = idx;
                    // let accessLevelObj = {
                    //   name: i.accessLevel,
                    //   code: i.accessLevel,
                    // };
                    let responseObj = {
                      emailId: i.emailId,
                      accessLevel: i.accessLevel,
                      firstName: i.firstName,
                      lastName: i.lastName,
                      reportId: rowData.reportId
                    };

                    let { emailId, accessLevel } = i;

                    responseObj.emailId = i.emailId;
                    responseObj.accessLevel = i.accessLevel;
                    responseObj.firstName = i.firstName;
                    responseObj.lastName = i.lastName;
                    arr.push(responseObj);

                  });
                //Set the merged variable in companyMembers state
                setCompanyMembers(arr);

                //The accoutingCompanyID should be equal to the Report Owner, If not, the person will be considered as an external User
                //Set the external Users in inputarr state 
                let externalUsers = responseData.filter(val => !internalUsers.some(valObj => valObj.emailId == val.emailId))
                externalUsers &&
                  externalUsers.length &&
                  externalUsers.map((i, idx) => {
                    lastIdx = idx;
                    // let accessLevelObj = {
                    //   name: i.accessLevel,
                    //   code: i.accessLevel,
                    // };
                    let responseObj = {
                      emailId: i.emailId,
                      accessLevel: i.accessLevel,
                      reportId: rowData.reportId
                    };

                    let { emailId, accessLevel } = i;

                    responseObj.emailId = i.emailId;
                    responseObj.accessLevel = i.accessLevel;

                    // setInputarr((inputarr) => [...inputarr, responseObj]);
                setInputarr((inputarr) => [...inputarr.filter((val) => val.emailId != ''), responseObj]);

                  });
              })
          });
      }
    }

    setShareProductDialog(true);
  };

  const linkSharingBodyTemplate = (rowData) => {
    return (
      <div
        style={{ cursor: "pointer" }}
        onClick={() => linkShareOnClick(rowData)}
        className="linkshareTab"
      >
        {rowData.sharedToNoOfUsers > 0 ? (
          <a className="dashboard-link-share-pop-up">
            {rowData.sharedToNoOfUsers + " user"}
          </a>
        ) : (
          <Button icon="pi pi-share-alt"
          />
        )}
      </div>
    );
  };


  const companyNameBodyTemplate = (rowData) => {
    return (
      <a
        className="dashbaord-companyName"
        onClick={() => companyNameOnClick(rowData)}
      >
        {rowData.companyName}
      </a>
    );
  };

  const registrationBodyTemplate = (rowData) => {
    if (rowData.financialYear != null) {
      let financialYear1 = rowData.financialYear && rowData.financialYear.split(" ")
      return (
        <div>
          <span> {financialYear1[0]}</span>
          <span style={{ fontWeight: "600" }}> {financialYear1[1]}</span>
          <span> {financialYear1[2]}</span>

        </div>
      );
    }

  };


  const companyNameOnClick = (rowData) => {
    localStorage.setItem("navigateTo", "companyInformation");
    localStorage.setItem("navigateToGUID", rowData.reportGUID);
    localStorage.setItem("navigatedCompanyName", rowData.companyName);

    const annualReport = "/annualreport/";
    history.push(annualReport + rowData.reportGUID + "/companyInfo");
    window.location.reload(false);
  };

  const statusBodyTemplate = (rowData) => {
    let status = "Draft";
    if (rowData.reportStatus) {
      status = "Completed";
    }
    return (
      <Tag value={status} severity={getSeverity(status)}></Tag>);
  };

  const paymentStatusBody = (rowData) => {
    let status;
    if (rowData.paymentEntryNo > 0) {
      status = "Paid";

      return (
        <Tag value={status} severity={getSeverityOrg(rowData, status)}></Tag>
      );
    }
  };

  const statusBodyTemplateTEST = (rowData) => {
    return getStatusLabelTEST(rowData.accountingCompanySharing);
  };

  const getStatusLabelTEST = (status) => {
    switch (status) {
      case "Read Only":
        return "Read Only";

      case "Write":
        return "Write";

      case "-":
        return "-";
    }
  };

  const statusItemTemplate = (option) => {
    return <Tag value={option} severity={getSeverity(option)} />;
  };


  const PaymentstatusItemTemplate = (option) => {
    return <Tag value={option} severity={getSeverityOrg(option)} />;
  };

  const statusFilterTemplate = (options) => {
    return (
      <div className="TEST">
        <Dropdown
          value={options.value}
          options={statuses}
          onChange={(e) => options.filterCallback(e.value, options.index)}
          itemTemplate={statusItemTemplate}
          placeholder={TranslationFile.dashboardSelectOne[userSelectedLanguage]}
          className="dashboard-status-filter"
          showClear
          filter={false}
        />
      </div>
    );
  };


  const paymentFilterTemplate = (options) => {
    return (
      <div className="TEST">
        <Dropdown
          value={options.value}
          options={statuses1}
          onChange={(e) => options.filterCallback(e.value, options.index)}
          itemTemplate={PaymentstatusItemTemplate}
          placeholder={TranslationFile.dashboardSelectOne[userSelectedLanguage]}
          className="dashboard-status-filter"
          showClear
          filter={false}
        />
      </div>
    );
  };

  const navigateToPdf = (reportGUID) => {
    window.open(`#/annualreport/${reportGUID}/pdf`, "_blank");
  };

  const pdfBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          type="button"
          icon="pi pi-file-pdf"
          // className="p-button-warning mr-2"
          rounded outlined
          tooltip="PDF"
          className="dashboard-pdf-Id"
          tooltipOptions={{ position: "bottom" }}
          // onClick={() => navigateToPdf(rowData)}
          onClick={() => previewPdf(rowData.reportGUID)}
        />
      </React.Fragment>
    );
  };

  const showCopyLinkSuccess = () => {
    toast.current.show({
      severity: "info",
      summary: TranslationFile.SuccessText[userSelectedLanguage],
      detail: TranslationFile.Linkcopied[userSelectedLanguage],
      life: 5000,
    });
  };

  const deleteProductDialogFooter = (
    <React.Fragment>
      <Button
        label={TranslationFile.noBtn[userSelectedLanguage]}
        icon="pi pi-times"
        outlined
        onClick={hideDeleteProductDialog}
      />
      <Button
        label={TranslationFile.yesBtn[userSelectedLanguage]}
        icon="pi pi-check"
        severity="danger"
        onClick={deleteProduct}
      />
    </React.Fragment>
  );
  const deleteProductsDialogFooter = (
    <React.Fragment>
      <Button
        label={TranslationFile.noBtn[userSelectedLanguage]}
        icon="pi pi-times"
        outlined
        onClick={hideDeleteProductsDialog}
      />
      <Button
        label={TranslationFile.yesBtn[userSelectedLanguage]}
        icon="pi pi-check"
        severity="danger"
        onClick={deleteSelectedProducts}
      />
    </React.Fragment>
  );

  const shareProductDialogFooter = () => {
    const linkToCopy =
      config.annualReport.url +
      "/" +
      selectedRowData.reportGUID +
      "/companyInfo";

    return (
      <React.Fragment>
        <CopyToClipboard text={linkToCopy}>
          <Button
            label={TranslationFile.dashboardCopyLinkBtn[userSelectedLanguage]}
            onClick={showCopyLinkSuccess}
            outlined
            id="copy-link-button-id"
          />
        </CopyToClipboard>
      </React.Fragment>
    );
  };

  const [emailArray, setEmailArray] = useState([]);
  const sideBarStatus = (e) => {
    setSideBarOpenState(e);
  };

  const changeAccessForPeopleEmail = (e) => {
    setPeopleSelectedAccess(e.value);
  };

  const getUserShareEmail = (e) => {
    setShareEmail(e.target.value);
  };
  const changeAccessForEmail = (e) => {
    setSelectedAccess(e.value);
  };

  let localData = localStorage.getItem("rebelSkoolUser");
  let localObject = JSON.parse(localData);

  const addTableRows = () => {
    const rowsInput = {
      fullName: "",
      fullNameDp: shareAccess && shareAccess[2].name,
    };
    setInputFields([...inputFields, rowsInput]);
  };

  const addInputField = (e) => {
    if (
      inputFields[inputFields.length - 1].fullName !== "" &&
      userSessionAuthKeyReturnValue
    ) {
      let requestStr = {
        reportId: selectedRowData.reportId,
        createdByUserId: loginReducer && loginReducer.login.values.userID,
        userAccessLevel: inputFields[inputFields.length - 1].fullNameDp.name,
        emailId: inputFields[inputFields.length - 1].fullName,
        isInsert: true,
      };

      if (loginReducer && loginReducer.login.values.emailID != requestStr.emailId) {
        axios
          .post(
            "/annualReport/saveReportAccess",
            requestStr,
            userSessionAuthKeyReturnValue
          )
          .then((response) => {
            toast.current.show({
              severity: "success",
              summary: TranslationFile.SuccessfulText[userSelectedLanguage],
              detail: TranslationFile.ReportSharedSuces[userSelectedLanguage],
              life: 3000,
            });
            axios
              .get(
                "/userDashboard/getLinkSharedDetails",
                userSessionAuthKeyReturnValue
              )
              .then((response) => {
                let respData = response.data;
                respData.map((i, idx) => {
                  if (i.createdAtDateTime > i.modifiedAtDateTime) {
                    return (i.modifiedAtDateTime = "");
                  }
                });
                respData = respData.reverse();
                setDashboardAPIResponse(respData);
              });
          });
      } else {
        toast.current.show({
          severity: "error",
          summary: TranslationFile.errorText[userSelectedLanguage],
          detail: TranslationFile.CantShareReportText[userSelectedLanguage],
          life: 3000,
        });
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // setInputarr((inputarr) => [...inputarr.filter((val) => val.emailId != ''), responseObj]);

    setInputarr([...inputarr.filter((val) => val.emailId != ''), { emailId, accessLevel }]);
    userSessionAuthKeyReturnValue = setUserSessionAuthKey();
    // if(userSessionAuthKeyReturnValue) {
    let localData = localStorage.getItem("rebelSkoolUser");
    let localObject = JSON.parse(localData);

    if (localObject != null && localObject.emailID != null) {


      setFirstName(localObject.firstName);
      setLastName(localObject.lastName);
      setUserEmailId(localObject.emailID);
      setUserId(localObject.userID)

      // const config = {
      //   headers: {
      //     "User-Session-Auth-Key":
      //       userSessionAuthKeyReturnValue.headers[
      //       "User-Session-Auth-Key"
      //       ],
      //   },
      // };

      axios.post("/user/checkIfPasswordExists?emailId=" + localObject.emailID)
        .then((response) => {
          let respData = response.data;
          if (!respData) {
            setShowUserNamePasswordDialogBox(true)
          }

        })
      // }
    }

  }, []);

  useEffect(() => {
    let userTourGuide = JSON.parse(localStorage.getItem("TourGuide")) || false;
    let tourGuidePages = JSON.parse(localStorage.getItem("tourGuidePages")) || [];

    if (userTourGuide) {
      if (tourGuidePages.includes(currentPageTourGuideName)) {
        setTimeout(() => {
          setRun(false);
        }, 200);
      } else {
        setTimeout(() => {
          setRun(true);
        }, 200);
      }
    }

    // Update tourGuidePages in localStorage
    if (!tourGuidePages.includes(currentPageTourGuideName)) {
      tourGuidePages.push(currentPageTourGuideName);
      localStorage.setItem("tourGuidePages", JSON.stringify(tourGuidePages));
    }
  }, [currentPageTourGuideName]);

  const inputEmailIDOnBlur = (e) => {
    let localData = localStorage.getItem("rebelSkoolUser");
    let localObject = JSON.parse(localData);
    if (inputdata.emailId != "") {
      if (inputdata.emailId == localObject.emailID) {
        toast.current.show({
          severity: "warn",
          summary: "No Sharing Applicable",
          detail: "The Report can't be shared to the Report Creator's Mail",
          life: 4000,
        });
        setInputDate({ emailId: "",accessLevel:'' });

        setSharingDisabled(true)
      } else {
        setSharingDisabled(false)
        }

        if(inputdata.emailId !== localObject.emailID) {
          //Check this email id is already has access to the report
        //If it has show a toast message and clear the value in the input box
        console.log(inputarr,"!!!!!!!!!DASH")
        let sameEmailId = inputarr.filter((val) => val.emailId == inputdata.emailId)
        if(sameEmailId.length > 0) {
          toast.current.show({
            severity: "error",
            summary: "Report Already Shared",
            detail: "The Report is already being shared to the user",
            life: 4000,
          });
          setInputDate({ emailId: "",accessLevel:'' });

        setSharingDisabled(true)
        }
      }
        else {
          setSharingDisabled(false)
          }

        // 
      }
      //TODO Find Out whether the report has been shared with the emailID already
    
  };

  function changeHandle(e, field) {
    if (field == 'email') {
      setInputDate({ ...inputdata, [e.target.name]: e.target.value, });
    } else {
      setInputDate({ ...inputdata, [e.target.name]: e.target.value, });

    }


  }

  let { emailId, accessLevel } = inputdata;

  const addInputFieldNew = (e) => {
    if (inputdata.emailId != "" && userSessionAuthKeyReturnValue) {
      let req = {
        reportId: selectedRowData.reportId,
        createdByUserId: loginReducer && loginReducer.login.values.userID,
        userAccessLevel: inputdata.accessLevel.name,
        emailId: inputdata.emailId,
        isInsert: true,
      };

      if (!validateEmail(inputdata.emailId)) {
        isValidEmailIDCheck = false;
      } else {
        isValidEmailIDCheck = true;

        let objTemp = {
          emailId: inputdata.emailId,
          accessLevel: inputdata.accessLevel.name
        }
        setInputarr([...inputarr.filter((val) => val.emailId != ''), objTemp]);

        setInputDate({ emailId: "" });

        axios
          .post(
            "/annualReport/saveReportAccess",
            req,
            userSessionAuthKeyReturnValue
          )
          .then((response) => {
            if (response.data) {
              toast.current.show({
                severity: "success",
                summary: TranslationFile.SuccessfulText[userSelectedLanguage],
                detail: TranslationFile.ReportSharedSuces[userSelectedLanguage],
                life: 3000,
              });

              axios
                .get(
                  "/userDashboard/getLinkSharedDetails",
                  userSessionAuthKeyReturnValue
                )
                .then((response) => {
                  let respData = response.data;
                  respData &&
                    respData.length &&
                    respData.map((i, idx) => {
                      if (i.createdAtDateTime > i.modifiedAtDateTime) {
                        return (i.modifiedAtDateTime = "");
                      }
                    });
                  respData = respData.reverse();
                  setDashboardAPIResponse(respData);
                });

              axios
                .get(
                  "/user/getByEmail?emailId=" + req.emailId,
                  userSessionAuthKeyReturnValue
                )
                .then((userDetailsByEmailIdResp) => {
                  userDetailsByEmailIdRespData =
                    userDetailsByEmailIdResp.data;
                  if (userDetailsByEmailIdRespData.userID > 0) {
                    let sendMailObject = {
                      recipient: inputdata.emailId,
                      emailType: "reportShare",
                      userGUID: userDetailsByEmailIdRespData.userGUID,
                      reportGuid: selectedRowData.reportGUID,
                      noOfAttempts: 0,
                      status: "New",
                      userName: localObject.emailID,
                      message: linkSharedMessage,
                    };

                    const url =
                      "/emailQueueController/insertEmailQueueAndSendMail";
                    const formData = new FormData();
                    const objString = JSON.stringify(sendMailObject);

                    formData.append("request", objString);
                    const config = {
                      headers: {
                        timeZone: userTimeZone,
                        "User-Session-Auth-Key":
                          userSessionAuthKeyReturnValue.headers[
                          "User-Session-Auth-Key"
                          ],
                        language: userSelectedLanguage
                      },
                    };

                    axios.post(url, formData, config).then((response) => {
                      if (response.data) {
                      }
                    });
                  }
                });
            }
          });
      }
    }
  };

  const userPasswordUpdateButton = () => {
    if (firstName != '' && lastName != '' && userEmailId != '' && userPassword != '') {
      let userDetails = {
        userID: userId,
        userName: firstName,
        lastName: lastName,
        password: userPassword,
      };
      axios
        .post(
          "/user/updateUsernamePasswordForAccountingUsers",
          userDetails,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          if (response.data) {
            let emailVerifiedRequest = {
              emailID: userEmailId,
            };
            axios
              .post(
                "/user/getUserDetailsByEmailIdAsLoginResp",
                emailVerifiedRequest
              )
              .then((response) => {
                const userAuthKey = response.headers["user-session-auth-key"];
                response.data["User-Session-Auth-Key"] = userAuthKey;
                localStorage.setItem(
                  "rebelSkoolUser",
                  JSON.stringify(response.data)
                );
                setShowUserNamePasswordDialogBox(false)
              })
          }
        });
    }
  }
  const removeInputFields = (index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
  };

  const shareProductDialogFooterOverlap = (
    <React.Fragment>
      <Button
        label={TranslationFile.dashboardLinkShareBtn[userSelectedLanguage]}
        icon="pi pi-check"
        severity="success"
        onClick={addInputFieldNew}
      />
    </React.Fragment>
  );


  const accessLevelDialogBox = () => {
    setShareEmailDialogOverlap(false);
    inputdata.emailId = "";
    setInputDate({ emailId: "" });
  };

  const [shareAccessDropdown] = useState(["Read Only", "Write", "No Access"]);


  const accessEmailBodyTemplate = (rowData) => {
    if(rowData.emailId != '') {
      return(
        <div>
          {rowData.emailId}
        </div>
      )
    }
  }
  const accessBodyTemplate = (rowData) => {
    if(rowData.accessLevel != undefined) {
    return (
      <Tag
        style={{ textAlign: 'center' }}
        value={rowData["accessLevel"] != undefined ? rowData["accessLevel"] : 'NIL'}
        severity={getSeverityActive(rowData["accessLevel"])}
      ></Tag>
    );
  }
  };

  const onRowEditCompleteOutside = (e) => {
    let { newData, index } = e;
    let tempOutside = [];
    let requestStr = {
      reportId: newData.reportId,
      createdByUserId: loginReducer && loginReducer.login.values.userID,
      userAccessLevel: newData.accessLevel,
      emailId: newData.emailId,
      isUpdate: true,
    };

    axios
      .post(
        "/annualReport/saveReportAccess",
        requestStr,
        userSessionAuthKeyReturnValue
      )
      .then((response) => {

        if (response.data) {
          toast.current.show({
            severity: "success",
            summary: TranslationFile.SuccessfulText[userSelectedLanguage],
            detail: TranslationFile.ReportSharedSuces[userSelectedLanguage],
            life: 3000,
          });

          tempOutside = inputarr
          tempOutside && tempOutside.map((val) => {

            if (newData.emailId == val.emailId) {
              return val.accessLevel = newData.accessLevel
            }
          })
          tempOutside.length > 0 && tempOutside.map((val) => {
            setInputarr((inputarr) => [...inputarr.filter((val) => val.emailId != '')])
          })

          //Send Email
          axios
            .get(
              "/user/getByEmail?emailId=" + newData.emailId,
              userSessionAuthKeyReturnValue
            )
            .then((response) => {
              let userData = response.data;
              let sendMailObject = {
                recipient: newData.emailId,
                emailType: "reportShare",
                userGUID: userData.userGUID,
                reportGuid: selectedRowData.reportGUID,
                noOfAttempts: 0,
                status: "New",
                userName: localObject.emailID,
                message: '',
              };

              const url = "/emailQueueController/insertEmailQueueAndSendMail";
              const formData = new FormData();
              const objString = JSON.stringify(sendMailObject);

              formData.append("request", objString);
              const config = {
                headers: {
                  timeZone: userTimeZone,
                  "User-Session-Auth-Key":
                    userSessionAuthKeyReturnValue.headers[
                    "User-Session-Auth-Key"
                    ],
                  language: userSelectedLanguage
                },
              };

              axios.post(url, formData, config).then((response) => {
                if (response.data) {

                }
              });
            })
        }
      })
    setReRender(true)


  }
  const onRowEditComplete = (e) => {

    let { newData, index } = e;
    let temp = [];

    let requestStr = {
      reportId: newData.reportId,
      createdByUserId: loginReducer && loginReducer.login.values.userID,
      userAccessLevel: newData.accessLevel,
      emailId: newData.emailId,
      isUpdate: true,
    };

    axios
      .post(
        "/annualReport/saveReportAccess",
        requestStr,
        userSessionAuthKeyReturnValue
      )
      .then((response) => {

        if (response.data) {
          toast.current.show({
            severity: "success",
            summary: TranslationFile.SuccessfulText[userSelectedLanguage],
            detail: TranslationFile.ReportSharedSuces[userSelectedLanguage],
            life: 3000,
          });
          temp = companyMembers
          temp.map((val) => {
            if (newData.emailId == val.emailId) {
              return val.accessLevel = newData.accessLevel
            }
          })
          temp && temp.map((val) => {
            setCompanyMembers((companyMembers) => [...companyMembers])
          })

          //Send Email
          axios
            .get(
              "/user/getByEmail?emailId=" + newData.emailId,
              userSessionAuthKeyReturnValue
            )
            .then((response) => {
              let userData = response.data;
              let sendMailObject = {
                recipient: newData.emailId,
                emailType: "reportShare",
                userGUID: userData.userGUID,
                reportGuid: selectedRowData.reportGUID,
                noOfAttempts: 0,
                status: "New",
                userName: localObject.emailID,
                message: '',
              };

              const url = "/emailQueueController/insertEmailQueueAndSendMail";
              const formData = new FormData();
              const objString = JSON.stringify(sendMailObject);

              formData.append("request", objString);
              const config = {
                headers: {
                  timeZone: userTimeZone,
                  "User-Session-Auth-Key":
                    userSessionAuthKeyReturnValue.headers[
                    "User-Session-Auth-Key"
                    ],
                  language: userSelectedLanguage
                },
              };

              axios.post(url, formData, config).then((response) => {
                if (response.data) {

                }
              });
            })
        }
      })
    setReRender(true)
  }

  const accessEditor = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={shareAccessDropdown}
        onChange={(e) => options.editorCallback(e.value)}
        placeholder={options.value}
      />
    );
  };


  const callback = (annualReport, session, link, status) => {
    let sessionUUIDFromLS = localStorage.getItem("sessionUUID");
    if (status) {
      history.push(annualReport + sessionUUIDFromLS + link);
    }
  };

  const navigateToNewReport = () => {
    localStorage.setItem("navigateTo", "companyInformation");
    history.push("/annualreport");
    window.location.reload(false);
  };

  const openTheLoginSidebar = () => {
    document.getElementsByClassName("user-btn")[0].click();
  };

  const emptyMessageBodyTemplate = () => {
    if (
      localObject != undefined &&
      localObject.emailID != undefined &&
      localObject.userName != undefined
    ) {
      if (value == options[0]) {
        return (
          <div>
            {TranslationFile.dashboardNoReport[userSelectedLanguage]} <br></br>
            {TranslationFile.dashboardCreateNewReport[userSelectedLanguage]}
            <button
              onClick={() => navigateToNewReport()}
              id="new-report-dashboard"
              className="new-annual-report-create-button"
            >
              {TranslationFile.sidebarNewReport[userSelectedLanguage]}
            </button>
          </div>
        );
      } else {
        return <div>{TranslationFile.dashboardNoSharedReport[userSelectedLanguage]}</div>;
      }
    } else {
      return (
        <div>
          {value == options[0] ? (
            <div>{TranslationFile.dashboardLoginInToViewReports[userSelectedLanguage]}</div>
          ) : (
            <div>
              {TranslationFile.dashboardLoginInToViewSharedReports[userSelectedLanguage]}
            </div>
          )}
          <br></br>
          {TranslationFile.dashboardLoginBtn[userSelectedLanguage]}
          <button
            onClick={() => openTheLoginSidebar()}
            id="new-report-dashboard"
            className="new-annual-report-create-button"
          >
            {TranslationFile.loginText[userSelectedLanguage]}
          </button>
        </div>
      );
    }
  };


  const sideBarCallback = () => {
    // StatusALert("signUp")
  };


  const tourStatus = (e) => {
    setRun(e)
  }


  return (
    <div>
      <Joyride
        continuous
        run={run}
        disableBeacon={true}
        showProgress={false}
        showSkipButton
        steps={steps}
        scrollToFirstStep
        scrollToSteps
        scrollOffset={100}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />

      <Toast ref={toast} position="top-right" className="toast-top" />
      <NavBarHeader isAppPortal={true} isHomePage={false} callBack={callback} />
      <div className="dashboard_sidebar_div">
        <Sidebar
          children="MyDashboard"
          sideBarStatus={sideBarStatus}
          sideBarCallback={() => sideBarCallback()}
          tourStatus={tourStatus}
        />
      </div>
      <div
        className={
          sideBarOpenState
            ? "myDashboardGridSideBarOpen"
            : "myDashboardGridSideBarClose"
        }
      >
        <div className="dashboardGridMainDiv">
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            center={header}
            right={dashboardMyReports}
          ></Toolbar>

          <DataTable
            ref={dt}
            value={dashboardAPIResponse}
            editMode="cell"
            selection={selectedProducts}
            onSelectionChange={(e) => setSelectedProducts(e.value)}
            selectionMode="checkbox"
            dataKey="id"
            paginator
            rows={10}
            emptyMessage={emptyMessageBodyTemplate}
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            rowsPerPageOptions={[5, 10, 25, 50]}
            globalFilter={globalFilter}
            header={header}
            selectionPageOnly
            className="dashboard-datatable1"
            style={{ padding: "0rem" }}
          >
            <Column
              selectionMode="multiple"
              exportable={false}
              className="dashboard-checkbox"
            // style={{ padding: "0rem" }}
            ></Column>

            <Column
              field="companyName"
              header={
                TranslationFile.dashboardCompanyName[userSelectedLanguage]
              }
              filter
              className="dashboard-company-name"
              body={companyNameBodyTemplate}
              style={{ padding: "0rem", width: "15%" }}
            ></Column>

            <Column
              field="registrationNo"
              header={TranslationFile.dashboardOrgNumber[userSelectedLanguage]}
              className="dashboard-org-no"
              filter
              style={{ padding: "0rem", width: "9%" }}
            ></Column>
            <Column
              field="financialYear"
              body={registrationBodyTemplate}
              header={TranslationFile.dashboardFinYear[userSelectedLanguage]}
              className="dashboard-financial-year"
              filter
              style={{ padding: "0rem", width: "14%" }}
            ></Column>

            {value == TranslationFile.dashboardMyReportsBtn[userSelectedLanguage] && (
              <Column
                field="linkSharing"
                header={
                  TranslationFile.dashboardLinkShare[userSelectedLanguage]
                }
                className="dashboard-link-sharing"
                filter
                filterPlaceholder={TranslationFile.dashboardSearchBtn[userSelectedLanguage]}
                body={linkSharingBodyTemplate}
                style={{ padding: "0rem" }}
              ></Column>
            )}

            <Column
              field="createdAtDateTime"
              header={TranslationFile.dashboardCreatedOn[userSelectedLanguage]}
              filter
              className="dashboard-created-on"
              style={{ padding: "0rem" }}
            ></Column>

            <Column
              field="createdBy"
              header={TranslationFile.dashboardCreatedBy[userSelectedLanguage]}
              className="dashboard-created-by"
              filter
              style={{ padding: "0rem" }}
            ></Column>

            {value == options[0] && (
              <Column
                // field="createdBy"
                header={TranslationFile.dashboardPaymentStatus[userSelectedLanguage]}
                className="dashboard-created-by"
                body={paymentStatusBody}
                filter
                filterElement={paymentFilterTemplate}
                style={{ padding: "0rem" }}
              ></Column>
            )}

            <Column
              field="modifiedAtDateTime"
              header={TranslationFile.dashboardModifiedOn[userSelectedLanguage]}
              filter
              className="dashboard-modified-on"
              style={{ padding: "0rem" }}
            ></Column>

            <Column
              field="modifiedBy"
              header={TranslationFile.dashboardModifiedBy[userSelectedLanguage]}
              className="dashboard-modified-by"
              filter
              style={{ padding: "0rem" }}
            ></Column>

            {value == options[1] && (
              <Column
                header={TranslationFile.dashboardAccess[userSelectedLanguage]}
                field="accessLevel"
                // filter
                body={accessBodyTemplate}
                className="dashboard-Access"
                style={{ padding: "0rem" }}
              ></Column>
            )}

            <Column
              field="reportStatus"
              header={TranslationFile.dashboardStatusBtn[userSelectedLanguage]}
              body={statusBodyTemplate}
              filter
              filterElement={statusFilterTemplate}
              className="dashboard-status"
              style={{ padding: "0rem" }}
            ></Column>

            <Column
              body={pdfBodyTemplate}
              exportable={false}
              className="dashboard-pdf"
              header="PDF"
              title="Download PDF"
              style={{ padding: "0rem" }}
            ></Column>
          </DataTable>
        </div>
        {/* )} */}
      </div>

      <Dialog
        visible={deleteProductDialog}
        blockScroll
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header={TranslationFile.dashboardConfirmBtn[userSelectedLanguage]}
        modal
        footer={deleteProductDialogFooter}
        onHide={hideDeleteProductDialog}
      >
        <div className="confirmation-content">
          <span>
            <span>
              {" "}
              {TranslationFile.dashboardDeleteText[userSelectedLanguage]}
            </span>
          </span>
        </div>
      </Dialog>

      <Dialog
        blockScroll
        visible={deleteProductsDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header={TranslationFile.dashboardConfirmBtn[userSelectedLanguage]}
        modal
        footer={deleteProductsDialogFooter}
        onHide={hideDeleteProductsDialog}
      >
        <div className="confirmation-content">
          <div>
            <span>
              {TranslationFile.dashboardDeleteText[userSelectedLanguage]}
            </span>
          </div>
        </div>
      </Dialog>

      <Dialog
        // header={TranslationFile.dashboardLinkShareHeaderText[userSelectedLanguage]}
        blockScroll
        visible={shareProductDialog}
        style={{
          width: "60vw",
          height: "36vw",
        }}
        footer={shareProductDialogFooter}
        onHide={() => hideShareProductDialog()}
      >
        <TabView
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
          className="Annual_reportBox1"
        >
          {isAccCompany && (
            <TabPanel header={TranslationFile.Inside_My_Organization_Text[userSelectedLanguage]} className="">
              <div>
                {companyMembers && companyMembers.length > 0 ? (
                  <div className="reportShareDetails">
                    <div className="share_people_box">
                      <Toolbar
                        // className="admin-toolbar"
                        left={leftToolbarTemplatebox2}
                      ></Toolbar>
                      <DataTable
                        editMode="row"
                        paginator
                        rows={10}
                        emptyMessage="No Users Added"
                        onRowEditComplete={onRowEditComplete}
                        value={companyMembers}
                      >
                        <Column
                          header={TranslationFile.LoginFirstName[userSelectedLanguage]}
                          field="firstName"
                          showFilterMatchModes={false}
                          disabled
                        >
                        </Column>
                        <Column
                          header={TranslationFile.LoginLastName[userSelectedLanguage]}
                          field="lastName"
                          showFilterMatchModes={false}
                          disabled

                        >
                        </Column>

                        <Column
                          header={TranslationFile.EmailIdText[userSelectedLanguage]}
                          field="emailId"
                          showFilterMatchModes={false}
                          disabled
                        ></Column>

                        <Column
                          header={TranslationFile.dashboardAccess[userSelectedLanguage]}
                          field="accessLevel"
                          showFilterMatchModes={false}
                          disabled
                          body={accessBodyTemplate}
                          editor={(options) => accessEditor(options)}
                        ></Column>
                        <Column
                          rowEditor
                        ></Column>

                      </DataTable>
                    </div>
                  </div>
                ) : (
                  <div className="dash-inside-organization">
                    {/* No people in your Organization */}
                    {TranslationFile.No_People_Org_Text[userSelectedLanguage]}
                  </div>
                )}
              </div>
            </TabPanel>
          )}

          <TabPanel
            header={isAccCompany ? TranslationFile.Outside_My_Organization_Text[userSelectedLanguage] : TranslationFile.Report_Sharing_Text[userSelectedLanguage]} >
            <div className="mydashboard_div1"
              style={{ display: "grid", gridTemplateColumns: "repeat(2,1fr)" }}
            >
              <Card title={TranslationFile.dashboardLinkShareHeaderText[userSelectedLanguage]} className="report-sharing-card">
                <div className="share_mail_box">
                  <Row className="share-overlap-row">
                    <Col className="link-email-input-box">
                      <InputText
                        type="text"
                        name="emailId"
                        value={inputdata.emailId}
                        onChange={(e) => changeHandle(e, 'email')}
                        onBlur={inputEmailIDOnBlur}
                        placeholder={
                          TranslationFile.dashboardEnterEmailId[
                          userSelectedLanguage
                          ]
                        }
                        className="emai-id-overlap"
                      />
                    </Col>
                    <Col className="link-access-dropdown">
                      <Dropdown
                        value={inputdata.accessLevel}
                        options={shareAccess}
                        optionLabel="label"
                        name="accessLevel"
                        className="mail-give-access-dropdown"
                        onChange={(e) => changeHandle(e, 'access')}

                        dropdownIcon={
                          <RiArrowDownSFill className="drop-down-black-fill-icon" />
                        }
                      />
                    </Col>
                  </Row>
                  <br></br>

                  <div className="share-message">
                    {" "}
                    {
                      TranslationFile.dashboardLinkShareMessage[
                      userSelectedLanguage
                      ]
                    }
                  </div>
                  <InputTextarea
                    rows={4}
                    cols={30}
                    onChange={(e) => setLinkSharedMessage(e.target.value)}
                    className="share-annual-text-area"
                  />
                  <center className="dashboard-share-btn">
                    <Button
                      label={TranslationFile.dashboardLinkShareBtn[userSelectedLanguage]}
                      severity="success"
                      icon="pi pi-check"
                      onClick={addInputFieldNew}
                      disabled={sharingDisabled}
                    />
                  </center>
                </div>
              </Card>
              <Card
                title={TranslationFile.dashboardLinkSharePeopleAccesText[userSelectedLanguage]}
                className="dashboard-linksharing-card"
              >
                <div className="people-access-card">
                  <DataTable
                    paginator
                    editMode="row"
                    rows={10}
                    value={inputarr}
                    emptyMessage="No Users Added"
                    onRowEditComplete={onRowEditCompleteOutside}
                  >
                    <Column
                      header={TranslationFile.EmailIdText[userSelectedLanguage]}
                      // field="emailId"
                      body={accessEmailBodyTemplate}
                      showFilterMatchModes={false}
                    ></Column>
                    <Column
                      header={TranslationFile.dashboardAccess[userSelectedLanguage]}
                      field="accessLevel"
                      showFilterMatchModes={false}
                      body={accessBodyTemplate}
                      editor={(options) => accessEditor(options)}
                    ></Column>

                    <Column
                      rowEditor
                    ></Column>

                  </DataTable>
                </div>
              </Card>
            </div>
          </TabPanel>
        </TabView>
      </Dialog>

      <Dialog
        header={TranslationFile.setUserNamePassText[userSelectedLanguage]}
        blockScroll
        visible={showUserNamePasswordDialogBox}
        onHide={() => setShowUserNamePasswordDialogBox(false)}
        style={{ width: "30vw" }}
        className="newUserDialogBox"
        modal={true}
        closable={false}
      >
        <div>
          <div style={{ padding: "20px 30px" }}>
            <div>
              <div style={{ textAlign: "left" }}>
                {TranslationFile.LoginFirstName[userSelectedLanguage]}
              </div>
              <InputText
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
                placeholder={TranslationFile.LoginFirstName[userSelectedLanguage]}
              />
            </div>
            <br></br>
            <div>
              <div style={{ textAlign: "left" }}>
                {TranslationFile.LoginLastName[userSelectedLanguage]}
              </div>
              <InputText
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
                placeholder={TranslationFile.LoginLastName[userSelectedLanguage]}
              />
            </div>
            <br></br>
            <div>
              <div style={{ textAlign: "left" }}>
                {TranslationFile.FooterEmailLabel[userSelectedLanguage]}
              </div>
              <InputText value={userEmailId} placeholder={TranslationFile.EmailIdText[userSelectedLanguage]} disabled />
            </div>
            <br></br>
            <div>
              <div style={{ textAlign: "left" }}>
                {TranslationFile.IncomestateYearPassword[userSelectedLanguage]}
              </div>
              <div className="p-inputgroup">
                {/* Password Input */}
                <input
                  type={isMasked ? "password" : "text"}
                  value={userPassword}
                  required
                  onChange={(e) => {
                    setUserPassword(e.target.value);
                  }}
                  placeholder={TranslationFile.IncomestateYearPassword[userSelectedLanguage]}
                  className="p-inputtext p-component"
                />

                {/* Toggle Icon */}
                <span
                  className="p-inputgroup-addon"
                  onClick={toggleMask}
                  style={{ cursor: "pointer" }}
                >
                  <i
                    className={isMasked ? "pi pi-eye-slash" : "pi pi-eye"}
                    style={{ fontSize: "1.2rem" }}
                  ></i>
                </span>
              </div>

              {/* <Password
                value={userPassword}
                onChange={(e) => {
                  setUserPassword(e.target.value);
                }}
                feedback={false}
                toggleMask={true}
                required
                placeholder={TranslationFile.IncomestateYearPassword[userSelectedLanguage]}
              /> */}
            </div>
            <br></br>

            <div>
              <div style={{ textAlign: "left" }}>
                {
                  TranslationFile.IncomestateYearConPassword[
                  userSelectedLanguage
                  ]
                }
              </div>

              <div className="p-inputgroup">
                <input
                  type={isMasked ? "password" : "text"}
                  value={userConfirmPassword}
                  required
                  onChange={(e) => {
                    setUserConfirmPassword(e.target.value);
                  }}
                  placeholder={TranslationFile.IncomestateYearConPassword[userSelectedLanguage]}
                  className="p-inputtext p-component"
                />

                {/* Toggle Icon */}
                <span
                  className="p-inputgroup-addon"
                  onClick={toggleMask}
                  style={{ cursor: "pointer" }}
                >
                  <i
                    className={isMasked ? "pi pi-eye-slash" : "pi pi-eye"}
                    style={{ fontSize: "1.2rem" }}
                  ></i>
                </span>
              </div>
              {/* 
              <Password
                value={userConfirmPassword}
                onChange={(e) => {
                  setUserConfirmPassword(e.target.value);
                }}
                feedback={false}
                toggleMask={true}
                required
                placeholder={TranslationFile.IncomestateYearConPassword[userSelectedLanguage]}
              /> */}
            </div>
          </div>
          <br></br>
          <center>
            <Button
              icon="pi pi-check"
              onClick={() => userPasswordUpdateButton()}
            >
              {TranslationFile.IncomestateYearsave[userSelectedLanguage]}
            </Button>
          </center>
        </div>
      </Dialog>
      <ScrolltoTop />
      <Footer_contact_tab isCorpPortal={false} isHomePage={true} />
    </div>
  );
}
