import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { SelectButton } from "primereact/selectbutton";
import Sidebar from "../Sidebar/Sidebar";
import { Password } from "primereact/password";
import "./MyProfile.css";
import axios from "axios";
import { Divider } from "primereact/divider";
import { Row, Col } from "react-bootstrap";
import { Accordion, AccordionTab } from "primereact/accordion";
import { useHistory } from "react-router-dom";
import moment from "moment";
import usePasswordValidator from "../Login/usePasswordValidator";
import { useMountEffect } from "primereact/hooks";
import { setUserSessionAuthKey } from "../../Component/commonUtils";
import NavBarHeader from "../NavBarHeader/NavBarHeader";
import Footer_contact_tab from "../Footerpage/Footer_contact_tab";
import config from "../../Configuration/Config";
import TranslationFile from "../15-Translation/locale/Translation_apps.json";
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';

let userDetailsReducerValue = "";
let currentAppNameFromUrl;
let userTourGuide = JSON.parse(localStorage.getItem("TourGuide")) || false;
let userSelectedLanguage = localStorage.getItem("selectedLanguage");

let steps = [
  {
    content: TranslationFile.TourGuide_Profile_Edit_Btn[userSelectedLanguage],
    placement: 'top',
    styles: {
      options: {
        width: 300,
      },
    },
    target: '.header-edit',
    title: 'Edit',
    disableBeacon: true,
    delay: 500,
  },
  {
    content: TranslationFile.TourGuide_Active_InActive_Btn[userSelectedLanguage],
    placement: 'top',
    styles: {
      options: {
        width: 300,
      },
    },
    target: '.dashboard-togglebtn',
    title: 'Active & In-Active Tab',
    disableBeacon: true,
    delay: 500,
  }
];



export default function MyProfile() {
  userSelectedLanguage = localStorage.getItem("selectedLanguage");
  const history = useHistory();
  const [userName, setUserName] = useState(null);
  const [userLastName, setUserLastName] = useState(null);
  const [emailID, setEmailID] = useState(null);
  const [updateEmailID, setUpdateEmailID] = useState(null);
  const [contactNo, setContactNo] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  // const [newPassword, setNewPassword] = useState(null);
  const [role, setRole] = useState(null);
  const [activeIndex, setActiveIndex] = useState([0, 1]);
  const [toggleOpen, setToggleOpen] = useState(false);
  const [userInfoDialog, setUserInfoDialog] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [userID, setUserID] = useState(null);
  const [emailIdFocus, setEmailIdFocus] = useState(false);
  const [dashboardAPIResponse, setDashboardAPIResponse] = useState("");
  const [emailCheck, setEmailCheck] = useState(false);
  const [validOldPassword, setValidOldPassword] = useState(false);
  const [inValidOldPassword, setInValidOldPassword] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState(true);
  const toast = useRef(null);
  const [run, setRun] = useState(false);
  const dt = useRef(null);
  const loginReducer = useSelector((state) => state.login);
  const paymentReducer = useSelector((state) => state.payment.payment);
  const [packageAPIResponse, setPackageAPIResponse] = useState("");
  const [showReport, setShowReport] = useState("");
  const [actualUsageVisible, setActualUsageVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [reRender, setReRender] = useState(true);
  const msgs = useRef(null);
  const [sideBarOpenState, setSideBarOpenState] = useState(true);
  const currentPageTourGuideName = "MyProfile";
  const [isMasked, setIsMasked] = useState(true);

  let userSessionAuthKeyReturnValue = setUserSessionAuthKey();

  // Edit Profile State - START
  const [oldPassword, setOldPassword] = useState(null);
  const [oldPasswordCheck, setOldPasswordCheck] = useState(false);
  const [passwordMismatch, setPasswordMismatch] = useState("");
  const [newConfirmPassword, setNewConfirmPassword] = useState(null);
  const [newPassword, setNewPassword, passwordError] = usePasswordValidator({
    min: 8,
  });
  const [passwordFocus, setPasswordFocus] = useState(false);
  const [passwordDisable, setPasswordDisable] = useState(true);
  const [editFirstName, setEditFirstName] = useState(null);
  const [editSecondName, setEditSecondName] = useState(null);
  const [editcontactNo, setEditContactNo] = useState(null);
  // Edit Profile State - END

  useEffect(() => {
    window.scrollTo(0, 0);
    userDetailsReducerValue = loginReducer["login"].values;
    let localData = localStorage.getItem("rebelSkoolUser");
    let localObject = localData && JSON.parse(localData);
    if (userDetailsReducerValue == undefined) userDetailsReducerValue = localObject;
    userSessionAuthKeyReturnValue = setUserSessionAuthKey();
    if (userSessionAuthKeyReturnValue && userDetailsReducerValue.emailID) {
      axios
        .get(
          "/user/getByEmail?emailId=" + userDetailsReducerValue.emailID,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          setUserName(response.data.userName);
          setUserLastName(response.data.lastName);
          setEmailID(response.data.emailID);
          setContactNo(response.data.mobileNo);
          if (response.data.accountingCompanyID > 0) {
            setRole(response.data.accountingCompanyUserRole);
          }
          // else {
          //   setRole("Admin");
          // }
        });
    }
  }, []);

  useMountEffect(() => {
    if (msgs.current) {
      msgs.current.clear();
      msgs.current.show({
        sticky: true,
        severity: "success",
        detail: "Payment successfully done!",
      });
    }
  });

  const hideDialog = () => {
    setSubmitted(false);
    setUserInfoDialog(false);

    setOldPassword("");
    setNewPassword("");
    setNewConfirmPassword("");
  };
  const saveProduct = () => {
    setSubmitted(true);
    if (editFirstName != "" && emailID != "") {
      let updateUserInfo = {
        userName: editFirstName,
        lastName: editSecondName,
        emailID: emailID,
        mobileNo: editcontactNo,
        accountingCompanyUserRole: role,
        oldPassword: oldPassword,
        newPassword: newPassword,
        reEnterNewPassword: newConfirmPassword,
      };
      axios
        .post("/user/save", updateUserInfo, userSessionAuthKeyReturnValue)
        .then((response) => {


          if (response.data == "Success") {
            toast.current.show({
              severity: "success",
              summary: "Updated",
              detail: "User Details Successfully Updated",
              life: 4000,
            });

            let localData = localStorage.getItem("rebelSkoolUser");
            let localObject = localData && JSON.parse(localData);
            if (null != localObject) {
              localObject["userName"] = editFirstName;
              localStorage.setItem(
                "rebelSkoolUser",
                JSON.stringify(localObject)
              );
            }


            axios
              .get(
                "/user/getByEmail?emailId=" + userDetailsReducerValue.emailID,
                userSessionAuthKeyReturnValue
              )
              .then((response) => {
                setUserName(response.data.userName);
                setUserLastName(response.data.lastName);
                setEmailID(response.data.emailID);
                setContactNo(response.data.mobileNo);
                if (response.data.accountingCompanyID > 0) {
                  setRole(response.data.accountingCompanyUserRole);
                } else {
                  setRole("Admin");
                }
              });

            window.location.reload(false);
          }
        });
      userDetailsReducerValue = updateUserInfo;
    }
    setUserInfoDialog(false);

    setOldPassword("");
    setNewPassword("");
    setNewConfirmPassword("");
  };

  const productDialogFooter = (
    <React.Fragment>
      <Button label={TranslationFile.repCancelbtn[userSelectedLanguage]} icon="pi pi-times" outlined onClick={hideDialog} />
      <Button label={TranslationFile.repSavebtn[userSelectedLanguage]} icon="pi pi-check" onClick={saveProduct} />
    </React.Fragment>
  );

  const editUserInfo = (product) => {
    // setProduct({ ...product });
    setUserInfoDialog(true);
    setEditFirstName(userName);
    setEditSecondName(userLastName);
    setEditContactNo(contactNo);
  };

  const companyNameBodyTemplate = (rowData) => {
    return (
      <a
        className="dashbaord-companyName"
        onClick={() => comapnyNameOnClick(rowData)}
      >
        {rowData.companyName}
      </a>
    );
  };

  const comapnyNameOnClick = (rowData) => {
    localStorage.setItem("navigateTo", "companyInformation");
    localStorage.setItem("navigateToGUID", rowData.reportGUID);

    history.push("/");
    window.location.reload(false);
  };

  const showReportDialog = (rowData) => {
    if (userSessionAuthKeyReturnValue) {
      setActualUsageVisible(true);
      axios
        .get(
          "/annualReportPackage/getByPaymentEntryNo?packagePaymentNo=" +
          rowData.paymentEntryNo,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          let resData = response.data;
          resData.map((i) => {
            let epochValue = i.createdAtDateTime;
            let createdDateValueFormat =
              moment(epochValue).format("YYYY-MM-DD");
            return (i.createdAtDateTime = createdDateValueFormat);
          });

          setShowReport(resData);
        });
    }
  };

  const actualUsageBodyTemplate = (rowData) => {
    return (
      <div onClick={() => showReportDialog(rowData)}>
        {rowData.actualUsage > 0 ? (
          <a className="MyProfile-companyName" >
            {rowData.actualUsage}
          </a>
        ) : (
          <div>{rowData.actualUsage}</div>
        )}
      </div>
    );
  };

  // const options = ["Active", "In-Active"];
  const options = [TranslationFile.ActiveText[userSelectedLanguage],
  TranslationFile.InActiveText[userSelectedLanguage]];

  const [value, setValue] = useState(options[0]);

  const myPackageTabOnChange = (value) => {
    if (userSessionAuthKeyReturnValue) {
      if (null == value) {
        setValue(value);
      } else {
        setValue(value);
      }
      if (value == options[0]) {
        axios
          .get(
            "/annualReportPackage/getPaymentLines",
            userSessionAuthKeyReturnValue
          )
          .then((response) => {
            let respData = response.data;
            if (respData.length > 0) {
              respData.map((i) => {
                return (
                  (i.startingDate = i.startingDate.slice(0, 10)),
                  (i.validUntil = i.validUntil.slice(0, 10))
                );
              });
              respData.map((i, idx) => {
                if (i.startingDate > i.validUntil) {
                  return (i.validUntil = "Unlimited");
                }
              });

              let activePackage = respData && respData.filter(
                (item) => item.status == true
              );
              setPackageAPIResponse(activePackage);
            } else {
              setLoading(false);
            }
          });
      }

      if (value == options[1]) {
        axios
          .get(
            "/annualReportPackage/getPaymentLines",
            userSessionAuthKeyReturnValue
          )
          .then((response) => {
            let respData = response.data;
            if (respData.length > 0) {
              respData.map((i) => {
                return (
                  (i.startingDate = i.startingDate.slice(0, 10)),
                  (i.validUntil = i.validUntil.slice(0, 10))
                );
              });
              respData.map((i, idx) => {
                if (i.startingDate > i.validUntil) {
                  return (i.validUntil = "Unlimited");
                }
              });

              let nonActivePackage = respData && respData.filter(
                (item) => item.status !== true
              );
              setPackageAPIResponse(nonActivePackage);
            } else {
              setLoading(false);
            }
          });
      }
    }
  };
  const header = () => {
    return (
      <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder={TranslationFile.dashboardSearchBtn[userSelectedLanguage]}
            className="dashboard-search-bar"
          />
        </span>
      </div>
    );
  };

  const dashboardMyReports = () => {
    return (
      <div className="dashboard-togglebtn">
        <SelectButton
          value={value}
          onChange={(e) => myPackageTabOnChange(e.value)}
          options={options}
          className="mypackages_invoice_button"
        />
      </div>
    );
  };

  const toggleMask = () => {
    setIsMasked((prev) => !prev);
  };

  const handleDownloadInvoice = async (invoiceNo) => {
    let localData = localStorage.getItem("rebelSkoolUser");
    let localObject = localData && JSON.parse(localData);
    if (null != localObject) {
      let userSessionAuthKey = localObject["User-Session-Auth-Key"];
      if (userSessionAuthKey != "" && userSessionAuthKey != null) {
        try {
          const response = await fetch(
            config.backend.url +
            "annualReport/downloadInvoicePDF?invoiceNo=" +
            invoiceNo,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/pdf",
                "User-Session-Auth-Key": localObject["User-Session-Auth-Key"],
              },
            }
          );

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);

          const link = document.createElement("a");
          link.href = url;
          link.download = "RebelSkool_KVitto" + invoiceNo + ".pdf";
          document.body.appendChild(link);
          link.click();

          // Clean up and remove the link
          link.parentNode.removeChild(link);
          window.URL.revokeObjectURL(url);
        } catch (error) {
          toast.current.show({
            severity: "error",
            summary: "Error in Download",
            detail: "Try after some time",
            life: 4000,
          });
        }
      }
    }
  };

  const invoiceBodyTemplate = (rowData) => {
    return (
      <div style={{ display: "grid", gridTemplateColumns: "repeat(2,1fr)" }}>
        <Button
          className="dashboard-receipt-bill"
          icon="pi pi-download"
          roundedoutlined
          tooltip="Download Invoice"
          tooltipOptions={{ position: "bottom" }}
          onClick={() => handleDownloadInvoice(rowData.invoiceNo)}
        />
        {/* <span className="myprofile_invoice_nametag"> {rowData.invoiceNo}</span> */}
      </div>
    );
  };

  const remainingUsage = (rowData) => {
    return (
      <div>
        {rowData.maxUsage - rowData.actualUsage}
      </div>
    )
  }

  const invoiceBodyTemplateName = (rowData) => {
    return (
      <div style={{ display: "grid", gridTemplateColumns: "repeat(2,1fr)" }}>
        <span className="myprofile_invoice_nametag"> {rowData.invoiceNo}</span>
      </div>
    );
  };

  function oldPasswordOnBlur() {
    if (passwordFocus && oldPassword != "" && oldPasswordCheck) {
      emailPasswordLogin();
    }
  }

  function emailPasswordLogin() {
    if (userSessionAuthKeyReturnValue) {
      let userLoginDetails = {
        emailID: emailID,
      };

      axios
        .post(
          "/user/checkIfEmailExists",
          userLoginDetails,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          if (response.data) {
            let userEmailPasswordDetails = {
              emailID: emailID,
              password: oldPassword,
            };
            if (
              userEmailPasswordDetails.emailID != "" &&
              userEmailPasswordDetails.password != ""
            ) {
              axios
                .post("/user/loginAndGetUser", userEmailPasswordDetails)
                .then((response) => {
                  let respData = response.data;
                  if (respData.userID == null) {
                    toast.current.show({
                      severity: "error",
                      summary: "Invalid Password",
                      detail: "The Old Password is Invalid",
                      life: 4000,
                    });
                  } else {
                    toast.current.show({
                      severity: "success",
                      summary: "Password Valid",
                      detail: "Enter the new Password",
                      life: 4000,
                    });
                    setPasswordDisable(false);
                  }
                });
            }
          }
        });
    }
  }
  const checkIfTheEmailExistsAlready = (emailID) => {
    let userLoginDetails = {
      emailID: emailID,
    };

    axios
      .post(
        "/user/checkIfEmailExists",
        userLoginDetails,
        userSessionAuthKeyReturnValue
      )
      .then((response) => {
        if (!response.data) {
        }
      });
  };
  const languageValue = (e) => { };

  const callback = (annualReport, session, link, status) => {
    let sessionUUIDFromLS = localStorage.getItem("sessionUUID");
    if (status) {
      history.push(annualReport + sessionUUIDFromLS + link);
    }
  };

  useEffect(() => {
    localStorage.setItem("currentPageName", "MyProfile");
    // window.scrollTo(0, 0);
    let splittedUrl = window.location.href.split("/");
    currentAppNameFromUrl = splittedUrl[splittedUrl.length - 3];
    if (userSessionAuthKeyReturnValue) {
      if (loginReducer.login.values != undefined) {
        //Checking whether the user is coming to this Page from the Payment Page or from the Dropdown menu
        if (paymentReducer.values.checkoutRef != "") {
          axios
            .get(
              "/annualReportPackage/updateNotificationByCheckoutRef?checkoutRef=" +
              paymentReducer.values.checkoutRef,
              userSessionAuthKeyReturnValue
            )
            .then((updateNotifyResponse) => { });
        }

        axios
          .get(
            "/annualReportPackage/getPaymentLines",
            userSessionAuthKeyReturnValue
          )
          .then((response) => {
            // setLoading(false);
            let respData = response.data;
            let activePackage = respData && respData.filter((item) => item.status == true);
            let inActivePackage = respData && respData.filter(
              (item) => item.status == false
            );
            if (activePackage.length == 0 && inActivePackage.length > 0) {
              setValue(options[1]);
              myPackageTabOnChange(TranslationFile.inActive[userSelectedLanguage]);
            }
            respData.map((i) => {
              return (
                (i.startingDate = i.startingDate.slice(0, 10)),
                (i.validUntil = i.validUntil.slice(0, 10))
              );
            });
            respData.map((i, idx) => {
              if (i.startingDate > i.validUntil) {
                return (i.validUntil = "Unlimited");
              }
            });
            setPackageAPIResponse(activePackage);
          });
      }
    }

    const timer = setTimeout(() => {
      let userTourGuide = JSON.parse(localStorage.getItem("TourGuide")) || false;
      setRun(userTourGuide);
    }, 200);
    return () => clearTimeout(timer);

  }, []);

  useEffect(() => {
    let userTourGuide = JSON.parse(localStorage.getItem("TourGuide")) || false;
    let tourGuidePages = JSON.parse(localStorage.getItem("tourGuidePages")) || [];

    if (userTourGuide) {
      if (tourGuidePages.includes(currentPageTourGuideName)) {
        setTimeout(() => {
          setRun(false);
        }, 200);
      } else {
        setTimeout(() => {
          setRun(true);
        }, 200);
      }
    }

    // Update tourGuidePages in localStorage
    if (!tourGuidePages.includes(currentPageTourGuideName)) {
      tourGuidePages.push(currentPageTourGuideName);
      localStorage.setItem("tourGuidePages", JSON.stringify(tourGuidePages));
    }
  }, [currentPageTourGuideName]);


  const sideBarStatus = (e) => {
    setSideBarOpenState(e);
  };

  const sideBarCallback = (value) => { };

  const checkPasswordMatch = () => {
    if (newPassword != "" && newConfirmPassword != "") {
      if (newPassword == newConfirmPassword) {
        setPasswordMismatch(false);
      } else {
        setPasswordMismatch(true);
      }
    }
  };

  const tourStatus = (e) => {
    setRun(e)
  }


  return (
    <div>
      <Joyride
        continuous
        run={run}
        disableBeacon={true}
        showProgress={false}
        showSkipButton
        steps={steps}
        scrollToFirstStep
        scrollToSteps
        scrollOffset={100}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />

      <Toast ref={toast} position="top-right" className="toast-top" />
      <div className="my-profile-main">
        {/* {currentAppNameFromUrl == "annualreport" && ( */}
        <NavBarHeader
          isAppPortal={true}
          isHomePage={false}
          callBack={callback}
        />
        {/* )} */}
        {/* {currentAppNameFromUrl == "income-declaration-2" &&
          (
            <SRUNavBarHeader
              callBack={callback}
              isAppPortal={true}
              isHomePage={false}

            />
          )
        } */}
        <div className="my-profile-sidebar">
          {/* {currentAppNameFromUrl == "annualreport" && ( */}


          {/* <Sidebar
            sideBarStatus={sideBarStatus}
            sideBarCallback={(e) => sideBarCallback(e)}
            tourStatus={tourStatus}
          /> */}


          {/* )} */}
          {/* {currentAppNameFromUrl == "income-declaration-2" &&
            (
              <SRUSidebar
                sideBarStatus={sideBarStatus}
                sideBarCallback={(e) => sideBarCallback(e)}

              />
            )
          } */}
        </div>
      </div>
      <div className="my-profile-main-div">
        <Accordion
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
          // ref={this.myRef}
          className="profile-accordion"
          multiple
        >
          {/* <AccordionTab header="My Profile" className={currentAppNameFromUrl == "annualreport" ? "IS-Accordion-Tab" : "SRU-INK2-accordion-Tab"}> */}
          <AccordionTab
            header={TranslationFile.myProfileText[userSelectedLanguage]}
            className="IS-Accordion-Tab"
          >
            <div className="my-profile-div">
              <br></br>
              <Row>
                <Col></Col>
                <Col className="edit-btn-col">
                  <Button
                    icon="pi pi-pencil"
                    rounded
                    outlined
                    className="header-edit"
                    onClick={() => editUserInfo()}
                  />
                </Col>
              </Row>
              <Divider />
              <Row>
                <Col className="myProfile-Name">
                  {TranslationFile.LoginFirstName[userSelectedLanguage]}
                </Col>
                <Col>{userName}</Col>
              </Row>
              <Divider />

              <Row>
                <Col className="myProfile-Name">
                  {TranslationFile.LoginLastName[userSelectedLanguage]}
                </Col>
                <Col>{userLastName}</Col>
              </Row>
              <Divider />
              <Row>
                <Col className="myProfile-Email">
                  {TranslationFile.EmailIdText[userSelectedLanguage]}
                </Col>
                <Col>{emailID}</Col>
              </Row>
              <Divider />
              <Row>
                <Col className="myProfile-Contactno">   {TranslationFile.ContactNumberText[userSelectedLanguage]}</Col>
                <Col>
                  {contactNo != null && contactNo != "0" ? contactNo : "-"}
                </Col>
              </Row>
              <Divider />
              {/* <Row>
                <Col>Password</Col>
                <Col>{oldPassword}</Col>
              </Row>
              <Divider /> */}
              {role != null ? (
                <div>
                  <Row>
                    <Col className="myProfile-Role">
                      {TranslationFile.repRole[userSelectedLanguage]}
                    </Col>
                    <Col>{role}</Col>
                  </Row>
                  <Divider />
                </div>
              ) : ("")}
            </div>
          </AccordionTab>

          {/* <AccordionTab header="My Packages" className={currentAppNameFromUrl == "annualreport" ? "IS-Accordion-Tab" : "SRU-INK2-accordion-Tab"}> */}
          <AccordionTab
            header={TranslationFile.myProfilepackages[userSelectedLanguage]}
            className="IS-Accordion-Tab"
          >
            <SelectButton className="my-reports-select-button" />
            <div>
              {" "}
              <Toolbar
                className="profile_mb-4"
                left={header}
                right={dashboardMyReports}
              ></Toolbar>
              <DataTable
                ref={dt}
                value={packageAPIResponse}
                editMode="cell"
                selectionMode="checkbox"
                dataKey="id"
                paginator
                rows={10}
                emptyMessage={
                  TranslationFile.noPackagesFound[userSelectedLanguage]
                }
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                rowsPerPageOptions={[5, 10, 15]}
                globalFilter={globalFilter}
                header={header}
                rowGroupMode="rowspan"
                groupRowsBy="paymentEntryNo"
                sortMode="single"
                sortField="paymentEntryNo"
                sortOrder={1}
                tab
              >
                <Column
                  field="paymentEntryNo"
                  header={
                    TranslationFile.myprofileDownload[userSelectedLanguage]
                  }
                  body={invoiceBodyTemplate}
                  style={{ width: "8%" }}
                  className="mypackage-invoice"
                ></Column>

                <Column
                  field="paymentEntryNo"
                  header={
                    TranslationFile.myprofileInvoiceNo[userSelectedLanguage]
                  }
                  body={invoiceBodyTemplateName}
                  style={{ width: "8.4%" }}
                  className="mypackage-invoice"
                ></Column>

                <Column
                  header={
                    TranslationFile.myprofileApplication[userSelectedLanguage]
                  }
                  field="application"
                ></Column>

                <Column
                  field="packageName"
                  header={TranslationFile.PackageNameText[userSelectedLanguage]}
                  className="mypackage-packagename"
                ></Column>

                <Column
                  field="startingDate"
                  header={TranslationFile.ValidFromText[userSelectedLanguage]}
                  className="mypackage-startingdate"
                ></Column>

                <Column
                  field="validUntil"
                  header={TranslationFile.ValidUntilText[userSelectedLanguage]}
                  className="mypackage-validuntil"
                ></Column>

                <Column
                  field="maxUsage"
                  header={TranslationFile.MaxiUsageText[userSelectedLanguage]}
                  className="mypackage-maxiusage"
                  id="mypackage-maxi"
                ></Column>

                <Column
                  // field="maxUsage"
                  header={TranslationFile.RemainingText[userSelectedLanguage]}
                  className="mypackage-maxiusage"
                  id="mypackage-maxi"
                  body={remainingUsage}
                ></Column>

                <Column
                  field="actualUsage"
                  header={TranslationFile.ActualUsageText[userSelectedLanguage]}
                  className="mypackage-actualusage"
                  body={actualUsageBodyTemplate}
                ></Column>
              </DataTable>
            </div>
            {/* )} */}
          </AccordionTab>
        </Accordion>

        <Dialog
          blockScroll
          visible={userInfoDialog}
          style={{ width: "32rem" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          header={TranslationFile.EditMyProfileText[userSelectedLanguage]}
          modal
          footer={productDialogFooter}
          onHide={hideDialog}
        >
          <div>
            <div className="field">
              <p className="adminPageCompanyInfoEditPopUpTitles">{TranslationFile.FirstNameText[userSelectedLanguage]}</p>
              <InputText
                id="name"
                value={editFirstName}
                onChange={(e) => setEditFirstName(e.target.value)}
                required
                autoFocus
                placeholder={TranslationFile.FirstNameText[userSelectedLanguage]}
              />
            </div>
            <br></br>
            <div className="field">
              <p className="adminPageCompanyInfoEditPopUpTitles">  {TranslationFile.LastName[userSelectedLanguage]}</p>
              <InputText
                id="name"
                value={editSecondName}
                onChange={(e) => setEditSecondName(e.target.value)}
                required
                placeholder={TranslationFile.LastName[userSelectedLanguage]}
              />
            </div>
            <br></br>
            <div
              className="field"
              onFocus={() => setEmailIdFocus(true)}
              onBlur={() => setEmailIdFocus(false)}
            >
              <p className="adminPageCompanyInfoEditPopUpTitles">{TranslationFile.EmailIdText[userSelectedLanguage]}</p>
              <InputText
                id="name"
                value={emailID}
                placeholder={TranslationFile.EmailIdText[userSelectedLanguage]}
                disabled
              />
            </div>
            <br></br>
            <div className="field">
              <p className="adminPageCompanyInfoEditPopUpTitles">
                {TranslationFile.ContactNumberText[userSelectedLanguage]}
              </p>
              <InputText
                id="name"
                value={editcontactNo}
                onChange={(e) => setEditContactNo(e.target.value)}
                required
                placeholder={TranslationFile.ContactNumberText[userSelectedLanguage]}
              />
            </div>
            <br></br>
            <div
              className="field"
              onFocus={() => setPasswordFocus(true)}
              onBlur={() => setPasswordFocus(false)}
            >
              <p className="adminPageCompanyInfoEditPopUpTitles">
                {TranslationFile.OldPassText[userSelectedLanguage]}
              </p>

              <div className="p-inputgroup">
                <input
                  type={isMasked ? "password" : "text"}
                  value={oldPassword}
                  required
                  onChange={(e) => {
                    setOldPassword(e.target.value);
                    setOldPasswordCheck(true);
                  }}
                  onBlur={() => oldPasswordOnBlur()}
                  placeholder={TranslationFile.IncomestateYearPassword[userSelectedLanguage]}
                  className="p-inputtext p-component"
                  id="name"
                />

                {/* Toggle Icon */}
                <span
                  className="p-inputgroup-addon"
                  onClick={toggleMask}
                  style={{ cursor: "pointer" }}
                >
                  <i
                    className={isMasked ? "pi pi-eye-slash" : "pi pi-eye"}
                    style={{ fontSize: "1.2rem" }}
                  ></i>
                </span>
              </div>

              {/* <Password
                id="name"
                value={oldPassword}
                onChange={(e) => {
                  setOldPassword(e.target.value);
                  setOldPasswordCheck(true);
                }}
                onBlur={() => oldPasswordOnBlur()}
                required
                placeholder={TranslationFile.IncomestateYearPassword[userSelectedLanguage]}
                toggleMask
                feedback={false}
              /> */}
            </div>
            <br></br>
            <div
              className="field"
              onFocus={() => setPasswordFocus(true)}
              onBlur={() => setPasswordFocus(false)}
            >
              <p className="adminPageCompanyInfoEditPopUpTitles">
                {/* New Password */}
                {TranslationFile.NewPassText[userSelectedLanguage]}
              </p>


              <div className="p-inputgroup">
                <input
                  type={isMasked ? "password" : "text"}
                  value={newPassword}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                    // setNewPasswordCheck(true);
                  }}
                  onBlur={() => checkPasswordMatch()}
                  disabled={passwordDisable}
                  required
                  placeholder={TranslationFile.IncomestateYearPassword[userSelectedLanguage]}
                  className="p-inputtext p-component"
                />

                {/* Toggle Icon */}
                <span
                  className="p-inputgroup-addon"
                  onClick={toggleMask}
                  style={{ cursor: "pointer" }}
                >
                  <i
                    className={isMasked ? "pi pi-eye-slash" : "pi pi-eye"}
                    style={{ fontSize: "1.2rem" }}
                  ></i>
                </span>
              </div>
              {/* <Password
                id="name"
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                  // setNewPasswordCheck(true);
                }}
                onBlur={() => checkPasswordMatch()}
                disabled={passwordDisable}
                required
                placeholder={TranslationFile.IncomestateYearPassword[userSelectedLanguage]}
                toggleMask
                feedback={false}
              /> */}
            </div>
            <br></br>
            <div
              className="field"
              onFocus={() => setPasswordFocus(true)}
              onBlur={() => setPasswordFocus(false)}
            >
              <p className="adminPageCompanyInfoEditPopUpTitles">
                {/* Confirm New Password */}
                {TranslationFile.ConfirmPassText[userSelectedLanguage]}
              </p>

              <div className="p-inputgroup">
                <input
                  type={isMasked ? "password" : "text"}
                  value={newConfirmPassword}
                  onChange={(e) => {
                    setNewConfirmPassword(e.target.value);
                    // setOldPasswordCheck(true);
                  }}
                  onBlur={() => checkPasswordMatch()}
                  required
                  placeholder={TranslationFile.IncomestateYearPassword[userSelectedLanguage]}
                  disabled={passwordDisable}
                  className="p-inputtext p-component"
                />

                {/* Toggle Icon */}
                <span
                  className="p-inputgroup-addon"
                  onClick={toggleMask}
                  style={{ cursor: "pointer" }}
                >
                  <i
                    className={isMasked ? "pi pi-eye-slash" : "pi pi-eye"}
                    style={{ fontSize: "1.2rem" }}
                  ></i>
                </span>
              </div>

              {/* <Password
                id="name"
                value={newConfirmPassword}
                onChange={(e) => {
                  setNewConfirmPassword(e.target.value);
                  // setOldPasswordCheck(true);
                }}
                onBlur={() => checkPasswordMatch()}
                required
                placeholder={TranslationFile.IncomestateYearPassword[userSelectedLanguage]}
                toggleMask
                feedback={false}
                disabled={passwordDisable}
              /> */}
              {passwordMismatch && newConfirmPassword != "" && (
                <div className="error">{TranslationFile.PasswordMustMatchText[userSelectedLanguage]}</div>
              )}
            </div>
            <br></br>
          </div>
        </Dialog>

        <Dialog
          className="mypackage-dialoge-box-22"
          header={TranslationFile.CompletedPackListText[userSelectedLanguage]}
          blockScroll
          visible={actualUsageVisible}
          style={{ width: "40vw" }}
          onHide={() => setActualUsageVisible(false)}
        >
          <DataTable
            value={showReport}
            className="mypackage-datatable-22-list"
            paginator
            rows={5}
            emptyMessage={TranslationFile.dashboardNoReport[userSelectedLanguage]}
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            rowsPerPageOptions={[5, 10, 15]}
          >
            <Column
              field="companyName"
              header={TranslationFile.dashboardCompanyName[userSelectedLanguage]}
              className="mypackage-company-name"
              body={companyNameBodyTemplate}
            ></Column>

            <Column
              field="registrationNo"
              header={TranslationFile.IncomestateCompanyOrgNo[userSelectedLanguage]}
              className="mypackage-organization-number"
            ></Column>

            <Column
              field="createdAtDateTime"
              header={TranslationFile.dashboardCreatedOn[userSelectedLanguage]}
              className="mypackage-createddate"
            ></Column>

            <Column
              field="reportStatus"
              header="Status"
              className="mypackage-reportstatus"
            ></Column>
          </DataTable>
        </Dialog>
      </div>
      {/* <Footerpage /> */}
      <Footer_contact_tab
        sideBar={sideBarStatus}
        isCorpPortal={false}
        isHomePage={true}
      />
    </div>
  );
}
