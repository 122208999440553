import React, { Component } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { connect } from "react-redux";
import { Toast } from "primereact/toast";
import ScrolltoTop from "../ScrollTop/ScrollTop";
import axios from "axios";
import { Button } from "primereact/button";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { setUserSessionAuthKey } from "../../Component/commonUtils";
import TranslationFile from "../15-Translation/locale/Translation_apps.json";
import NavBarHeader from "../NavBarHeader/NavBarHeader";
import Footer_contact_tab from "../Footerpage/Footer_contact_tab";
import config from "../../Configuration/Config";
import { Checkbox } from "primereact/checkbox";
import { Fieldset } from "primereact/fieldset";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import { Calendar } from "primereact/calendar";
import "./ReviewAndSend.css";
import { InputText } from "primereact/inputtext";
import { setupLocales } from "../../Component/setupLocales";
import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";


let userSessionAuthKeyReturnValue = setUserSessionAuthKey();
let tourGuidePages = localStorage.getItem("tourGuidePages");
let currentPageTourGuideName = "ReviewAndSend";

const mapStateToProps = (state) => {
  return {
    annualReportType: state.annualReportType.annualReportType.values,
    companyInformation: state.companyInformation.companyInformation.values,
    financialYear: state.financialYear.financialYear,
    sessionDetails: state.sessionDetails.sessionDetails.values,
    login: state.login.login.values,
    sideBarReducer: state.sideBarReducer.sideBarReducer,
    prices: state.prices.prices,
    currentPageName: state.currentPageName.currentPageName,
    payment: state.payment.payment,
  };
};
let base64 = ``;
let currentReportGUIDFromUrl = "",
  linkSharedUser,
  sideBarGreenTickPages = [];
let userSelectedLanguage, localeLang = '';

let steps = [
  // {
  //   content: TranslationFile.TourGuide_AddNewColumn_Btn[userSelectedLanguage],
  //   placement: "top",
  //   styles: {
  //     options: {
  //       width: 300,
  //     },
  //   },
  //   target: "#addNewColumnMS",
  //   title: TranslationFile.AddNewRecord[userSelectedLanguage],
  //   disableBeacon: true,
  //   delay: 500,
  // },
];


class ReviewAndSend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkoutId: "",
      checkoutPurchaseId: "",
      checkoutAmount: 0,
      showPayementSuccessModal: false,
      showNotification: false,
      loading: true,
      pdfUrl: "",
      sideBarStatus: true,
      isLoading: true,
      getRepList: '',
      repOptions: [],
      repValue: "",
      repSSN: "",
      repEmailId: "",
      repDate: new Date(),
      termsCheck: false,
      run: false,
    };
    this.acceptSendToBolagsverket = this.acceptSendToBolagsverket.bind(this);
  }

  componentWillMount() {
    window.scrollTo(0, 0);
    userSelectedLanguage = localStorage.getItem("selectedLanguage");
    if (userSelectedLanguage == 'sw') {
      localeLang = 'sv'
    } else if (userSelectedLanguage == 'en') {
      localeLang = 'en'
    }
    setupLocales(localeLang);

    userSessionAuthKeyReturnValue = setUserSessionAuthKey();
    const { login, sideBarReducer, payment } = this.props;

    // base64 = localStorage.getItem("pdfBase64");

    let paysonGetCheckoutObj = {
      userId: login.userID,
      isCheckoutObj: true,
    };

    let localData = localStorage.getItem("rebelSkoolUser");
    let localObject = localData && JSON.parse(localData);
    this.setState({ repEmailId: localObject.emailID })

    this.setState({
      showNotification: false,
    });
    window.scrollTo(0, 0);
    let splittedUrl = window.location.href.split("/");
    currentReportGUIDFromUrl = splittedUrl[splittedUrl.length - 2];
    window.scrollTo(0, 0);

    if (userSessionAuthKeyReturnValue) {
      axios
        .get(
          "/annualReport/getHeader?reportId=" + currentReportGUIDFromUrl,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          if (response.data) {
            let responseDate = response.data;

            console.log(responseDate, "@@22");

            if (responseDate != "") {
              let reportAccessReq = {
                reportId: responseDate.reportID,
              };
              axios
                .post(
                  "/annualReport/getAccessStatus",
                  reportAccessReq,
                  userSessionAuthKeyReturnValue
                )
                .then((response) => {
                  switch (response.data) {
                    case 2:
                      linkSharedUser = 2; //Write
                      break;
                    case 3:
                      linkSharedUser = 3; //Read Only
                      break;
                    case 4:
                      linkSharedUser = 4; //No Access
                      break;
                  }

                  if (response.data <= 3) {
                    axios
                      .get(
                        "/annualReport/pdf?reportGUID=" +
                        currentReportGUIDFromUrl,
                        {
                          responseType: "blob",
                        }
                      )
                      .then((response) => {
                        const url = window.URL.createObjectURL(
                          new Blob([response.data], { type: "application/pdf" })
                        );
                        this.setState({
                          pdfUrl: url,
                          isLoading: false
                        });
                      });
                  }

                  axios
                    .get(
                      "/annualReportRepresentative/getByReportId?reportID=" +
                      responseDate.reportID,
                      userSessionAuthKeyReturnValue
                    )
                    .then((response) => {
                      if (response.data.length > 0) {
                        this.setState({ getRepList: response.data });
                        let repResponse = response.data;
                        let combinedKeys = [];
                        repResponse &&
                          repResponse.forEach((val) => {
                            let mergedSet = {
                              no: val.no,
                              name:
                                val.firstName +
                                " " +
                                val.lastName +
                                " (" +
                                val.role +
                                ")",
                            };
                            combinedKeys.push(mergedSet);
                          });
                        this.setState({ repOptions: combinedKeys });
                      }
                    });
                  axios
                    .get(
                      "/annualReport/getCompletedPages?reportId=" +
                      responseDate.reportID,
                      userSessionAuthKeyReturnValue
                    )
                    .then((completedStatementTypesResponse) => {
                      sideBarReducer.values =
                        completedStatementTypesResponse.data;
                      sideBarGreenTickPages =
                        completedStatementTypesResponse.data;
                      sideBarGreenTickPages.push(
                        "My Dashboard",
                        "Company Information",
                        "Digital Signature",
                        "Review And Send"
                      );
                      let updatePossibleNavigationPagesListString =
                        JSON.stringify(sideBarGreenTickPages);
                      localStorage.setItem(
                        "possibleAccessablePages",
                        updatePossibleNavigationPagesListString
                      );
                      this.setState({
                        reRender: true,
                      });
                    });
                })
                .catch((error) => {
                  let noAccessURL =
                    "/annualreport/" + currentReportGUIDFromUrl + "/no-access";
                  this.props.history.push(noAccessURL);
                });
            }
            // }

            axios
              .get(
                "/annualReport/getCompletedPages?reportId=" +
                response.data.reportID,
                userSessionAuthKeyReturnValue
              )
              .then((completedStatementTypesResponse) => {
                let sideBarReducerValues = [];
                sideBarReducerValues = completedStatementTypesResponse.data;
                if (linkSharedUser != 3) {
                  sideBarReducerValues.push("Payment");
                }
                sideBarReducer.values = sideBarReducerValues;
                sideBarGreenTickPages = completedStatementTypesResponse.data;
                this.setState({
                  reRender: true,
                });

                setTimeout(() => {
                  if (payment.values.checkoutRef != "") {
                    axios
                      .get(
                        "/annualReportPackage/getCheckoutDetailsByRef?checkoutRef=" +
                        payment.values.checkoutRef,
                        userSessionAuthKeyReturnValue
                      )
                      .then((checkoutRefResponse) => {
                        this.setState({
                          loading: false,
                        });
                        if (
                          checkoutRefResponse.data != "" &&
                          !checkoutRefResponse.data.isNotificationShown
                        ) {
                          this.setState({
                            showNotification: true,
                          });
                          axios
                            .get(
                              "/annualReportPackage/updateNotificationByCheckoutRef?checkoutRef=" +
                              payment.values.checkoutRef,
                              userSessionAuthKeyReturnValue
                            )
                            .then((updateNotifyResponse) => { });
                        }
                      });
                    // this.setState({ isLoading: false });
                  } else {
                    this.setState({
                      loading: false,
                    });
                  }
                }, 3000);
              });
          }
        });
    }

    tourGuidePages = localStorage.getItem("tourGuidePages");
    if (tourGuidePages != null) {
      tourGuidePages = JSON.parse(tourGuidePages)
      if (!tourGuidePages.includes(currentPageTourGuideName)) {
        tourGuidePages.push(currentPageTourGuideName)
      }
      localStorage.setItem("tourGuidePages", JSON.stringify(tourGuidePages))
    }
    else {
      let currentPageTourGuideArray = []
      currentPageTourGuideArray.push(currentPageTourGuideName)
      localStorage.setItem("tourGuidePages", JSON.stringify(currentPageTourGuideArray))
    }

  }

  repValueOnChange(e) {
    const { getRepList } = this.state;
    this.setState({ repValue: e.target.value });
    let selectedValue = e.target.value;
    if (selectedValue != "" && selectedValue != null) {

      //Find repFirstName in repList
      let FilteredRep =
        getRepList && getRepList.filter((val) => (val.no == selectedValue.no));

      this.setState({ repSSN: FilteredRep[0].ssn });
    }
  }

  paymentSuccessModalOnHide() {
    this.setState({
      showPayementSuccessModal: false,
    });
  }

  acceptSendToBolagsverket() {
    this.toast.show({
      severity: "success",
      summary: TranslationFile.SumissionSuccess[userSelectedLanguage],
      detail: TranslationFile.PaymentSubmitToBolags[userSelectedLanguage],
      life: 2000,
    });
  }

  next(e) {
    const annualReport = "/annualreport/";
    this.props.history.push(
      annualReport + currentReportGUIDFromUrl + "/digitalSignature"
    );
  }

  editReport() {
    const annualReport = "/annualreport/";
    this.props.history.push(
      annualReport + currentReportGUIDFromUrl + "/companyInfo"
    );
    window.location.reload(false);
  }

  sideBarCallback = (value) => { };
  sideBarStatus = (e) => {
    this.setState({
      sideBarStatus: e,
    });
  };

  UploadLegendTemplate() {
    return <div className="review-legend-template"> {TranslationFile.uploadToBlogsText[userSelectedLanguage]}</div>;
  }
  reviewLegendTemplate() {
    return <div className="review-legend-template">{TranslationFile.ReviewPDFText[userSelectedLanguage]}</div>;
  }


  componentDidMount() {
    if (this.state.run) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    let userTourGuide = JSON.parse(localStorage.getItem("TourGuide")) || false;

    if (userTourGuide) {
      if (tourGuidePages != null && tourGuidePages.length > 0 && tourGuidePages.includes(currentPageTourGuideName)) {
        setTimeout(() => {
          this.setState({ run: false });
        }, 200);
      } else {
        setTimeout(() => {
          this.setState({ run: true });
        }, 200);
      }
    }

  }

  tourStatus = (e) => {
    this.setState({ run: e });
  };

  render() {
    const {
      checkoutId,
      checkoutPurchaseId,
      checkoutAmount,
      showPayementSuccessModal,
      showNotification,
      loading,
      pdfUrl,
      isLoading,
      repValue,
      repOptions,
      repSSN,
      repEmailId,
      repDate,
      termsCheck,
    } = this.state;

    return (
      <div className="notes-main-div">
        <Joyride
          continuous
          run={this.state.run}
          disableBeacon={true}
          showProgress={false}
          showSkipButton
          steps={steps}
          scrollToFirstStep
          scrollToSteps={true}
          disableScrolling={false}
          scrollOffset={100}
          locale={{
            last: "End",
            back: "Back",
            next: "Next",
            skip: "Skip",
          }}
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
        />

        <Toast
          ref={(el) => {
            this.toast = el;
          }}
          style={{ marginTop: "80px" }}
        ></Toast>

        {/* <Header /> */}
        <NavBarHeader
          callBack={this.callback}
          isAppPortal={true}
          isHomePage={false}
        />

        <div className="paymentSideBar">
          <Sidebar
            children="Review And Send"
            sideBarStatus={this.sideBarStatus.bind(this)}
            sideBarCallback={(e) => this.sideBarCallback(e)}
            tourStatus={this.tourStatus.bind(this)}
          />
        </div>
        <ConfirmPopup />
        {isLoading ? (
          <div className="ISLoading">
            <i
              className="pi pi-spin pi-spinner"
              style={{ fontSize: "2rem" }}
            ></i>
          </div>
        ) : (
          <div>
            <div
              className="reviewAndSendPaymentSuccess"
              style={{ display: showNotification ? "inherit" : "none" }}
            >
              {/* <Messages ref={(el) => (this.msgs1 = el)} /> */}
            </div>

            <div className="reviewAndSend-div">
              <Fieldset
                legend={this.reviewLegendTemplate()}
                className="review-fieldset"
              >
                <div className="review-edit">
                  <Row>
                    <Col
                      xl={10}
                      style={{ textAlign: "center", paddingTop: "3px" }}
                    ></Col>
                    <Col>
                      <Button
                        label={TranslationFile.EditReportText[userSelectedLanguage]}
                        icon="pi pi-pencil"
                        onClick={() => this.editReport()}
                      />
                    </Col>
                  </Row>
                </div>
                <div>
                  <center>
                    <div className="review-iframe-div">
                      {pdfUrl && pdfUrl.length > 0 && (
                        <iframe
                          src={pdfUrl}
                          width="1150px"
                          height="1130px"
                        ></iframe>
                      )}
                    </div>
                  </center>
                </div>
              </Fieldset>

              <Fieldset
                legend={this.UploadLegendTemplate()}
                className="review-fieldset"
              >
                <Row className="upload-row">
                  <Col>
                    <label
                      htmlFor="Name"
                      className="companyInformationTextBoxTitle"
                    >
                      {TranslationFile.representativeText[userSelectedLanguage]}
                      <p className="companyInformationMandatoryStart">*</p>
                    </label>
                    <Dropdown
                      value={repValue}
                      options={repOptions}
                      optionLabel="name"
                      onChange={(e) => this.repValueOnChange(e)}
                      placeholder={TranslationFile.SelectSignRepText[userSelectedLanguage]}
                      className="sign-rep-dropdown"
                    />
                  </Col>

                  <Col>
                    <label
                      htmlFor="Email"
                      className="companyInformationTextBoxTitle"
                    >
                      SSN<p className="companyInformationMandatoryStart">*</p>
                    </label>

                    <InputMask
                      useGrouping={false}
                      value={repSSN}
                      onChange={(e) =>
                        this.setState({ repSSN: e.target.value })
                      }
                      mask="99999999-9999"
                      required
                      placeholder={TranslationFile.SSNFormat[userSelectedLanguage]}
                    />
                  </Col>
                </Row>
                <br></br>
                <Row className="upload-row">
                  <Col>
                    <label
                      htmlFor="Name"
                      className="companyInformationTextBoxTitle"
                    >
                      {TranslationFile.EmailIdText[userSelectedLanguage]}
                      <p className="companyInformationMandatoryStart">*</p>
                    </label>
                    <InputText
                      value={repEmailId}
                      onChange={(e) =>
                        this.setState({ repEmailId: e.target.value })
                      }
                      required
                      placeholder={TranslationFile.EmailIdText[userSelectedLanguage]}
                    />
                  </Col>

                  <Col>
                    <label
                      htmlFor="Email"
                      className="companyInformationTextBoxTitle"
                    >
                      {TranslationFile.repDate[userSelectedLanguage]}
                      <p className="companyInformationMandatoryStart">*</p>
                    </label>
                    <Calendar
                      className="review-rep-calendar"
                      value={repDate}
                      onChange={(e) =>
                        this.setState({ repDate: e.target.value })
                      }
                      showIcon
                      dateFormat="yy-mm-dd"
                      placeholder={TranslationFile.YearFormat[userSelectedLanguage]}
                      locale={localeLang}
                    />
                  </Col>
                </Row>
                <br></br>
                <center>
                  <div className="review-terms-condition">
                    <Checkbox
                      checked={termsCheck}
                      onChange={(e) => this.setState({ termsCheck: e.checked })}
                    ></Checkbox>
                    <span style={{ marginLeft: "1%" }}>
                      {TranslationFile.loginAcceptText[userSelectedLanguage]}
                    </span>
                    <span>
                      <a
                        className="classcolourterm"
                        href={config.corporatePage.url + "terms-of-usage"}
                        target="_blank"
                      >
                        {TranslationFile.loginTermsText[userSelectedLanguage]}
                      </a>
                    </span>
                  </div>
                  <div className="review-submit">
                    {repValue != "" &&
                      repValue != null &&
                      repSSN != "" &&
                      repSSN != null &&
                      repEmailId != "" &&
                      repEmailId != null &&
                      repDate != "" &&
                      repDate != null &&
                      termsCheck ? (
                      <Button
                        label={TranslationFile.uploadToBlogsText[userSelectedLanguage]}
                        icon="pi pi-send"
                        // onClick={(e) => this.confirmSubmitToBolagsverket(e)}
                        disabled={
                          linkSharedUser == 2 || linkSharedUser == 3
                        }
                      />
                    ) : (
                      <Button
                        label={TranslationFile.uploadToBlogsText[userSelectedLanguage]}
                        icon="pi pi-send"
                        disabled={true}
                      />
                    )}
                  </div>
                </center>
              </Fieldset>
            </div>
          </div>
        )}

        <ScrolltoTop />
        <Footer_contact_tab
          sideBar={this.state.sideBarStatus}
          isCorpPortal={false}
          isHomePage={true}
        />
      </div>
    );
  }
}
export default connect(mapStateToProps, null)(ReviewAndSend);
