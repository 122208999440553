import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { Tag } from "primereact/tag";
import Sidebar from "../Sidebar/Sidebar";
import ScrolltoTop from "../ScrollTop/ScrollTop";
import { Dropdown } from "primereact/dropdown";
import axios from "axios";
import "./Representative.css";
import { Row, Col } from "react-bootstrap";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Calendar } from "primereact/calendar";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { setUserSessionAuthKey, previewPdf } from "../../Component/commonUtils";
import TranslationFile from "../15-Translation/locale/Translation_apps.json";
import NavBarHeader from "../NavBarHeader/NavBarHeader";
import Footer_contact_tab from "../Footerpage/Footer_contact_tab";
import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";
import { setupLocales } from "../../Component/setupLocales";

let userSelectedLanguage = localStorage.getItem("selectedLanguage");
let tourGuidePages = localStorage.getItem("tourGuidePages");
let currentPageTourGuideName = "Representative";

let possibleAccessablePagesListLSArray = [];

let totalRepList = [];
let cityName = "";
let currentReportGUIDFromUrl = "",
  sideBarGreenTickPages = [],
  linkSharedUser, localeLang = '',
  paymentEntryNo,
  reportIDToGet = "",
  pdfLink = "";
let updateOnBlurFName = "",
  updateOnBlurLName = "";
let dataKeyIn = "",
  dateValue = "",
  unixTimestamp = "";
let userSessionAuthKeyReturnValue = setUserSessionAuthKey();

let steps = [
  {
    content: TranslationFile.TourGuide_Add_NewRep_Btn[userSelectedLanguage],
    placement: "top",
    styles: {
      options: {
        width: 300,
      },
    },
    target: "#rep_addRep",
    title: TranslationFile.repAddRep[userSelectedLanguage],
    disableBeacon: true,
  },

  {
    content:
      TranslationFile.TourGuide_Add_CheckBox_Delete_Btn[userSelectedLanguage],
    placement: "top",
    styles: {
      options: {
        width: 300,
      },
    },
    target: ".whole-delete",
    title: TranslationFile.repDeleteBtn[userSelectedLanguage],
    disableBeacon: true,
  },
];

export default function Representative() {
  userSelectedLanguage = localStorage.getItem("selectedLanguage");
  const history = useHistory();

  const signTypeObj = [
    { name: TranslationFile.repPaper[userSelectedLanguage], code: 0 },
    { name: TranslationFile.repDigitalSignature[userSelectedLanguage], code: 1 },
  ];

  const [signTypeOptions] = useState([TranslationFile.repPaper[userSelectedLanguage], TranslationFile.repDigitalSignature[userSelectedLanguage]]);
  const [reRender, setReRender] = useState(false);

  const repRoleOptionsObj = [
    { name: TranslationFile.repRollselect2[userSelectedLanguage], code: "CEO" },
    { name: TranslationFile.repRollselect3[userSelectedLanguage], code: "Auditor" },
    { name: TranslationFile.repRollselect4[userSelectedLanguage], code: "Chairman of the Board" },
    { name: TranslationFile.repRollselect5[userSelectedLanguage], code: "Board member" },
    {
      name: TranslationFile.repRollselect6[userSelectedLanguage],
      code: "Chairman of the board and CEO",
    },
    { name: TranslationFile.repRollselect7[userSelectedLanguage], code: "External CEO" },
    { name: TranslationFile.repRollselect8[userSelectedLanguage], code: "Board deputy" },
    { name: TranslationFile.repRollselect10[userSelectedLanguage], code: "Liquidator" },
  ];

  const [repRoleOptions] = useState([
    TranslationFile.repRollselect2[userSelectedLanguage],
    TranslationFile.repRollselect3[userSelectedLanguage],
    TranslationFile.repRollselect4[userSelectedLanguage],
    TranslationFile.repRollselect5[userSelectedLanguage],
    TranslationFile.repRollselect6[userSelectedLanguage],
    TranslationFile.repRollselect7[userSelectedLanguage],
    TranslationFile.repRollselect8[userSelectedLanguage],
    TranslationFile.repRollselect10[userSelectedLanguage],
  ]);

  let emptyProduct = {
    firstName: "",
    lastName: "",
    socialSecurityNo: "",
    repRole: "",
  };

  const [companyName, setCompanyName] = useState("");
  const [currentFinancialYear, setCurrentFinancialYear] = useState("");
  const [product, setProduct] = useState(null);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const toast = useRef(null);
  const dt = useRef(null);
  const [toggleOpen, setToggleOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState([0, 1]);
  const [addNewRepDialog, setAddNewRepDialog] = useState(false);
  const [repFirstName, setRepFirstName] = useState("");
  const [repLastName, setRepLastName] = useState("");
  const [repSSN, setRepSSN] = useState("");
  const [repRole, setRepRole] = useState("");
  const [signType, setSignType] = useState(signTypeObj[0]);
  const [newRepsubmitted, setNewRepSubmitted] = useState(false);
  const [cityValue, setCityValue] = useState("");
  const [repDate, setRepDate] = useState(new Date());
  const [deleteRep, setDeleteRep] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [sideBarStatus, setSideBarStatus] = useState(true);
  const [reportAccess, setReportAccess] = useState(null);
  const [run, setRun] = useState(false);
  const [calendarHardCodedDate, setCalendarHardCodedDate] = useState("");
  const currentPageTourGuideName = "Representative";

  let [totalRepListArray, setTotalRepListArray] = useState("");
  let [reportIDFromDB, setReportIDFromDB] = useState("");

  const [repFirstNameBlurError, setRepFirstNameBlurError] = useState(false);
  const [repLastNameBlurError, setRepLastNameBlurError] = useState(false);
  const [repSsnBlurError, setRepSsnBlurError] = useState(false);
  const sessionDetails = useSelector((state) => state.sessionDetails);
  const sideBarReducer = useSelector(
    (state) => state.sideBarReducer.sideBarReducer
  );
  const loginReducer = useSelector((state) => state.login);

  function accordionToggle(toggleOpen) {
    if (toggleOpen == true) {
      setActiveIndex([0, 1]);
      setToggleOpen(false);
    } else {
      setActiveIndex([2]);
      setToggleOpen(true);
    }
  }

  const roleEditOnChange = (e, options) => {
    options.rowData.role = e;
  };

  const signTypeEditOnChange = (e, options) => {

    if (e == TranslationFile.repDigitalSignature[userSelectedLanguage] && options.rowData.sSN != "") {
      options.rowData.signType = e;
    } else if (e == TranslationFile.repPaper[userSelectedLanguage]) {
      options.rowData.signType = e;
    } else {
      toast.current.show({
        severity: "error",
        summary: TranslationFile.errorText[userSelectedLanguage],
        detail: (
          <>
            {TranslationFile.repToast1[userSelectedLanguage]}{" "}
            <b>{TranslationFile.repDigitalSignature[userSelectedLanguage]}</b>
          </>
        ),

        life: 6000,
      });
    }
  };

  const roleEditOnBlur = (e, options) => {
    representativeInputOnBlur(e, "role", options);
  };

  const signTypeEditOnBlur = (e, options) => {
    representativeInputOnBlur(e, "signType", options);
  };

  const roleEditor = (options) => {
    return (
      <Dropdown
        value={options.value}
        editable
        options={repRoleOptions}
        onChange={(e) => roleEditOnChange(e.target.value, options)}
        onBlur={(e) => roleEditOnBlur(e, options.rowData)}
        placeholder={TranslationFile.SelectAStatusText[userSelectedLanguage]}
        itemTemplate={(option) => {
          return <Tag value={option}></Tag>;
        }}
      />
    );
  };

  const signTypeEditor = (options) => {
    let signType = TranslationFile.repPaper[userSelectedLanguage];
    if (options.value > 0) signType = TranslationFile.repDigitalSignature[userSelectedLanguage];
    return (
      <Dropdown
        value={signType}
        // editable
        className="signTypeEditDropdown"
        options={signTypeOptions}
        onChange={(e) => signTypeEditOnChange(e.target.value, options)}
        onBlur={(e) => signTypeEditOnBlur(e, options.rowData)}

        placeholder={TranslationFile.SelectASignTypeText[userSelectedLanguage]}
        itemTemplate={(option) => {
          return <Tag value={option}></Tag>;
        }}
      />
    );
  };

  const pageNavigation = (navigateToPageName) => {
    //Check if the user(Report Owner) is logged in or not
    if (reportAccess == 1) {
      let localData = localStorage.getItem("rebelSkoolUser");
      let localObject = localData && JSON.parse(localData);
      if (localObject["userName"] == undefined) {
        document.getElementsByClassName("user-btn")[0].click();
      } else {
        let sideBarReducerValues = [];
        sideBarReducerValues = sideBarReducer.values;

        sideBarReducer.values = sideBarReducerValues;
        let possibleAccessablePagesListLSString = localStorage.getItem(
          "possibleAccessablePages"
        );
        possibleAccessablePagesListLSArray = JSON.parse(
          possibleAccessablePagesListLSString
        );
        if (!possibleAccessablePagesListLSArray.includes("Payment")) {
          possibleAccessablePagesListLSArray.push(
            "Company Information",
            "Income Statement",
            "Balance Sheet",
            "Notes",
            "Management Statement",
            "Payment"
          );

          let updatePossibleNavigationPagesListString = JSON.stringify(
            possibleAccessablePagesListLSArray
          );

          localStorage.setItem(
            "possibleAccessablePages",
            updatePossibleNavigationPagesListString
          );
        }
        history.push("payment");
      }
      //If the user already paid for the report, we should navigate him directly to the Review and send page
      if (paymentEntryNo > 0) {
        // history.push("reviewAndSend");
        history.push("digitalSignature");
        //digitalSignature
      }
    } else {
      //If he is not the Report Owner, we should navigate him directly to the Review and send page
      // history.push("reviewAndSend");
      history.push("digitalSignature");
    }
  };

  const bankIdOnClick = () => {
    history.push("bankId");
  };

  const navigationOnClick = (navigateToPageName) => {
    //If the user wants to navigate to previous page
    if (navigateToPageName == "managementStatement") {
      history.push(navigateToPageName);
    } else {
      //If the user wants to navigate to next page
      // Check if the user filled all the mandatory fields
      let repWithSSN = totalRepList.filter((obj) => obj.sSN != "");
      if (totalRepList.length == 0) {
        toast.current.show({
          severity: "error",
          summary: TranslationFile.errorText[userSelectedLanguage],
          detail: TranslationFile.repToast2[userSelectedLanguage],
          life: 3000,
        });
      } else if (totalRepList.length > 1 && repWithSSN.length == 0) {
        toast.current.show({
          severity: "warn",
          summary: TranslationFile.balanceSheetWarningTag[userSelectedLanguage],
          detail: TranslationFile.repToast3[userSelectedLanguage],
          life: 3000,
        });
      } else if (cityValue == "" || repDate == "") {
        toast.current.show({
          severity: "error",
          summary: TranslationFile.errorText[userSelectedLanguage],
          detail: TranslationFile.repToast4[userSelectedLanguage],
          life: 3000,
        });
      } else if (
        totalRepList.length == 1 &&
        cityValue != "" &&
        repDate !== ""
      ) {
        pageNavigation(navigateToPageName);
      } else if (
        totalRepList.length > 1 &&
        repWithSSN.length >= 1 &&
        cityValue != "" &&
        repDate !== ""
      ) {
        pageNavigation(navigateToPageName);
      }
    }
  };

  const checkForGreenTick = (repListParamter) => {
    let possibleAccessablePagesListLSString = localStorage.getItem(
      "possibleAccessablePages"
    );
    let possibleAccessablePagesListLSArray = JSON.parse(
      possibleAccessablePagesListLSString
    );
    if (
      possibleAccessablePagesListLSArray != null &&
      // && !possibleAccessablePagesListLSArray.includes("Representative")
      repListParamter.length > 0
    ) {
      possibleAccessablePagesListLSArray.push(
        "Company Information",
        "Income Statement",
        "Balance Sheet",
        "Notes",
        "Management Statement",
        "Representative"
      );
      let updatePossibleNavigationPagesListString = JSON.stringify(
        possibleAccessablePagesListLSArray
      );

      localStorage.setItem(
        "possibleAccessablePages",
        updatePossibleNavigationPagesListString
      );
    }
    setReRender(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (userSelectedLanguage == 'sw') {
      localeLang = 'sv'
    } else if (userSelectedLanguage == 'en') {
      localeLang = 'en'
    }
    setupLocales(localeLang);
    let splittedUrl = window.location.href.split("/");
    currentReportGUIDFromUrl = splittedUrl[splittedUrl.length - 2];
    pdfLink = "#annualreport/" + currentReportGUIDFromUrl + "/pdf";
    userSessionAuthKeyReturnValue = setUserSessionAuthKey();
    if (userSessionAuthKeyReturnValue) {
      axios
        .get(
          "/annualReport/getHeader?reportId=" + currentReportGUIDFromUrl,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          if (response.data) {
            paymentEntryNo = response.data.paymentEntryNo;

            let responseDate = response.data;
            cityName = response.data.city;
            // setCityValue(response.data.city)

            if (responseDate != "") {
              let reportAccessReq = {
                reportId: responseDate.reportID,
              };
              axios
                .post(
                  "/annualReport/getAccessStatus",
                  reportAccessReq,
                  userSessionAuthKeyReturnValue
                )
                .then((response) => {
                  switch (response.data) {
                    case 1:
                      linkSharedUser = 1; //Write
                      setReportAccess(1);
                      break;
                    case 2:
                      linkSharedUser = 2; //Write
                      setReportAccess(2);
                      break;
                    case 3:
                      linkSharedUser = 3; //Read Only
                      setReportAccess(3);
                      break;
                    case 4:
                      linkSharedUser = 4; //No Access
                      setReportAccess(4);
                      break;
                  }
                })
                .catch((error) => {
                  let noAccessURL =
                    "/annualreport/" + currentReportGUIDFromUrl + "/no-access";
                  // this.props.history.push(noAccessURL);
                  history.push(noAccessURL);
                });
            }
            // }

            axios
              .get(
                "/annualReport/getCompletedPages?reportId=" +
                response.data.reportID,
                userSessionAuthKeyReturnValue
              )
              .then((completedStatementTypesResponse) => {
                sideBarReducer.values = completedStatementTypesResponse.data;
                sideBarGreenTickPages = completedStatementTypesResponse.data;
              });

            // Get Representative API
            axios
              .get(
                "/annualReportRepresentative/getByReportId?reportID=" +
                response.data.reportID,
                userSessionAuthKeyReturnValue
              )
              .then((response) => {
                if (response.data.length > 0) {
                  totalRepList = response.data;
                  setTotalRepListArray(response.data);
                  if (response.data[0].city != "" && response.data[0].city != null) {
                    setCityValue(response.data[0].city);
                  }
                  else {
                    setCityValue(cityName);
                  }

                  let dateFromDB = response.data[0].date;
                  if (dateFromDB && dateFromDB.length > 0) {
                    let convertedDate = new Date(dateFromDB);
                    setRepDate(convertedDate);
                  }
                  checkForGreenTick(response.data);
                } else {
                  setCityValue(cityName);
                }
              });
          }
          setReportIDFromDB(response.data.reportID);
          setCompanyName(response.data.companyName);
          setCurrentFinancialYear(response.data.currentYear);
        });
    }
    // const timer = setTimeout(() => {
    //   let userTourGuide = JSON.parse(localStorage.getItem("TourGuide")) || false;
    //   setRun(userTourGuide);
    // }, 200);

    // return () => clearTimeout(timer);


  }, []);


  useEffect(() => {
    let userTourGuide = JSON.parse(localStorage.getItem("TourGuide")) || false;
    let tourGuidePages = JSON.parse(localStorage.getItem("tourGuidePages")) || [];

    if (userTourGuide) {
      if (tourGuidePages.includes(currentPageTourGuideName)) {
        setTimeout(() => {
          setRun(false);
        }, 200);
      } else {
        setTimeout(() => {
          setRun(true);
        }, 200);
      }
    }

    // Update tourGuidePages in localStorage
    if (!tourGuidePages.includes(currentPageTourGuideName)) {
      tourGuidePages.push(currentPageTourGuideName);
      localStorage.setItem("tourGuidePages", JSON.stringify(tourGuidePages));
    }
  }, [currentPageTourGuideName]);


  const roleBodyTemplate = (rowData) => {
    return <Tag value={rowData.role}></Tag>;
  };

  const signTypeBodyTemplate = (rowData) => {
    let signType = TranslationFile.repPaper[userSelectedLanguage];
    if (rowData.signType > 0) signType = TranslationFile.repDigitalSignature[userSelectedLanguage];
    return <Tag value={signType}></Tag>;
  };

  const navigateToPDF = () => {
    window.open(`/annualreport/${currentReportGUIDFromUrl}/pdf`, "_blank");
  };

  const addNewRep = () => {
    if (userSessionAuthKeyReturnValue) {
      setNewRepSubmitted(true);
      setRepSsnBlurError(false);
      if (
        repFirstName != "" &&
        repLastName != "" &&
        repRole != "" &&
        signType != "" &&
        repFirstName.length > 1 &&
        repLastName.length > 1
      ) {
        let unixTimestamp = null;
        if (repDate != null) {
          let StandardDateFormat = moment(repDate).format("YYYY-MM-DD");

          const date = new Date(StandardDateFormat);
          unixTimestamp = Math.floor(date.getTime());
        }
        let insertRepToDB = {
          reportID: reportIDFromDB,
          firstName: repFirstName,
          lastName: repLastName,
          sSN: repSSN,
          role: repRole.name == undefined ? repRole : repRole.name,
          signType: signType.code,
          signStatus: 0,
          date: unixTimestamp,
        };

        // Insert Representative API
        if ((signType.code == 1 && repSSN != "") || signType.code == 0) {
          axios
            .post(
              "/annualReportRepresentative/insert",
              insertRepToDB,
              userSessionAuthKeyReturnValue
            )
            .then((response) => {
              if (response.data) {
                if (cityValue != "" && cityValue != null) {
                  CityOnBlur();
                }

                if (unixTimestamp != "" && unixTimestamp != null) {
                  let updateDateObject = {
                    date: unixTimestamp,
                    reportID: reportIDFromDB,
                  };

                  // Insert & Update Representative Date
                  axios
                    .post(
                      "/annualReportRepresentative/updateDate",
                      updateDateObject,
                      userSessionAuthKeyReturnValue
                    )
                    .then((response) => { });
                }
                // Get Representative API
                axios
                  .get(
                    "/annualReportRepresentative/getByReportId?reportID=" +
                    reportIDFromDB,
                    userSessionAuthKeyReturnValue
                  )
                  .then((response) => {
                    totalRepList = response.data;
                    setTotalRepListArray(response.data);

                    toast.current.show({
                      severity: "success",
                      summary:
                        TranslationFile.SuccessfulText[userSelectedLanguage],
                      detail:
                        TranslationFile.RepAddSucess[userSelectedLanguage],
                      life: 3000,
                    });
                    checkForGreenTick(response.data);
                  });

                setTotalRepListArray(totalRepList);
                setRepFirstName("");
                setRepLastName("");
                setRepSSN("");
                setRepRole("");
                setAddNewRepDialog(false);
                setNewRepSubmitted(false);
              } else {
                toast.current.show({
                  severity: "warn",
                  summary: TranslationFile.balanceSheetWarningTag[userSelectedLanguage],
                  detail: TranslationFile.repToast5[userSelectedLanguage],
                  life: 3000,
                });
              }
            });
        }
      }
    }
  };

  const openNew = () => {
    setAddNewRepDialog(true);
    setNewRepSubmitted(false);
    setRepFirstName("");
    setRepLastName("");
    setRepSSN("");
    setRepRole("");
    // setRepRole(repRoleOptions[0]);
  };

  const rightToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label={TranslationFile.repAddRep[userSelectedLanguage]}
          id="rep_addRep"
          icon="pi pi-plus"
          severity="success"
          onClick={openNew}
          disabled={reportAccess == 3 && true}
        />
      </div>
    );
  };

  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };

  const hideDeleteProductsDialog = () => {
    setDeleteProductsDialog(false);
  };

  const confirmDeleteProduct = (rowData) => {
    setProduct(rowData);
    setDeleteProductDialog(true);
  };

  const confirmDeleteSelected = () => {
    setDeleteProductsDialog(true);
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          className="whole-delete"
          label={TranslationFile.repDeleteBtn[userSelectedLanguage]}
          icon="pi pi-trash"
          severity="danger"
          onClick={confirmDeleteSelected}
          disabled={
            !selectedProducts || !selectedProducts.length || reportAccess == 3
          }
        />
      </div>
    );
  };

  const hideAddNewRep = () => {
    setAddNewRepDialog(false);
    setNewRepSubmitted(false);
    setRepFirstName("");
    setRepLastName("");
    setRepSSN("");
    setRepRole("");
    // setRepRole(repRoleOptions[0]);
    setRepFirstNameBlurError(false);
    setRepLastNameBlurError(false);
    setRepSsnBlurError(false);
  };

  const addNewRepDialogFooter = (
    <React.Fragment>
      <Button
        label={TranslationFile.repCancelbtn[userSelectedLanguage]}
        icon="pi pi-times"
        outlined
        onClick={hideAddNewRep}
      />
      <Button
        label={TranslationFile.repSavebtn[userSelectedLanguage]}
        icon="pi pi-check"
        onClick={addNewRep}
      />
    </React.Fragment>
  );

  const onRowEditComplete = (rowData, fieldName) => {
    if (userSessionAuthKeyReturnValue) {
      let updateRepToDB = {
        firstName: rowData.firstName,
        lastName: rowData.lastName,
        sSN: rowData.sSN,
        role: rowData.role,
        reportID: reportIDFromDB,
        no: rowData.no,
        signType: rowData.signType == TranslationFile.repPaper[userSelectedLanguage] || rowData.signType == 0 ? 0 : 1,
      };

      axios
        .post(
          "/annualReportRepresentative/update",
          updateRepToDB,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          if (response.data) {
            toast.current.show({
              severity: "success",
              summary: TranslationFile.SuccessfulText[userSelectedLanguage],
              detail: TranslationFile.RepUpdateSucess[userSelectedLanguage],
              life: 3000,
            });
            axios
              .get(
                "/annualReportRepresentative/getByReportId?reportID=" +
                reportIDFromDB,
                userSessionAuthKeyReturnValue
              )
              .then((response) => {
                totalRepList = response.data;
                setTotalRepListArray(totalRepList);
                checkForGreenTick(totalRepList);
              });
          }

          if (
            document.getElementsByClassName("p-row-editor-save p-link")[0] !=
            undefined
          ) {
            document
              .getElementsByClassName("p-row-editor-save p-link")[0]
              .click();
          }
        });
    }
  };
  const representativeInputOnBlur = (e, field, rowData) => {
    let getRepResponse = "";
    if (userSessionAuthKeyReturnValue) {
      if (field == "SSN" && rowData.signType == 1 && e.target.value == "") {
        toast.current.show({
          severity: "error",
          summary: TranslationFile.errorText[userSelectedLanguage],
          detail: (
            <>
              {TranslationFile.repToast6[userSelectedLanguage]}{" "}
              <b>{TranslationFile.repDigitalSignature[userSelectedLanguage]}</b>{" "}
              {TranslationFile.repToast7[userSelectedLanguage]}
            </>
          ),

          life: 6000,
        });
      } else {
        axios
          .get(
            "/annualReportRepresentative/getByReportId?reportID=" +
            reportIDFromDB,
            userSessionAuthKeyReturnValue
          )
          .then((response) => {
            getRepResponse = response.data;
            getRepResponse =
              getRepResponse &&
              getRepResponse.filter((res) => res.no == rowData.no);

            if (getRepResponse[0].firstName !== rowData.firstName) {
              onRowEditComplete(rowData, "firstName");
            } else if (getRepResponse[0].lastName !== rowData.lastName) {
              onRowEditComplete(rowData, "lastName");
            } else if (getRepResponse[0].sSN !== rowData.sSN) {
              onRowEditComplete(rowData, "sSN");
            } else if (getRepResponse[0].role !== rowData.role) {
              onRowEditComplete(rowData, "role");
            } else if (getRepResponse[0].signType !== rowData.signType) {
              onRowEditComplete(rowData, "signType");
            }
          });
      }
    }
  };

  const textEditorFName = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
        onBlur={(e) => representativeInputOnBlur(e, "FName", options.rowData)}
      />
    );
  };
  const textEditorLName = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
        onBlur={(e) => representativeInputOnBlur(e, "LName", options.rowData)}
      />
    );
  };
  const textEditorMask = (options) => {
    return (
      <InputMask
        value={options.value}
        onChange={(e) => options.editorCallback(e.value)}
        useGrouping={false}
        mask="99999999-9999"
        onBlur={(e) => representativeInputOnBlur(e, "SSN", options.rowData)}
        // required
        placeholder={TranslationFile.SSNFormat[userSelectedLanguage]}
      />
    );
  };
  const deleteProduct = () => {
    if (userSessionAuthKeyReturnValue) {
      setDeleteProductsDialog(false);
      // Delete Representative API
      selectedProducts.map((val) => {
        let deleteRepFromDB = {
          reportID: reportIDFromDB,
          no: val.no,
        };

        axios
          .post(
            "/annualReportRepresentative/delete",
            deleteRepFromDB,
            userSessionAuthKeyReturnValue
          )
          .then((response) => {
            if (response.data) {
              // Get Representative API
              axios
                .get(
                  "/annualReportRepresentative/getByReportId?reportID=" +
                  reportIDFromDB,
                  userSessionAuthKeyReturnValue
                )
                .then((response) => {
                  totalRepList = response.data;
                  setTotalRepListArray(response.data);
                  checkForGreenTick(response.data);
                });

              toast.current.show({
                severity: "error",
                summary: TranslationFile.DeletedText[userSelectedLanguage],
                detail: TranslationFile.RepdeleteSucess[userSelectedLanguage],
                life: 3000,
              });
            }
          });
      });

      totalRepList = totalRepList.filter(
        (val) => !selectedProducts.includes(val)
      );

      setTotalRepListArray(totalRepList);

      setDeleteProductDialog(false);
      setDeleteRep(emptyProduct);

      setSelectedProducts(null);
    }
  };

  const deleteProductDialogFooter = (
    <React.Fragment>
      <Button
        label={TranslationFile.noBtn[userSelectedLanguage]}
        icon="pi pi-times"
        outlined
        onClick={hideDeleteProductDialog}
      />
      <Button
        label={TranslationFile.yesBtn[userSelectedLanguage]}
        icon="pi pi-check"
        severity="danger"
        onClick={deleteProduct}
      />
    </React.Fragment>
  );

  const repFirstNameOnBlur = (e) => {
    let firstName = e.target.value;
    if (firstName != "" && firstName.length < 2) setRepFirstNameBlurError(true);
    else setRepFirstNameBlurError(false);
  };

  const repLastNameOnBlur = (e) => {
    let lastName = e.target.value;
    if (lastName != "" && lastName.length < 2) setRepLastNameBlurError(true);
    else setRepLastNameBlurError(false);
  };

  const languageValue = (e) => { };

  const callback = (annualReport, session, link, status) => {
    if (status) {
      history.push(annualReport + session + link);
    }
  };

  const CityOnBlur = () => {
    if (userSessionAuthKeyReturnValue) {
      let updateCityObject = {
        city: cityValue,
        reportID: reportIDFromDB,
      };
      axios
        .post(
          "/annualReportRepresentative/updateCity",
          updateCityObject,
          userSessionAuthKeyReturnValue
        )
        .then((response) => {
          if (!response.data) {
            toast.current.show({
              severity: "info",
              summary: "Info",
              detail: TranslationFile.repToast2[userSelectedLanguage] + TranslationFile.cityRepToast[userSelectedLanguage],
              life: 3000,
            });
          }
        });
    }
  };

  const dateOnBlur = (e) => {
    if (calendarHardCodedDate != "") {
      if (calendarHardCodedDate.length == 6) {
        var year = calendarHardCodedDate.substr(0, 2);
        var month = calendarHardCodedDate.substr(2, 2);
        var date = calendarHardCodedDate.substr(4, 4);
        var newdate = "20" + year;
        newdate = newdate + "-" + month + "-" + date;
        dateValue = new Date(newdate);
      } else if (calendarHardCodedDate.length == 8) {
        if (calendarHardCodedDate.includes("-")) {
          let splittedFormat = calendarHardCodedDate.split("-");
          var year = "20" + splittedFormat[0];
          var month = splittedFormat[1];
          var date = splittedFormat[2];
          var newdate = year + "-" + month + "-" + date;

          dateValue = new Date(newdate);
        } else {
          var year = calendarHardCodedDate.substr(0, 4);
          var month = calendarHardCodedDate.substr(4, 2);
          var date = calendarHardCodedDate.substr(6, 5);
          var newdate = year;
          newdate = newdate + "-" + month + "-" + date;
          dateValue = new Date(newdate);
        }
      }
      setRepDate(dateValue);
      if (dateValue != "") {
        insertDateInForRep(dateValue, "dateKeyIn");
      }
    }
  };

  const insertDateInForRep = (e, type) => {
    if (userSessionAuthKeyReturnValue) {
      let originalDateFormat = "";
      if (type == "dateKeyIn") {
        originalDateFormat = e;
      } else {
        originalDateFormat = e.target.value;
      }
      let StandardDateFormat = moment(originalDateFormat).format("YYYY-MM-DD");

      const date = new Date(StandardDateFormat);
      const unixTimestamp = Math.floor(date.getTime());
      //  const unixTimestamp = (new Date(StandardDateFormat)).getTime();
      let updateDateObject = {
        date: unixTimestamp,
        reportID: reportIDFromDB,
      };

      // Insert & Update Representative Date
      axios
        .post(
          "/annualReportRepresentative/updateDate",
          updateDateObject,
          userSessionAuthKeyReturnValue
        )
        .then((response) => { });
    }
  };
  const dateOnChange = (e) => {
    if (e.target.value != null) {
      setRepDate(e.target.value);
      if (e.target.value != null) {
        insertDateInForRep(e, "datePick");
      }
    } else {
      dataKeyIn = dataKeyIn + "" + e.originalEvent.nativeEvent.data;
      dataKeyIn = dataKeyIn.replace("null", "");
      setCalendarHardCodedDate(dataKeyIn);
    }
  };

  const sideBarStatusFnc = (e) => {
    setSideBarStatus(e);
  };

  const addRepRoleOnChange = (e) => {
    setRepRole(e);
  };

  const signTypeOnChange = (e) => {
    setSignType(e);
  };

  const sideBarCallback = (value) => {
    if (value) {
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: TranslationFile.CompleteCurrentPageText[userSelectedLanguage],
        life: 3000,
      });
    }
  };

  const tourStatus = (e) => {
    setRun(e);
  };

  return (
    <div>
      <Joyride
        continuous
        run={run}
        disableBeacon={true}
        showProgress={false}
        showSkipButton
        steps={steps}
        scrollToFirstStep
        scrollToSteps={true}
        disableScrolling={false}
        scrollOffset={100}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />

      <NavBarHeader
        callBack={callback}
        isAppPortal={true}
        isHomePage={false}
      />
      <div className="representative-sidebar-div">
        <Sidebar
          children="Representative"
          sideBarStatus={(e) => sideBarStatusFnc(e)}
          sideBarCallback={(e) => sideBarCallback(e)}
          tourStatus={tourStatus}
        />
      </div>
      <Toast ref={toast} style={{ marginTop: "34%" }} />
      <Row className={sideBarStatus ? "BSFYStyleRep" : "BSFYStyleRepSidebar"}>
        <Col>
          <div>
            <div className="Page_Title_rep">
              <div className="Page_Title_T1_rep_name">{companyName}</div>
              <div className="Page_Title_Rep_Year">{currentFinancialYear}</div>
            </div>
          </div>
        </Col>
        <Col>
          <div
            style={{
              textAlign: "end",
              marginTop: "12px",
              marginBottom: "12px",
            }}
          >
            <Button
              type="button"
              icon="pi pi-file-pdf"
              outlined
              style={{ borderRadius: "70px" }}
              className="pdf_view_rep"
              tooltip={TranslationFile.pdfPreview[userSelectedLanguage]}
              tooltipOptions={{ position: "bottom" }}
              onClick={() => previewPdf(currentReportGUIDFromUrl)}
            />
          </div>
        </Col>
      </Row>

      <div className={sideBarStatus ? "rep-div" : "rep-div-sidebar"}>
        <Row style={{ width: "100%" }}>
          <br></br>

          <Col
            xs={8}
            sm={8}
            md={8}
            lg={8}
            xl={8}
            style={{ width: "100%", marginLeft: "12px" }}
          >
            <Accordion
              multiple
              activeIndex={activeIndex}
              onTabChange={(e) => setActiveIndex(e.index)}
              className="rep-accordion"
            >
              <AccordionTab
                // className="rep-accordion-tab1"
                header={TranslationFile.representativeText[userSelectedLanguage]}
                className="IS-Accordion-Tab"
              >
                <div>
                  <center>
                    <div className="card-admin-page">
                      <Toolbar
                        className="admin-toolbar"
                        right={rightToolbarTemplate}
                        left={leftToolbarTemplate}
                      ></Toolbar>
                      <DataTable
                        disabled={reportAccess == 3}
                        ref={dt}
                        value={totalRepList}
                        editMode="row"
                        selection={selectedProducts}
                        onSelectionChange={(e) => setSelectedProducts(e.value)}
                        selectionMode="checkbox"
                        dataKey="no"

                        emptyMessage={
                          TranslationFile.repEmptyMessage[userSelectedLanguage]
                        }
                        selectionPageOnly
                        className="representativeDataTable"
                      >
                        {reportAccess != 3 && (
                          <Column
                            selectionMode="multiple"
                            exportable={false}
                            className="representative-checkbox"
                            disabled={true}
                          ></Column>
                        )}

                        <Column
                          field="firstName"
                          header={
                            TranslationFile.repFirstNameText[
                            userSelectedLanguage
                            ]
                          }
                          style={{ width: "20%" }}
                          className="representative-firstName"
                          editor={(options) => textEditorFName(options)}
                        ></Column>

                        <Column
                          field="lastName"
                          header={
                            TranslationFile.repLastNameText[
                            userSelectedLanguage
                            ]
                          }
                          style={{ width: "20%" }}
                          className="representative-lastName"
                          editor={(options) => textEditorLName(options)}
                        ></Column>

                        <Column
                          field="sSN"
                          header={
                            TranslationFile.repSocialNo[userSelectedLanguage]
                          }
                          className="representative-SSN"
                          style={{ width: "20%" }}
                          editor={(options) => textEditorMask(options)}
                        ></Column>

                        <Column
                          field="role"
                          header={TranslationFile.repRole[userSelectedLanguage]}
                          className="representative-role"
                          style={{ width: "20%" }}
                          body={roleBodyTemplate}
                          editor={(options) => roleEditor(options)}
                        ></Column>

                        <Column
                          field="signType"
                          header={
                            TranslationFile.signTypeText[userSelectedLanguage]
                          }
                          className="representative-signType"
                          style={{ width: "20%" }}
                          body={signTypeBodyTemplate}
                          editor={(signTypeObj) => signTypeEditor(signTypeObj)}
                        ></Column>

                        {reportAccess != 3 && (
                          <Column
                            rowEditor
                            headerStyle={{ width: "20%", minWidth: "8rem" }}
                            bodyStyle={{ textAlign: "center" }}
                            className="representativeRowEdit"
                          ></Column>
                        )}
                      </DataTable>
                    </div>
                  </center>
                </div>

                <div className="rept-citydate">
                  <Row>
                    <Col className="rep-div-city">
                      <label className="rep-label-city">
                        {/* City  */}
                        {TranslationFile.repCityText[userSelectedLanguage]}
                      </label>
                      <InputText
                        className="rep-city-box"
                        value={cityValue}
                        onChange={(e) => setCityValue(e.target.value)}
                        onBlur={() => CityOnBlur()}
                        placeholder={
                          TranslationFile.repEnterCityText[userSelectedLanguage]
                        }
                        disabled={reportAccess == 3}
                      />
                    </Col>
                    <Col className="rep-div-date">
                      <label className="rep-label-date">
                        {TranslationFile.repDate[userSelectedLanguage]}
                        {/* Date */}
                      </label>
                      <Calendar
                        className="rep-date-box"
                        value={repDate}
                        onChange={(e) => dateOnChange(e)}
                        onBlur={(e) => dateOnBlur(e)}
                        showIcon
                        dateFormat="yy-mm-dd"
                        placeholder={TranslationFile.YearFormat[userSelectedLanguage]}
                        disabled={reportAccess == 3}
                        locale={localeLang}
                      />
                    </Col>
                  </Row>
                </div>
              </AccordionTab>
            </Accordion>

            <Dialog
              visible={addNewRepDialog}
              style={{ width: "32rem" }}
              breakpoints={{ "960px": "75vw", "641px": "90vw" }}
              header={TranslationFile.repAddNewRep[userSelectedLanguage]}
              modal
              className="p-fluid"
              blockScroll
              footer={addNewRepDialogFooter}
              onHide={hideAddNewRep}
            >
              <div className="field">
                <p className="adminPageCompanyInfoEditPopUpTitles">
                  {/* First Name */}
                  {TranslationFile.repFirstNameText[userSelectedLanguage]}
                </p>
                <InputText
                  id="name"
                  value={repFirstName}
                  onChange={(e) => {
                    setRepFirstName(e.target.value);
                  }}
                  onBlur={(e) => repFirstNameOnBlur(e)}
                  required
                  autoFocus
                  placeholder={
                    TranslationFile.repFirstNameText[userSelectedLanguage]
                  }
                  className={classNames({
                    "p-invalid":
                      (newRepsubmitted && !repFirstName) ||
                      repFirstNameBlurError ||
                      repLastNameBlurError,
                  })}
                />
                {newRepsubmitted && repFirstName == "" && (
                  <small className="p-error">
                    {/* First Name is required. */}
                    {TranslationFile.repFirstNameReq[userSelectedLanguage]}
                  </small>
                )}
                {repFirstNameBlurError && (
                  <small className="p-error">
                    {/* First Name requires minimum 2 letters. */}
                    {
                      TranslationFile.repFirstNameMini2Letter[
                      userSelectedLanguage
                      ]
                    }
                  </small>
                )}
              </div>

              <br></br>

              <div className="field">
                <p className="adminPageCompanyInfoEditPopUpTitles">
                  {TranslationFile.repLastNameText[userSelectedLanguage]}
                  {/* Last Name */}
                </p>
                <InputText
                  value={repLastName}
                  onChange={(e) => {
                    setRepLastName(e.target.value);
                  }}
                  onBlur={(e) => repLastNameOnBlur(e)}
                  required
                  placeholder={
                    TranslationFile.repLastNameText[userSelectedLanguage]
                  }
                  className={classNames({
                    "p-invalid": newRepsubmitted && !repLastName,
                  })}
                />
                {newRepsubmitted && !repLastName && (
                  <small className="p-error">
                    {/* Last Name is required. */}
                    {TranslationFile.repLastNameReq[userSelectedLanguage]}
                  </small>
                )}
                {repLastNameBlurError && (
                  <small className="p-error">
                    {/* First Name requires minimum 2 letters. */}
                    {
                      TranslationFile.repFirstNameMini2Letter[
                      userSelectedLanguage
                      ]
                    }
                  </small>
                )}
              </div>

              <br></br>

              <div className="field">
                <p className="adminPageCompanyInfoEditPopUpTitles">
                  {/* Social Security No. */}
                  {TranslationFile.repSocialNo[userSelectedLanguage]}
                </p>
                <InputMask
                  useGrouping={false}
                  value={repSSN}
                  mask="99999999-9999"
                  onChange={(e) => {
                    setRepSSN(e.value);
                  }}
                  required
                  placeholder={TranslationFile.SSNFormat[userSelectedLanguage]}
                // style={{ borderColor: repSsnBlurError && "red" }}
                />
                {newRepsubmitted && repSSN == "" && signType.code == 1 && (
                  <small className="p-error">
                    {TranslationFile.repSsnReq[userSelectedLanguage]}
                  </small>
                )}
              </div>

              <br></br>
              <div className="field">
                <p className="adminPageCompanyInfoEditPopUpTitles">
                  {TranslationFile.repRole[userSelectedLanguage]}
                </p>
                <Dropdown
                  id="rep_Dropdown_list"
                  value={repRole}
                  options={repRoleOptionsObj}
                  onChange={(e) => {
                    addRepRoleOnChange(e.value);
                  }}
                  optionLabel="name"
                  editable
                  placeholder={TranslationFile.repSelectRoll[userSelectedLanguage]}
                />
                {newRepsubmitted && repRole == "" && (
                  <small className="p-error">
                    {TranslationFile.repRoleReq[userSelectedLanguage]}
                  </small>
                )}
              </div>
              <br></br>

              {/* //SignType */}

              <div className="field">
                <p className="adminPageCompanyInfoEditPopUpTitles">
                  {TranslationFile.signTypeText[userSelectedLanguage]}
                </p>
                <Dropdown
                  id="signType_Dropdown_list"
                  value={signType}
                  options={signTypeObj}
                  onChange={(e) => {
                    signTypeOnChange(e.value);
                  }}
                  optionLabel="name"
                />
              </div>
            </Dialog>

            <Dialog
              visible={deleteProductDialog}
              style={{
                width: "32rem",
              }}
              breakpoints={{ "960px": "75vw", "641px": "90vw" }}
              header={TranslationFile.dashboardConfirmBtn[userSelectedLanguage]}
              blockScroll
              modal
              footer={deleteProductDialogFooter}
              onHide={hideDeleteProductDialog}
            >
              <div className="confirmation-content">
                <i
                  className="pi pi-exclamation-triangle mr-3"
                  style={{ fontSize: "2rem" }}
                />
                {selectedProducts && (
                  <span>
                    {/* Are you sure you want to delete  */}
                    {TranslationFile.repConfDeleteText1[userSelectedLanguage]}
                    <b></b>?
                  </span>
                )}
              </div>
            </Dialog>

            <Dialog
              visible={deleteProductsDialog}
              style={{ width: "32rem" }}
              breakpoints={{ "960px": "75vw", "641px": "90vw" }}
              header={TranslationFile.dashboardConfirmBtn[userSelectedLanguage]}
              blockScroll
              modal
              footer={deleteProductDialogFooter}
              onHide={hideDeleteProductsDialog}
            >
              <div className="confirmation-content">
                <div>
                  <span>
                    {TranslationFile.repConfDeleteText2[userSelectedLanguage]}
                    {/* Are you sure you want to delete the selected report? */}
                  </span>
                </div>
              </div>
            </Dialog>

            <center className="MgmtSaveBtnCenter">
              <Button
                label={TranslationFile.previousBtn[userSelectedLanguage]}
                aria-label={TranslationFile.FooterAnnualReport[userSelectedLanguage]}
                onClick={() => navigationOnClick("managementStatement")}
                id="annualReportBtn"
                className="MgmtSaveBtn"
              />
              {cityValue != "" && repDate != "" && repDate != null ? (
                <Button
                  label={TranslationFile.nextBtn[userSelectedLanguage]}
                  aria-label={TranslationFile.FooterAnnualReport[userSelectedLanguage]}
                  onClick={() => navigationOnClick("payment")}
                  id="annualReportBtn"
                  className="MgmtSaveBtn"
                />
              ) : (
                <Button
                  label={TranslationFile.nextBtn[userSelectedLanguage]}
                  aria-label={TranslationFile.FooterAnnualReport[userSelectedLanguage]}
                  onClick={() => navigationOnClick("payment")}
                  id="annualReportBtn"
                  className="MgmtSaveBtn"
                // disabled
                />
              )}
            </center>
          </Col>
        </Row>
      </div>
      <ScrolltoTop />
      <Footer_contact_tab
        sideBar={sideBarStatus}
        isCorpPortal={false}
        isHomePage={true}
      />
    </div>
  );
}
