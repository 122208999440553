import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import { Toast } from "primereact/toast";
import NavBarHeader from "../NavBarHeader/NavBarHeader";
import { InputNumber } from "primereact/inputnumber";
import { Accordion, AccordionTab } from "primereact/accordion";
import axios from "axios";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import moment from "moment";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { setUserSessionAuthKey, previewPdf } from "../../Component/commonUtils";
import TranslationFile from "../15-Translation/locale/Translation_apps.json";
import ScrolltoTop from "../ScrollTop/ScrollTop";
import Footer_contact_tab from "../Footerpage/Footer_contact_tab";
import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";
import Autocomplete from "react-autocomplete";

import "./NotesNew.css";

let userSelectedLanguage,
  userSessionAuthKeyReturnValue = setUserSessionAuthKey(),
  currentReportGUIDFromUrl = "",
  notesTemplateReqArray = [],
  financialYearDatesForTemplate = [],
  notesTemplateResponseData = "";

let steps = [
  {
    content: TranslationFile.TourGuide_AddNewColumn_Btn[userSelectedLanguage],
    placement: "top",
    styles: {
      options: {
        width: 300,
      },
    },
    target: ".NotesAddNewColumnLbl",
    title: TranslationFile.AddNewRecord[userSelectedLanguage],
    disableBeacon: true,
    delay: 500,
  },
  {
    content: TranslationFile.TourGuide_DeleteRecord_Btn[userSelectedLanguage],
    placement: "top",
    styles: {
      options: {
        width: 300,
      },
    },
    target: "#notesDeleteHeader",
    title: TranslationFile.DeleteField[userSelectedLanguage],
    disableBeacon: true,
    delay: 500,
  },
  {
    content: TranslationFile.TourGuide_Add_NewNotes_Btn[userSelectedLanguage],
    placement: "top",
    styles: {
      options: {
        width: 300,
      },
    },
    target: "#notesAddNewBtn",
    title: TranslationFile.AddNewNotes[userSelectedLanguage],
    disableBeacon: true,
    delay: 500,
  },
];

export default function Notes() {
  const [sideBarStatus, setSideBarStatus] = useState(true);
  const [notesTemplateValue, setNotesTemplateValue] = useState("");
  const [notesRenderValue, setNotesRenderValue] = useState("");
  const [activeIndex, setActiveIndex] = useState([0]);
  const [run, setRun] = useState(false);
  userSelectedLanguage = localStorage.getItem("selectedLanguage");
  const [render, setRender] = useState(false);
  const [items, setItems] = useState(null);
  const toast = useRef(null);
  const history = useHistory();

  const sideBarStatusFnc = (e) => {
    setSideBarStatus(e);
  };

  const tourStatus = (e) => {
    setRun(e);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    let splittedUrl = window.location.href.split("/");
    currentReportGUIDFromUrl = splittedUrl[splittedUrl.length - 2];
    userSessionAuthKeyReturnValue = setUserSessionAuthKey();

    if (userSessionAuthKeyReturnValue) {
      axios
        .get(
          "/annualReport/getHeader?reportId=" + currentReportGUIDFromUrl,
          userSessionAuthKeyReturnValue
        )
        .then((headerResponse) => {
          let headerResponseData = headerResponse.data;

          if (headerResponseData != "") {
            financialYearDatesForTemplate = [];
            let financialYearDateObj = {
              validFromDate: moment(
                headerResponseData.currentYear.slice(0, 10)
              ).format("YYYY-MM-DD"),
              validToDate: moment(
                headerResponseData.currentYear.slice(13, 23)
              ).format("YYYY-MM-DD"),
              statementType: 1,
            };

            financialYearDatesForTemplate.push(financialYearDateObj);

            let getJsonLines = {
              guid: currentReportGUIDFromUrl,
              pageName: "Notes",
            };

            axios
              .post(
                "/annualReportLine/getLinesJson",
                getJsonLines,
                userSessionAuthKeyReturnValue
              )
              .then((linesJsonResponse) => {
                let linesJsonResponseData = linesJsonResponse.data;

                if (
                  linesJsonResponseData != "" &&
                  linesJsonResponseData.reportID != null &&
                  linesJsonResponseData.notes != "" &&
                  linesJsonResponseData.notes != null
                ) {
                  Object.keys(JSON.parse(linesJsonResponseData.notes)).map(
                    (i, idx) => {
                      setActiveIndex((prevState) => [...prevState, idx]);
                    }
                  );

                  setNotesRenderValue(JSON.parse(linesJsonResponseData.notes));
                } else {
                  notesTemplateReqArray = [];

                  axios
                    .post(
                      "/user/getAnnualReportTemplateDetails",
                      financialYearDatesForTemplate,
                      userSessionAuthKeyReturnValue
                    )
                    .then((notesTemplateResponse) => {
                      notesTemplateResponseData = notesTemplateResponse.data;
                      Object.keys(notesTemplateResponseData).map((i, idx) => {
                        setActiveIndex((prevState) => [...prevState, idx]);
                        let lines = notesTemplateResponseData[i];
                        lines = lines.filter(
                          (obj) => obj.templateColumn2 == "FromToDate"
                        );
                        lines.forEach((item) => {
                          item.column2 = item.financialYearRange;
                          item.column3 = "";
                          item.column4 = "";
                          item.column5 = "";
                          item.column6 = "";
                        });
                      });
                      setNotesTemplateValue(notesTemplateResponseData);
                      const updatedObj = updateObject(
                        notesTemplateResponseData
                      );
                      setNotesRenderValue(updatedObj);
                    });
                }
              });
          }
        });
    }
  }, []);

  // Function to dynamically update the object
  const updateObject = (obj) => {
    // Get all keys of the object
    const keys = Object.keys(obj);

    // Iterate over the keys and create a new object with updated values
    const updatedObj = keys.reduce((acc, key, idx) => {
      // If it's the third key (idx === 2), keep only the first element of the array
      if (idx === 2) {
        acc[key] = [obj[key][0]]; // Keep only the first object in the array
      } else {
        acc[key] = obj[key]; // Keep the full array for other keys
      }
      return acc;
    }, {});

    return updatedObj;
  };

  const sideBarCallback = (value) => {
    if (value) {
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: TranslationFile.CompleteCurrentPageText[userSelectedLanguage],
        life: 3000,
      });
    }
  };

  const callback = (annualReport, session, link, status) => {
    if (status) {
      history.push(annualReport + session + link);
    }
  };

  const pageNavigation = (navigateToPageName) => {
    // const { sessionDetails } = this.props;
    // let { sideBarReducer } = this.props;
    const annualReport = "/annualreport/";

    // let sideBarReducerValues = [];
    // sideBarReducerValues = sideBarReducer.values;
    // sideBarReducerValues.push("Notes");
    // if (sideBarGreenTickPages.length == 0)
    //   sideBarReducer.values = sideBarReducerValues;

    let possibleAccessablePagesListLSString = localStorage.getItem(
      "possibleAccessablePages"
    );

    let possibleAccessablePagesListLSArray = JSON.parse(
      possibleAccessablePagesListLSString
    );

    if (!possibleAccessablePagesListLSArray.includes("Management Statement")) {
      possibleAccessablePagesListLSArray.push(
        "Company Information",
        "Income Statement",
        "Balance Sheet",
        "Notes",
        "Management Statement"
      );

      let updatePossibleNavigationPagesListString = JSON.stringify(
        possibleAccessablePagesListLSArray
      );

      localStorage.setItem(
        "possibleAccessablePages",
        updatePossibleNavigationPagesListString
      );
    }

    history.push(
      annualReport + currentReportGUIDFromUrl + "/" + navigateToPageName
    );
  };

  const addNewColumnOnClick = () => {
    alert("Add New Column");
  };
  const accordionHeader = (headerLines, tabHeading) => {
    // console.log(notesRenderValue, "@!@", headerLines, ">>", tabHeading);
    // let headerLines = notesRenderValue[tabHeading];
    let tabHeadingObj = headerLines[0];
    let linesArray = headerLines.filter(
      (obj) =>
        obj.parentLineNo == tabHeadingObj.lineNo &&
        obj.lineNo != tabHeadingObj.lineNo
    );

    return (
      <Row>
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          style={{ width: "100%", marginTop: "7px" }}
        >
          <label
            className="ISFieldsStyle"
            style={{ marginLeft: "-21px", width: "50%" }}
          >
            {tabHeadingObj.name}
          </label>

          {tabHeadingObj.column2 == "Add New Column" &&
            linesArray.length > 0 && (
              <label className="notesAddNewColumnLbl" style={{ width: "50%" }}>
                <span
                  style={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    cursor: "pointer",
                  }}
                  onClick={() => addNewColumnOnClick()}
                >
                  + Add new column
                </span>
              </label>
            )}
        </Col>
      </Row>
    );
  };

  const deleteBtnClick = (heading, line) => {
    let headerLines = notesRenderValue[heading];
    let index = headerLines.findIndex((item) => item.lineNo === line.lineNo);
    headerLines.splice(index, 1);

    setRender(!render);
    axios
      .post(
        "/annualReportLine/deleteCustomLine",
        line,
        userSessionAuthKeyReturnValue
      )
      .then((response) => {
        if (response.data) {
          updateLinesJsonTable(notesRenderValue);
        }
      });
  };

  const addBtnClick = (heading, line) => {
    let newLine = { ...line };
    newLine.column1 = "";
    newLine.name = "";
    newLine.placeHolder = "";
    newLine.lineNo = line.lineNo + 1;
    newLine.isCustomLine = true;
    newLine.column2 = "";

    let headerLines = notesRenderValue[heading];

    let index = headerLines.findIndex((item) => item.lineNo === line.lineNo);
    headerLines.splice(index + 1, 0, newLine);

    axios
      .get(
        "/siteOwner/getSuggestionListByParentLineNo?parentLineNo=" +
          line.parentLineNo,
        userSessionAuthKeyReturnValue
      )
      .then((suggestionResponse) => {
        if (suggestionResponse.data.length > 0) {
          let suggestions = suggestionResponse.data;

          let suggestionsList = [];
          suggestions &&
            suggestions.length &&
            suggestions.map((i, idx) => {
              let suggestionObj = {
                id: idx + 1,
                label: i.column1,
                lineNo: i.lineNo,
              };
              suggestionsList.push(suggestionObj);
            });

          setItems(suggestionsList);
        } else setItems([]);
      });
  };

  const textAreaOnBlur = (e) => {};

  const textAreaOnChange = (heading, line, e) => {
    let linesArrayFilteredByHeader = notesRenderValue[heading];

    let lineObjArrayFilteredByLineNo = linesArrayFilteredByHeader.filter(
      (obj) => obj.lineNo == line.lineNo
    );

    lineObjArrayFilteredByLineNo.forEach((item) => {
      item.column1 = e;
    });

    setRender(!render);
    insertLines(notesRenderValue);
  };

  //dynamicInputNumberOnBlur

  const dynamicInputNumberOnBlur = (heading, line, idx, custEnteredValue) => {
    custEnteredValue = formatInputNumber(custEnteredValue);

    let linesArrayFilteredByHeader = notesRenderValue[heading];
    let lineObjArrayFilteredByLineNo = linesArrayFilteredByHeader.filter(
      (obj) => obj.lineNo == line.lineNo
    );

    lineObjArrayFilteredByLineNo.forEach((item) => {
      switch (idx) {
        case 0:
          item.column2 = custEnteredValue;
          updateLinesObjWithCustomKey(item, 0, custEnteredValue);
          break;

        case 1:
          item.column3 = custEnteredValue;
          updateLinesObjWithCustomKey(item, -1, custEnteredValue);
          break;

        case 2:
          item.column4 = custEnteredValue;
          updateLinesObjWithCustomKey(item, -2, custEnteredValue);
          break;

        case 3:
          item.column5 = custEnteredValue;
          updateLinesObjWithCustomKey(item, -3, custEnteredValue);
          break;

        case 4:
          item.column6 = custEnteredValue;
          updateLinesObjWithCustomKey(item, -4, custEnteredValue);
          break;
      }
    });

    setRender(!render);
    insertLines(notesRenderValue);
  };

  const insertLines = (linesObj) => {
    // Merge all values from the object into one array
    let mergedArray = [].concat(...Object.values(linesObj));
    mergedArray.forEach((item) => {
      item["reportGUID"] = currentReportGUIDFromUrl;
      item["nameTemp"] = item.name;

      if (
        item.isHeader &&
        item.lineLevel == 2 &&
        item.column2 == "FromToDate"
      ) {
        //   item.column2=currentfinancialYearRange
        item.column2 = "";
        item.column3 = "";
        item.column4 = "";
        item.column5 = "";
        item.column6 = "";

        financialYearDatesForTemplate &&
          financialYearDatesForTemplate.length &&
          financialYearDatesForTemplate.map((fy, fyIdx) => {
            item["column" + (fyIdx + 2)] =
              financialYearDatesForTemplate[fyIdx].validFromDate +
              " - " +
              financialYearDatesForTemplate[fyIdx].validToDate;
          });
      }
    });

    if (mergedArray.length > 0) {
      axios
        .post(
          "/annualReportLine/insertLines",
          mergedArray,
          userSessionAuthKeyReturnValue
        )
        .then((insertLinesResponse) => {
          if (insertLinesResponse.data) {
            updateLinesJsonTable(linesObj);
          }
        });
    }
  };

  const formatInputNumber = (custEnteredValue) => {
    custEnteredValue = custEnteredValue.replaceAll(",", "");
    let spacesRemoved = custEnteredValue.replaceAll(/\s/g, "");
    const firstChar = spacesRemoved.charAt(0);
    let minusArray = ["-", "−", "‑", "−"];

    if (minusArray.includes(firstChar)) {
      const newStr = spacesRemoved.slice(1);
      custEnteredValue = -1 * newStr;
    } else {
      custEnteredValue = Number(spacesRemoved);
    }

    return custEnteredValue;
  };
  const staticInputNumberOnBlur = (heading, line, custEnteredValue) => {
    custEnteredValue = formatInputNumber(custEnteredValue);

    let linesArrayFilteredByHeader = notesRenderValue[heading];
    let lineObjArrayFilteredByLineNo = linesArrayFilteredByHeader.filter(
      (obj) => obj.lineNo == line.lineNo
    );
    lineObjArrayFilteredByLineNo.forEach((item) => {
      item.column2 = custEnteredValue;
    });
    setRender(!render);
    insertLines(notesRenderValue);
  };

  const updateLinesObjWithCustomKey = (
    item,
    financialYear,
    custEnteredValue
  ) => {
    let currentfinancialYearRange =
      financialYearDatesForTemplate[0].validFromDate +
      " - " +
      financialYearDatesForTemplate[0].validToDate;

    item["financialYearRange"] = currentfinancialYearRange;
    item["financialYearValue"] = financialYear;
    item["lineAmount"] = custEnteredValue;
    item["nameTemp"] = item.name;

    return item;
  };

  const updateLinesJsonTable = (linesObj) => {
    let updateJsonTableObj = {
      pageName: "Notes",
      guid: currentReportGUIDFromUrl,
      json: JSON.stringify(linesObj),
    };

    axios
      .post(
        "/annualReportLine/updateLinesJsonTable",
        updateJsonTableObj,
        userSessionAuthKeyReturnValue
      )
      .then((response) => {});
  };

  const highlightMatchedText = (label, value) => {
    // Split the label by the matched value and wrap the match with a span for styling
    const parts = label.split(new RegExp(`(${value})`, "i"));
    return parts.map((part, index) =>
      part.toLowerCase() === value.toLowerCase() ? (
        <span
          key={index}
          style={{ backgroundColor: "#2a55a5", color: "white" }}
        >
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const handleSelect = (selectedValue, line, heading) => {
    let selectedSuggestionOption = items.filter(
      (obj) => obj.label == selectedValue
    )[0];
    line.column1 = selectedValue;
    line.lineNo = selectedSuggestionOption.lineNo;

    setRender(!render);
  };

  return (
    <div>
      <Joyride
        continuous
        run={run}
        disableBeacon={true}
        showProgress={false}
        showSkipButton
        steps={steps}
        scrollToFirstStep
        scrollToSteps={true}
        disableScrolling={false}
        scrollOffset={100}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />

      <Toast ref={toast} />

      <NavBarHeader callBack={callback} isAppPortal={true} isHomePage={false} />

      <div className="representative-sidebar-div">
        <Sidebar
          children="Notes"
          sideBarStatus={(e) => sideBarStatusFnc(e)}
          sideBarCallback={(e) => sideBarCallback(e)}
          tourStatus={tourStatus}
        />
      </div>

      <div className={sideBarStatus ? "ISSideBarOpen" : "ISSideBarClose"}>
        <Row className="ISParentRow">
          <Col xs={8} sm={8} md={8} lg={8} xl={8} style={{ width: "100%" }}>
            {notesRenderValue != "" && (
              <Accordion multiple activeIndex={activeIndex}>
                {Object.keys(notesRenderValue).map((heading, idx) => {
                  {
                    let headerLines = notesRenderValue[heading];
                    // headerLines = [...headerLines].sort(
                    //   (a, b) => a.lineNo - b.lineNo
                    // );
                    let header = headerLines[0];

                    return (
                      <AccordionTab
                        header={accordionHeader(headerLines, heading)}
                        className="IS-Accordion-Tab"
                      >
                        {headerLines &&
                          headerLines.length &&
                          headerLines.map((line, lineIdx) => {
                            if (lineIdx > 0) {
                              return (
                                <div>
                                  {/* Header Line*/}
                                  {line.header && line.lineLevel == 2 && (
                                    <div className="notesHeaderLine">
                                      <Row>
                                        <Col>{line.name}</Col>

                                        {line.templateColumn2 ==
                                        "FromToDate" ? (
                                          <>
                                            {financialYearDatesForTemplate &&
                                              financialYearDatesForTemplate.length &&
                                              financialYearDatesForTemplate.map(
                                                (fy, fyIdx) => {
                                                  return (
                                                    <Col>
                                                      {financialYearDatesForTemplate[
                                                        fyIdx
                                                      ].validFromDate +
                                                        " - " +
                                                        financialYearDatesForTemplate[
                                                          fyIdx
                                                        ].validToDate}
                                                    </Col>
                                                  );
                                                }
                                              )}
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </Row>
                                    </div>
                                  )}

                                  {/* Textarea Line*/}
                                  {line.lineLevel == 6 && (
                                    <div className="notesHeaderLine">
                                      <InputTextarea
                                        value={line.column1}
                                        rows={3}
                                        cols={30}
                                        autoResize={true}
                                        className="notesTextArea"
                                        onChange={(e) =>
                                          textAreaOnChange(
                                            heading,
                                            line,
                                            e.target.value
                                          )
                                        }
                                        onBlur={(e) => textAreaOnBlur(e)}
                                      />
                                    </div>
                                  )}

                                  {/* Non Dynamic Column Line*/}
                                  {line.lineLevel == 7 && (
                                    <div className="notesNonDynamicColumnsLine">
                                      <Row>
                                        <Col className="d-flex align-items-center justify-content-start">
                                          <div>
                                            {line.isCustomLine != undefined &&
                                            line.isCustomLine ? (
                                              <>
                                                <Autocomplete
                                                  getItemValue={(item) =>
                                                    item.label
                                                  }
                                                  items={
                                                    items != null &&
                                                    items.filter((item) =>
                                                      item.label
                                                        .toLowerCase()
                                                        .includes(
                                                          line.column1.toLowerCase()
                                                        )
                                                    )
                                                  }
                                                  renderItem={(
                                                    item,
                                                    isHighlighted
                                                  ) => (
                                                    <div
                                                      key={item.id}
                                                      style={{
                                                        background:
                                                          isHighlighted
                                                            ? "lightgray"
                                                            : "white",
                                                        padding: "5px",
                                                        cursor: "pointer",
                                                        borderBottom:
                                                          "1px solid #ddd",
                                                      }}
                                                      className="suggestionDiv"
                                                    >
                                                      {highlightMatchedText(
                                                        item.label,
                                                        item.column1 !=
                                                          undefined
                                                          ? item.column1
                                                          : ""
                                                      )}
                                                    </div>
                                                  )}
                                                  value={line.column1}
                                                  onSelect={(e) => {
                                                    handleSelect(
                                                      e,
                                                      line,
                                                      heading
                                                    );
                                                  }}
                                                  //   onChange={(e) => {
                                                  //     this.customOnChange(
                                                  //       e.target.value,
                                                  //       ISTemplateFieldOutsideMap,
                                                  //       heading
                                                  //     );
                                                  //   }}
                                                  renderInput={(props) => (
                                                    <textarea
                                                      {...props}
                                                      className="auto-resize-textareaa"
                                                    />
                                                  )}
                                                  inputProps={{
                                                    // ref: inputRef,
                                                    // onBlur: (e) =>
                                                    //   this.validateForm(
                                                    //     e,
                                                    //     heading,
                                                    //     idx,
                                                    //     ISTemplateFieldOutsideMap
                                                    //   ),

                                                    // disabled:
                                                    //   readOnly.values && true,

                                                    placeholder:
                                                      TranslationFile
                                                        .EnterYourDescriptionText[
                                                        userSelectedLanguage
                                                      ],

                                                    style: {
                                                      outline: "none",
                                                      width: "400px",
                                                      whiteSpace: "pre-wrap", // Enable wrapping for long words
                                                      wordWrap: "break-word", // Allow word breaking
                                                      overflowWrap:
                                                        "break-word", // For newer browsers
                                                      resize: "none", // Prevent resizing of textarea
                                                      minHeight: "40px", // Minimum height to start
                                                      lineHeight: "1.5", // Increase line height for better readability
                                                      padding: "5px",
                                                      borderRadius: "10px",
                                                      borderColor: "#cdcdcd",
                                                      overflowY: "hidden",
                                                      height:
                                                        line.column1.length /
                                                          55 >
                                                        1.0
                                                          ? Math.round(
                                                              line.column1
                                                                .length / 55
                                                            ) +
                                                            1 * 50 +
                                                            8
                                                          : Math.round(
                                                              line.column1
                                                                .length / 55
                                                            ) *
                                                              30 +
                                                            8,
                                                    },
                                                  }}
                                                  renderMenu={(
                                                    items,
                                                    value,
                                                    style
                                                  ) => (
                                                    <div
                                                      style={{
                                                        ...style,
                                                        marginTop: "5px",
                                                        maxHeight: "200px",
                                                        overflowY: "auto",
                                                        width: "360px",
                                                        lineHeight: 2,
                                                        boxShadow:
                                                          "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                                      }}
                                                    >
                                                      {items.length > 0 ? (
                                                        items
                                                      ) : (
                                                        <div
                                                          style={{
                                                            padding: "5px",
                                                            color: "gray",
                                                          }}
                                                        >
                                                          No matches found
                                                        </div>
                                                      )}
                                                    </div>
                                                  )}
                                                />
                                              </>
                                            ) : (
                                              <>{line.column1}</>
                                            )}
                                          </div>
                                        </Col>
                                        <Col>
                                          <InputNumber
                                            inputId="integeronly"
                                            value={line.column2}
                                            className="notesNonDynamicColumnsLineInput"
                                            onBlur={(e) =>
                                              staticInputNumberOnBlur(
                                                heading,
                                                line,
                                                e.target.value
                                              )
                                            }
                                            useGrouping={true}
                                            locale="sv-SE"
                                          />

                                          <i
                                            id="notesAddNewLineIcon"
                                            className="fa fa-plus"
                                            aria-hidden="true"
                                            onClick={() =>
                                              addBtnClick(heading, line)
                                            }
                                          />
                                          {line.isCustomLine && (
                                            <i
                                              id="notesAddNewLineIcon"
                                              className="fa fa-trash"
                                              aria-hidden="true"
                                              onClick={() =>
                                                deleteBtnClick(heading, line)
                                              }
                                            />
                                          )}
                                          {/* </div> */}
                                        </Col>
                                      </Row>
                                    </div>
                                  )}

                                  {/* Line*/}
                                  {line.lineLevel == 3 && (
                                    <div className="notesNonDynamicColumnsLine">
                                      <Row>
                                        <Col className="d-flex align-items-center justify-content-start">
                                          <div>{line.column1}</div>
                                        </Col>

                                        <>
                                          {financialYearDatesForTemplate &&
                                            financialYearDatesForTemplate.length &&
                                            financialYearDatesForTemplate.map(
                                              (fy, fyIdx) => {
                                                return (
                                                  <Col>
                                                    <InputNumber
                                                      inputId="integeronly"
                                                      value={line.column2}
                                                      className="notesNonDynamicColumnsLineInput"
                                                      onBlur={(e) =>
                                                        dynamicInputNumberOnBlur(
                                                          heading,
                                                          line,
                                                          fyIdx,
                                                          e.target.value
                                                        )
                                                      }
                                                      useGrouping={true}
                                                      locale="sv-SE"
                                                    />
                                                  </Col>
                                                );
                                              }
                                            )}
                                        </>
                                      </Row>
                                    </div>
                                  )}
                                </div>
                              );
                            }
                          })}
                      </AccordionTab>
                    );
                  }
                })}
              </Accordion>
            )}
          </Col>
        </Row>

        <center className="BSSaveBtnCenter">
          <Button
            label={TranslationFile.previousBtn[userSelectedLanguage]}
            aria-label={
              TranslationFile.FooterAnnualReport[userSelectedLanguage]
            }
            onClick={() => pageNavigation("balanceSheet")}
            id="annualReportBtn"
            className="BSSaveBtn"
          />
          <Button
            label={TranslationFile.nextBtn[userSelectedLanguage]}
            aria-label={
              TranslationFile.FooterAnnualReport[userSelectedLanguage]
            }
            onClick={() => pageNavigation("managementStatement")}
            id="annualReportBtn"
            className="BSSaveBtn"
          />
        </center>
      </div>
      <ScrolltoTop />
      <Footer_contact_tab
        sideBar={sideBarStatus}
        isCorpPortal={false}
        isHomePage={true}
      />
    </div>
  );
}
